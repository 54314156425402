import React, { useEffect, useState } from "react";

const SBDMStory = ({ sbdmdata, claimerdetails }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); 
    };
    return (
        <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden h-100">
            <div className="header-section d-flex flex-wrap justify-content-between row-gap-1 column-gap-3 p-3" style={{ backgroundColor: '#7d2930' }}>
                <div className="d-flex gap-2 text-truncate">
                    <div className="user-img mt-1">
                        <img src={require('../../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                    </div>
                    <div className="user-info text-truncate text-capitalize">
                        <div className="user-name text-white fw-semibold text-truncate">{sbdmdata?.raiser_name}</div>
                        <div className="user-empCode text-white text-truncate">Despute Raiser</div>
                        <div className="opportunity-added fs-14 text-warning text-wrap text-capitalize mb-1">Submission : <span className="text-white ms-1">{formatDate(sbdmdata?.createdAt)}</span></div>
                    </div>
                </div>
                <div className="info-box text-md-end ms-5 ps-1">
                    <div className="label text-warning">Claimed Role</div>
                    <div className="value text-white text-opacity-75 text-break">{sbdmdata?.role_for_this_opportunity}</div>
                </div>
            </div>
            <div className="body-section p-3 pb-4">
                <div className="wrapper ms-md-5 ps-md-1">
                    <div className="checklist-wrapper mb-4">
                        <div className="checklist-label fs-6 text-warning mb-2">Please carefully review your contributions to this opportunity before proceeding.</div>
                        <ul className="m-0">
                            {sbdmdata?.contribution?.map((items, index) => (
                                <>
                                    <li>{items.text}</li>
                                </>
                            ))}
                        </ul>
                    </div>
                    <div className="info-box">
                        <div className="fs-6 text-warning mb-2">Message</div>
                        <div className="msg-description">
                            {sbdmdata?.additional_information}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SBDMStory;
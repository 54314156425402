import React, { useEffect, useState } from "react";

export const Servicekey = ({serviceData, DateFormatefunc,getInstallmentCard}) => {
    // console.log(getInstallmentCard[0])
    const [sdmName, setSdmName] = useState('')
    useEffect(()=>{
        if(getInstallmentCard && getInstallmentCard.length>0){
            setSdmName(getInstallmentCard[0].sdm)
        }
        
    },[getInstallmentCard])
    return (
        <>
            <div className="col-lg-6 col-xl-4">
                <div className="details-card h-100 rounded-3 p-3" style={{ backgroundColor: 'var(--custom-bg)' }}>
                    <div className="mb-3">
                        <div class="fw-semibold text-warning lh-sm mb-1">Key Details in Service</div>
                        <div class="text-white lh-sm">Key details of the service for a quick review.</div>
                    </div>
                    <div className="row g-3">
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Service ID<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-uppercase">{serviceData?.serviceId}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Service Model<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{serviceData?.serviceModel}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Service Created<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{DateFormatefunc(serviceData?.serviceCreated)}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Service Type<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{serviceData?.serviceType}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Service Belongs To<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{serviceData?.serviceBelongsTo}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Service Lead (SDM)<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{sdmName?sdmName:"-"}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Service Tenure<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{serviceData?.serviceTennure}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Shift Hours<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{serviceData?.shiftHours}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import briefcase from '../../resources/images/briefcase.svg';
import schoolbook from '../../resources/images/schoolbook.svg';
import whatsapp from '../../resources/images/whatsapp.svg';
import googleIcon from '../../resources/images/googleIcon.svg';
import axios from 'axios';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { toast, ToastContainer } from 'react-toastify';
import { Loader } from '../Components/Loader';
import { environment } from '../../environment';
import Autosuggest from 'react-autosuggest';
import { callAxios } from '../../utils/utils';

const getSuggestions = async (value, is_outside_india) => {
    const response = await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/profile/search-city', { cityName: value,is_outside_india:is_outside_india }, "post");
    return response.data;
};

// Function to get the suggestion value to display in the input
const getSuggestionValue = suggestion => suggestion.name;

// Function to render each suggestion
const renderSuggestion = suggestion => (
    <div className='pointer'>
        {suggestion.name}
    </div>
);

const animatedComponents = makeAnimated();

export const SignIn = ({ openVerifyMobileCard, index, setMobileNumber,setProfileId,mobileNumber,setIsLoading }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    // const [loading, setIsLoading] = useState(false)
    const [uploadDate, setUploadDate] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [fresher, setFresher] = useState(false)
    const [isChecked, setIsChecked] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidNumber, setIsValidNumber] = useState(true);
    const [selectedCity, setSelectedCity] = useState('')
    const [hidetag, setHidetag] = useState(true)
 
    // checkEmpty
    const [canname, setCanname] = useState('')
    const [canemail, setCanemail] = useState('')
    const [canphone, setCanphone] = useState('')
    const [canpassword, setCanpassword] = useState('')
    const [cantype, setCantype] = useState('')
    const [cancurrentcity, setCancurrentcity] = useState('')
    const [cancurrentcountry, setCancurrentcountry] = useState('')
    const [checkedvalue, setCheckedvalue] = useState(0)
    // checkEmpty
    let navigate = useNavigate();
    const options = [
        { id: 1, value: 'New Delhi', label: 'New Delhi', name: "current_city" },
        { id: 2, value: 'Bengaluru', label: 'Bengaluru', name: "current_city" },
        { id: 3, value: 'Mumbai', label: 'Mumbai', name: "current_city" },
        { id: 4, value: 'Pune', label: 'Pune', name: "current_city" },
        { id: 5, value: 'Chennai', label: 'Chennai', name: "current_city" },
        { id: 6, value: 'Hyderabad', label: 'Hyderabad', name: "current_city" },
        { id: 7, value: 'Gurugram', label: 'Gurugram', name: "current_city" },
        { id: 8, value: 'Noida', label: 'Noida', name: "current_city" },
        { id: 9, value: 'Ahmedabad', label: 'Ahmedabad', name: "current_city" },
        { id: 10, value: 'Kolkata', label: 'Kolkata', name: "current_city" }
    ]

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        mobile: "",
        type: "",
        current_city: "",
        current_country: "",
        availability: "",
        resumeName: ""
    })

    // addskills
    const [lowerItems, setLowerItems] = useState([
        { id: 1, content: 'New Delhi' },
        { id: 2, content: 'Bengaluru' },
        { id: 3, content: 'Mumbai' },
        { id: 4, content: 'Pune' },
        { id: 5, content: 'Chennai' },
        { id: 6, content: 'Hyderabad' },
        { id: 7, content: 'Gurugram' },
        { id: 8, content: 'Noida' },
        { id: 9, content: 'Ahmedabad' },
        { id: 10, content: 'Kolkata' },
    ]);

    const [upperItems, setUpperItems] = useState([]);

    const moveItemToUpper = (itemdata, id) => {
        formData.current_city = itemdata.content
        setHidetag(false)
        const selectedItem = lowerItems.find((item) => item.id === id);
        setLowerItems(lowerItems.filter((item) => item.id !== id));
        setUpperItems([...upperItems, selectedItem]);
    };

    const moveItemToBottom = (itemdata, id) => {
        setHidetag(true)
        const selectedItem = upperItems.find((item) => item.id === id);
        setUpperItems(upperItems.filter((item) => item.id !== id));
        setLowerItems([...lowerItems, selectedItem]);
    };
    // addskills

    // Input Files Code
    const handleFileChange = async (event) => {
        // Access the selected file from the input element
        const file = event.target.files[0];
        setSelectedFile(file);
        setUploadDate(new Date()); // Capture the upload date

        // Check if the selected file type is supported
        const supportedTypes = ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/rtf', 'application/pdf'];
        if (!supportedTypes.includes(file.type)) {
            setSelectedFile(null);
            setErrorMessage('File extension not supported. Supported file extensions: .docx, .doc, .pdf, .rtf');
            return;
        }

        // Check if file size is within the limit
        const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
        if (file.size > maxSize) {
            setSelectedFile(null);
            setErrorMessage('File size exceeds 2 MB limit. Please select a smaller file.');
            return;
        }

        formData.resumeName = file
        setSelectedFile(file);
        setErrorMessage('');
    };

    const handleFileDelete = () => {
        setSelectedFile(null);
        setUploadDate(null);
    };

    const handleInputChange = (e) => {
        if (e.target.name === 'type' && e.target.value === 'fresher') {
            setFresher(true)
            setCantype(e.target.value)
        }
        if (e.target.name === 'type' && e.target.value === "experienced") {
            setFresher(false)
            setCantype(e.target.value)
        }
        if (e.target.name === 'name') {
            setCanname(e.target.value)
        }
        if (e.target.name === 'email') {
            setCanemail(e.target.value)
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setIsValidEmail(emailPattern.test(e.target.value));
        }
        if (e.target.name === 'password') {
            setCanpassword(e.target.value)
        }
        if (e.target.name === 'mobile') {
            setCanphone(e.target.value)
            const numberPattern = /^[0-9]+$/;
            setIsValidNumber(numberPattern.test(e.target.value));
            setMobileNumber(e.target.value)
        }
        if (e.target.name === 'current_city') {
            setCancurrentcity(e.target.value)
            // setSelectedCity(e.target.value)
        }
        if (e.target.name === 'current_country') {
            setCancurrentcountry(e.target.value)
        }
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlecity = (e) => {
        setHidetag(false)
        setSelectedCity(e.value)
        const postme = { id: e.id, content: e.value }
        moveItemToUpper(postme, e.id)
    };

    const handleCheckboxChange = (data) => {
        formData.outsideIndia = data
        setCheckedvalue(data)
        setIsChecked(!isChecked);
    };

    const createProfile = async (e) => {
        setIsLoading(true)
        e.preventDefault()
        if (formData.type === "experienced") {
            formData.current_city = ""
            formData.current_country = ""
            setUpperItems([])
            setHidetag(true)
            setIsChecked(false)
        }
        if (formData.outsideIndia === 0) {
            formData.current_country = ""
        }
        if(formData.type === "fresher"){
            formData.resumeName = ""
        }
        const postData = formData
        try {
            // const response = await axios.post("https://7f17-160-202-37-128.ngrok-free.app/api/v1/profile/get-profile-data", postData, {
            const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/create-profile`, postData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const responsedata = response?.data
            // handleChange('canData',responsedata)
            if (responsedata?.statusCode === 201) {
                setProfileId(response?.data.profile.id)
                callAxios(environment.REACT_APP_API_URL_Live+"api/v1/profile/send-otp",{to:mobileNumber,eventType:"verifyMobile",profileId:response?.data.profile.id},"post")
                // handleChange('candidateInfo', responsedata)
                // resumeUpload
                if (formData.resumeName === "") {
                    setIsLoading(false)
                    toast.success('Data has been uploaded.', {
                        position: 'bottom-center'
                    });
                    openVerifyMobileCard()
                }
                // apiWork
                if (formData.resumeName !== "") {
                    const stuId = responsedata?.profile?.id
                    const sendData = new FormData();
                    sendData.append('profileId', stuId);
                    sendData.append('resume', formData.resumeName);
                    try {
                        const response = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/upload-resume`, sendData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });
                        if (response?.data) {
                            const getresponse = response?.data
                            const getresumeUrl = getresponse?.location
                            const postdata = {
                                "profileId": stuId,
                                "type": "resume",
                                "resumeHeadLine": "resumeHeadLine",
                                "resumeUrl": `${getresumeUrl}`
                            }
                            const dataStringyfy = JSON.stringify(postdata)
                            const resumeresponse = await axios.post(`${environment.REACT_APP_API_URL_Live}api/v1/profile/resume`, dataStringyfy, {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                            if (resumeresponse?.data.statusCode === 200) {
                                setIsLoading(false)
                                toast.success('Data has been uploaded.', {
                                    position: 'bottom-center'
                                });
                                openVerifyMobileCard()
                            }
                            else {
                                toast.error('Something went wrong.', {
                                    position: 'bottom-center'
                                });
                            }
                        }
                    } catch (error) {
                        toast.error(error, {
                            position: 'bottom-center'
                        });
                        console.error('Error uploading file:', error);
                    }
                    // apiWork
                }
                // resumeUpload
            }else{
                setIsLoading(false)
                toast.error(responsedata?.message, {
                    position: 'bottom-center'
                });
            }
        } catch (error) {
            setIsLoading(false)
            toast.error(error?.response?.data?.message, {
                position: 'bottom-center'
            });
            console.log('Error: ', error)
        }
    }

    // onClick={openVerifyMobileCard}
    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState('');
    const onChange = (event, { newValue }) => {
        
        setValue(newValue);
        setFormData({ ...formData, current_city: newValue });
    };

    const onSuggestionsFetchRequested = async ({ value }) => {
        // console.log(LoadUserDetails)
        const fetchedSuggestions = await getSuggestions(value,false);
        setSuggestions(fetchedSuggestions);
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const inputProps = {
        placeholder: formData?.current_city,
        type: 'text',
        autoComplete: 'off',
        className:'form-control',
        value:formData.current_city,
        required:true,
        onChange
    };
    return (
        <div className='card small dark-theme-bg border border-secondary border-opacity-25 rounded-4 px-3 px-lg-4 py-4'>
           
            <div className="row">
                <div className="col-lg-9">
                    <div className='heading-wrapper mb-3'>
                        <div className='text-white fs-5 fw-medium'>Create your naukri profile</div>
                        <div className='text-clr1'>Search & apply to jobs from india's No.1 job site</div>
                    </div>

                    <Form action="POST" className="profileCustomModal form-cover" onSubmit={createProfile}>
                        <Form.Group className="mb-3">
                            <Form.Label>Full name<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="text" name="name" value={formData.name} onChange={handleInputChange} placeholder="What is your name?" autoComplete='off' required />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Email ID<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="email" name="email" value={formData.email} onChange={handleInputChange} placeholder="Tell us your Email ID" autoComplete='off' required />
                            {!isValidEmail && <p style={{ color: 'red' }}>Please enter a valid email address</p>}
                            <div className='text-clr1 fs-12 mt-1'>We'll send relevant jobs and updates to this email</div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Password<span className='text-danger'>*</span></Form.Label>
                            <Form.Control type="password" name="password" value={formData.password} onChange={handleInputChange} placeholder="(Minimum 6 characters)" maxLength="40" autoComplete='off' required />
                            <div className='text-clr1 fs-12 mt-1'>This helps your account stay protected</div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Mobile number<span className='text-danger'>*</span></Form.Label>
                            <InputGroup className='rounded-4 overflow-hidden'>
                                <InputGroup.Text id="basic-addon1" className='fs-14 fw-semibold bg-white pe-2'>+91</InputGroup.Text>
                                <Form.Control type="text" className='border-start-0 ps-2' name="mobile" value={formData.mobile} onChange={handleInputChange} placeholder="Enter your mobile number" minLength={10} maxLength="10" autoComplete='off' required />
                            </InputGroup>
                            {!isValidNumber && <p style={{ color: 'red' }}>Please enter only numbers</p>}
                            <div className='text-clr1 fs-12 mt-1'>Recruiters will contact you on this number</div>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Work status<span className='text-danger'>*</span></Form.Label>
                            <div className="work-status-wrapper d-flex flex-column flex-sm-row gap-2 gap-xxl-3">
                                <div className="optionWrap">
                                    <input type="radio" className="btn-check" id="option1" name='type' value="experienced" checked={formData.type === "experienced"} onChange={handleInputChange} autoComplete="off" required />
                                    <label htmlFor="option1" className="work-details d-flex align-items-center justify-content-between gap-2 border rounded-4 p-3">
                                        <div className="textWrap">
                                            <h2 className="text-light fs-6 fw-medium mb-1">I'm experienced</h2>
                                            <p className="subtitle fs-14 text-clr1 m-0">I have work experience (excluding internships)</p>
                                        </div>
                                        <div className="iconWrap">
                                            <img src={briefcase} alt='briefcase' className='img-fluid' />
                                        </div>
                                    </label>
                                </div>
                                <div className="optionWrap">
                                    <input type="radio" className="btn-check" name='type' value="fresher" checked={formData.type === "fresher"} onChange={handleInputChange} id="option2" autoComplete="off" />
                                    <label htmlFor="option2" className="work-details d-flex align-items-center justify-content-between gap-2 border rounded-4 p-3">
                                        <div className="textWrap">
                                            <h2 className="text-light fs-6 fw-medium mb-1">I'm a fresher</h2>
                                            <p className="subtitle fs-14 text-clr1 m-0">I am a student/ Haven't worked after graduation </p>
                                        </div>
                                        <div className="iconWrap">
                                            <img src={schoolbook} alt='Schoolbook' className='img-fluid' />
                                        </div>
                                    </label>
                                </div>
                            </div>
                        </Form.Group>

                        {/* fresher */}
                        {fresher ?
                            <>
                                <div className="wrapper mb-4">
                                    <Form.Group>
                                        <div className='d-flex flex-wrap justify-content-between column-gap-2'>
                                            <Form.Label>Current city<span className='text-danger'>*</span></Form.Label>
                                            <div className="form-check">
                                                <input type="checkbox"
                                                    checked={isChecked}
                                                    onChange={() => handleCheckboxChange(isChecked ? 0 : 1)}
                                                    className="form-check-input"
                                                    id="outsideIndia"
                                                />
                                                <label className="text-light text-opacity-75 fs-14 fw-normal form-check-label" htmlFor="outsideIndia">Outside India</label>
                                            </div>
                                        </div>
                                        {/* current city */}
                                        <div className="skills-wrapper mb-4">
                                            <div className="d-flex flex-wrap gap-2">
                                                {upperItems.map((item) => (
                                                    <button key={item.id} type='button' className='btn btn-secondary bg-opacity-50 rounded-pill mt-3 py-1 px-3' onClick={() => moveItemToBottom(item, item.id)}>{item.content} x</button>
                                                ))}
                                            </div>
                                            <div className='text-clr1 fs-12 mt-1'>This helps recruiters know your location preferences</div>
                                        </div>
                                        {hidetag ?
                                            <>
                                                <div className="mb-2">
                                                    {/* <Select
                                                        // isMulti 
                                                        // name="current_city"
                                                        closeMenuOnSelect={true} className='custom-select text-dark' classNamePrefix='custom-select' required
                                                        components={animatedComponents}
                                                        // defaultValue={[colourOptions[4], colourOptions[5]]}
                                                        options={options}
                                                        // styles={customStyles}
                                                        placeholder="Current City"
                                                        onChange={handlecity}
                                                        value={selectedCity}
                                                    /> */}
                                                </div>
                                                <Autosuggest
                                        
                                                    suggestions={suggestions}
                                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                                    getSuggestionValue={getSuggestionValue}
                                                    renderSuggestion={renderSuggestion}
                                                    inputProps={inputProps}
                                                    
                                                />
                                                <div className="suggestion-wrapper mt-3 pb-2">
                                                    <div className="suggestion-label fs-14 text-clr1 mb-2">Suggestion:</div>
                                                    <div className="d-flex flex-wrap gap-2 text-capitalize">
                                                        <div className="skills-wrapper">
                                                            <div className="d-flex flex-wrap gap-2">
                                                                {lowerItems.map((item) => (
                                                                    <button key={item.id} type='button' className='btn btn-outline-light bg-opacity-50 rounded-pill py-1 px-3' onClick={() => moveItemToUpper(item, item.id)}>{item.content}</button>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div></> : ""}
                                    </Form.Group>
                                </div>

                                {isChecked ?
                                    <Form.Group className="mb-4">
                                        <Form.Label>Current country<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="text" name="current_country" value={formData.current_country} onChange={handleInputChange} placeholder="Mention the country where you're staying" autoComplete='off' required />
                                    </Form.Group> : ""}
                            </> : <Form.Group className="mb-4">
                                <Form.Label className="d-block">Resume</Form.Label>
                                {selectedFile ?
                                    <>
                                        <div className='d-flex align-items-center gap-3'>
                                            <div className='d-inline-flex align-items-center justify-content-between gap-5 border border-secondary rounded-pill px-3 py-2'>
                                                <div className='fileName fs-14 text-warning'><i className="bi bi-paperclip"></i> {selectedFile.name}</div>
                                                <input type='file' name='file' id='file-upload' accept='.doc,.docx,.rtf,.pdf' onChange={handleFileChange} style={{ display: 'none' }} />
                                                <label htmlFor='file-upload' className='text-primary fw-semibold'>Replace</label>
                                            </div>
                                            <span onClick={handleFileDelete} className='icon bg-danger d-flex align-items-center justify-content-center rounded-circle pointer' style={{ height: '35px', width: '35px' }}>
                                                <i className='bi bi-trash3 text-white'></i>
                                            </span>
                                        </div>
                                        <div className='fileDate text-clr2 fs-14 fw-light mt-1'>Last Updated: {uploadDate.toLocaleString()}</div>
                                    </> :
                                    <>
                                        <div className="upload-wrapper">
                                            <div className='d-inline-flex align-items-center gap-3 border border-secondary rounded-pill'>
                                                <input type='file' name='file' id='file-upload' accept='.doc,.docx,.rtf,.pdf' onChange={handleFileChange} style={{ display: 'none' }} />
                                                <label htmlFor='file-upload' className='btn btn-sm btn-danger rounded-pill fw-medium px-3 py-2'>Update Resume</label>
                                                <div className='widgetCont text-white text-opacity-75 me-3'>DOC, DOCx, PDF, RTF | Max: 2 MB</div>
                                            </div>
                                            {errorMessage && <div className='text-danger mt-1'>{errorMessage}</div>}
                                        </div>
                                        <div className='text-clr1 fs-12 mt-1'>Recruiters prefer candidates who have a resume on their profile</div>
                                    </>
                                }
                            </Form.Group>}
                        {/* fresher */}



                        <div className="form-check mb-4">
                            <input type="checkbox" className="form-check-input" id="whatsappCheck" />
                            <label className="text-light form-check-label fs-14 fw-medium" htmlFor="whatsappCheck">Send me important updates & promotions via SMS, email, and <img src={whatsapp} alt='Whatsapp Icon' className="img-fluid" /> <span className="whatsappText">WhatsApp</span></label>
                        </div>

                        <p className="fs-12 text-light text-opacity-75">By clicking Sign in, you agree to the <Link to="#" target="_blank" className="btn-link text-decoration-none">Terms and Conditions</Link> & <Link to="#" target="_blank" className="btn-link text-decoration-none">Privacy Policy</Link> of Virtualemployee.com</p>

                        <div className="btn-wrapper mt-3">
                            <Button type='submit' variant="primary" className='rounded-pill px-4'>Register now</Button>
                            {/* <Button type='submit' variant="primary" className='rounded-pill px-4' onClick={openVerifyMobileCard}>Register now</Button> */}
                            
                        </div>
                    </Form>
                </div>
                <div className="col-lg-3">
                    <hr className='d-lg-none my-4' />
                    <div className="d-flex align-items-center justify-content-center gap-4 mt-lg-5 pt-lg-4">
                        <div className="d-none d-lg-flex flex-column gap-2 text-clr1" style={{ height: '200px' }}>
                            <span className='vr flex-fill ms-2'></span>
                            <span className=''>Or</span>
                            <span className='vr flex-fill ms-2'></span>
                        </div>

                        <div className="text-center">
                            <div className='text-light fw-medium mb-2'>Continue with</div>
                            <Button variant='outline-primary' className='d-flex align-items-center justify-content-center gap-2 rounded-pill mx-auto px-3 py-2 lh-sm'>
                                <img src={googleIcon} alt='Google Icon' className="img-fluid" style={{ width: '15px' }} /> Google
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}
        </div>
    )
}

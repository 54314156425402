import React, { useState } from 'react';
import { jsPDF } from 'jspdf';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import html2canvas from 'html2canvas';
import { Modal, Button } from 'react-bootstrap';
// import FilePreview from './FilePreview';
import CryptoJS from 'crypto-js';
import * as XLSX from 'xlsx';

const Base26Converter = ({ sendattachmentValue }) => {
  const [fileContent, setFileContent] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [convertedContent, setConvertedContent] = useState(null);  // Content to display in modal
  const [fileType, setFileType] = useState('');

  const generateDataFromBase = (getdata) => {
    console.log(getdata)
    const passValues = getdata?.body
    if (getdata?.mimetype == "image/png") {
      generateImage(passValues)
    }
    else if (getdata?.mimetype == 'application/pdf') {
      generatePDF(passValues)
    } else if(getdata?.mimetype == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      generateExcelFromBase64(passValues)
    }
    else {
      generateWord(passValues)
    }
  }

  const decodeBase64 = (base64) => {
    return atob(base64);
  };

  // Function to convert Base26 to PDF
  const generatePDF = (getValuedata) => {
    const decodedText = decodeBase64(getValuedata);
    const doc = new jsPDF();
    doc.text(decodedText, 10, 10);
    // Convert PDF to base64 URL for display in modal
    const pdfBlob = doc.output('blob');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    setConvertedContent(pdfUrl);
    setFileContent(pdfUrl);
    setFileType('pdf');
    setModalShow(true);  // Show modal
    // Open the PDF in a new tab
  };

  // Function to convert Base26 to Word
  const generateWord = (getValuedata) => {
    const decodedText = decodeBase64(getValuedata);
    const doc = new Document({
      sections: [
        {
          properties: {},
          children: [
            new Paragraph({
              children: [new TextRun(decodedText)],
            }),
          ],
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      const url = URL.createObjectURL(blob);
      setFileContent(url);
      setConvertedContent(url);
      setFileType('word');
      setModalShow(true);  // Show modal
    });
  };

  const generateImage = (getValuedata) => {
    const imgData = `data:image/png;base64,${getValuedata}`;
    const imageUrl = URL.createObjectURL(new Blob([imgData], { type: 'image/png' }));
    // setFileUrl(imageUrl);
    setFileContent(imageUrl);
    setConvertedContent(imgData);
    setFileType('image');
    setModalShow(true);  // Show modal
  };

  // Excelsheet converter
  const generateExcelFromBase64 = (getValuedata) => {
    // Decode base64 to binary string
    const binaryData = atob(getValuedata);

    // Convert binary string into an ArrayBuffer
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    // Fill the ArrayBuffer with binary data
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }

    // Load the Excel file using SheetJS (xlsx) library
    const workbook = XLSX.read(arrayBuffer, { type: 'array' });

    // Convert workbook to Blob (Excel format)
    const excelBlob = new Blob([workbook], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Generate an object URL for the Excel file
    const url = URL.createObjectURL(excelBlob);

    // Optional: Set the file content, and show a modal, etc.
    setFileContent(url);
    setConvertedContent(url);
    setFileType('excel');
    setModalShow(true);  // Show modal
  };


  return (
    <div>
      {sendattachmentValue?.map((itemvalue) => (
        <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2"
          onClick={() => generateDataFromBase(itemvalue)}
        >
          <i className="bi bi-file-earmark-pdf-fill text-light"></i>
          <span className="text-truncate">{itemvalue?.filename}</span>
          <i className="bi bi-eye-fill ms-1"></i>
        </div>
      ))}

      {/* Modal for displaying converted file */}
      <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
        {/* <Modal.Header closeButton>       
        </Modal.Header> */}
        <Modal.Body>
          {/* Conditionally render content based on file type */}
          {fileType === 'pdf' && (
            <iframe src={convertedContent} width="100%" height="500px" />
          )}
          {fileType === 'excel' && (
            <iframe src={convertedContent} width="100%" height="500px" />
          )}
          {fileType === 'word' && (
            <iframe src={convertedContent} width="100%" height="500px" />
          )}
          {fileType === 'image' && <img src={convertedContent} alt="Converted" width="100%" />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Base26Converter;
import React, { useEffect, useState } from "react";
import './Notes.scss';
import { Button, Form, Pagination, Tab, Tabs } from 'react-bootstrap';
import SearchIcon from './resources/images/search.svg';
// import { CreateNotesModal } from "./CreateNotesModal"; 
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Loader from "./components/Loader";
import Paginationpage from "./components/Pagination";
import axios from 'axios';

import moment from 'moment';
import DocumentViewModal from "./components/DocumentViewModal";
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import Base26Converter from "./components/Base26Converter";
import { environment } from "../environment";
import { callAxios } from "../utils/utils";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
// Custom Accordion
function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        console.log('totally custom!'),
    );

    return (
        <div className="d-flex flex-wrap flex-sm-nowrap gap-2" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

const InHouseNotes = ({ setLoggedinUser }) => {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();
 
    const [getdata, setData] = useState([]);  // To store the response data
    const [loading, setLoading] = useState(false); // To track loading state
    const [error, setError] = useState(''); // To store any errors
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [searchTerm, setSearchTerm] = useState('');
    const [getOpportunity, setOpportunity] = useState('')
    const [getleadData, setleadData] = useState('')
    const [getSort, setSort] = useState('ASC')
    const [getOpportunitydetail, setOpportunitydetail] = useState('')
    const [getRefreshtime, setRefreshtime] = useState('')

    const [tabContent, setTabContent] = useState('InHouseNotes');

    // Create notes modal
    const [showCreateNotes, setShowCreateNotes] = useState(false);
    const openCreateNotesModal = () => setShowCreateNotes(true);
    const closeCreateNotesModal = () => setShowCreateNotes(false);

    // For Sidebar list selected card
    const [active, setActive] = useState(0);

    // For date steps functionality
    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 6;

    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
        //   loadWallet(userRole);
    
        }
      }, [user]);

    const handleNext = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    // View more show less functionality
    const [isExpanded, setIsExpanded] = useState(false);
    const [activeIndex, setActiveIndex] = useState(false)
    const [activeActionDetails, setActiveActionDetails] = useState(null);
    const [smallLoading, setSmallLoading] = useState(false);
    const [getAttachments, setAttachments] = useState([]);
    // pagination
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const itemsPerPage = 10;
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    const viewAttachmentFile = (type, value, btntype) => {
        let sendInfo = { "type": type, "value": value }
        if (btntype == 'viewFile') {
            const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();
            // navigate(`/inhousenotefileview?query=${encodeURIComponent(encryptedQuery)}`);
            const url = `/inhousefileview?query=${encodeURIComponent(encryptedQuery)}`;
            const popupWidth = "100%";
            const popupHeight = 600;
            const popupFeatures = `width=${popupWidth},height=${popupHeight},resizable=yes,scrollbars=yes`;
            // Open the popup
            // window.open(url, '_blank', popupFeatures);
            window.open(url, '_blank');
        }
    }
    // Listing data
    const handlePostRequest = async (searchValue) => {
        setLoading(true);  // Start loading
       
        const postData = {
            "startDate": startDate,
            "endDate": endDate,
            "isFilterByOppId": searchValue == '' ? false : true,
            "topicName": searchValue
        };
        try {
            const response = await callAxios(`${environment.REACT_APP_API_URL_Live_CRM}api/v1/co-ordination/opportunity-listing`, postData, "post");
            if (response?.data.statuscode == 200) {
                const listingData = response.data
                console.log(listingData.opportunityList.length)
                console.log("listingData")
                // console.log(listingData.opportunityList[0])
                setData(listingData.opportunityList);
                await handleInhouseNotes(listingData.opportunityList[0])
                await setleadData(listingData.opportunityList[0])
                // if(listingData.opportunityList.length == 0){
                //     setData([])
                //     handleInhouseNotes(null)
                //     setleadData(null)
                //     setOpportunity([])
                //     sethandledata([])
                // }
            }
            setLoading(false);
            // Store the response data
        } catch (error) {
            // setError(error.message);  // Store the error message
            setLoading(false);
        } finally {
            setLoading(false);  // Stop loading
        }
    };

    const debounceDelay = 500;
    useEffect(() => { 
        console.log("in use effect")
        if (searchTerm == '') {
            handlePostRequest(searchTerm)
            return
        };
        const timer = setTimeout(() => {
            handlePostRequest(searchTerm);
        }, debounceDelay);
        return () => clearTimeout(timer);
    }, [endDate, startDate, searchTerm])

    // Handle the start date change
    const handleStartDateChange = (event) => {
        const value = event.target.value;
        setStartDate(value);
        if (endDate && value > endDate) {
            setError('End date cannot be less than start date');
        } else {
            setError('');
        }
    };

    // Handle the end date change
    const handleEndDateChange = (event) => {
        const value = event.target.value;
        setEndDate(value);
        if (value < startDate) {
            setError('End date cannot be less than start date');
        } else {
            setError('');
        }
    };

    const handleSubmit = () => {
        if (!startDate || !endDate) {
            setError('Both start date and end date are required');
        } else if (startDate > endDate) {
            setError('End date cannot be less than start date');
        } else {
            setError('');
            alert(`Start Date: ${startDate}, End Date: ${endDate}`);
        }
    };

    const handleClick = (index, opportunityVal) => {
        setActive(index)
        handleInhouseNotes(opportunityVal)
        setleadData(opportunityVal)
    }

    const handleInhouseNotes = async (opportunityVal, sortVal = 'default') => {
        setRefreshtime('')
        handleActions(opportunityVal.opportunityid)
        if (sortVal == '') {
            setRefreshtime(moment().format('h:mmA, DD/MM/YYYY'))
        }
        setOpportunitydetail(opportunityVal)
        setLoading(true);  // Start loading
     
        const postData = {
            opportunityid: opportunityVal.opportunityid,
            opportunityCreatedon: opportunityVal.createdon,
            orderBy: sortVal == '' ? 'ASC' : getSort // ASC/DESC based on sortVal
        };
        // console.log(opportunityVal);
        try {
            const response = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/conversation/in-house-notes`, postData, "post");
            const opportunityData = response.data
            setOpportunity(opportunityData.allNotes);
            setLoading(false);
            // Store the response data
        } catch (error) {
            // setError(error.message);  // Store the error message
            setLoading(false);
        } finally {
            setLoading(false);  // Stop loading
        }
    };

    useEffect(() => {
        if (getOpportunitydetail != "")
            handleInhouseNotes(getOpportunitydetail)
    }, [getSort])

    const [getUsersdata, setUsersdata] = useState([])
    const getSystemusers = async () => {
       
        const postData = {
            operation: "fetchsystemusers",
        };
        try {
            const response = await callAxios(`${environment.REACT_APP_API_URL_Live_CRM}api/v1/co-ordination/fetch-actions`, postData, "post");
            const opportunityData = response.data.users
            console.log(opportunityData)
            console.log("opportunityData")
            let storeUsers = []
            opportunityData?.map((item) => {
                storeUsers[item.systemuserid] = item.yomifullname
            })
            console.log(storeUsers)
            console.log("storeUsers")
            setUsersdata(storeUsers)
            // setUsersdata(opportunityData)
            // setLoading(false);
            // Store the response data
        } catch (error) {
            // setError(error.message);  // Store the error message
        } finally {
            setLoading(false);  // Stop loading
        }
    }

    useEffect(() => {
        getSystemusers()
    }, [])

    const getInitials = (name) => {
        const nameArray = name?.split(' ');
        const initials = nameArray.map(word => word.charAt(0).toUpperCase());
        return initials.join('');
    };

    const formattedDate = (dateString) => {
        const formattedDate = moment(dateString).format('DD MMM YYYY, hh:mm A');
        return formattedDate
    }

    const sortingData = (getSortval) => {
        // console.log(getSortval)
        if (getSortval == 'ASC') {
            setSort('DESC')
        } else {
            setSort('ASC')
        }
    }

    const [gethandledata, sethandledata] = useState([])
    const [getactionRefresh, setactionRefresh] = useState('')
    const handleActions = async (opportunityVal, sortVal = 'default') => {
        setactionRefresh('')
        if (sortVal == '') {
            setactionRefresh(moment().format('h:mmA, DD/MM/YYYY'))
        }
        setLoading(true);  // Start loading
       
        // console.log(refreshedToken)
        const postData = {
            opportunityId: opportunityVal,
            operation: 'actions'
        };
        try {
            const response = await axios.post(`${environment.REACT_APP_API_URL_Live_CRM}api/v1/co-ordination/fetch-actions`, postData, "post");
            const actionsData = response.data
            const getallactionsData = actionsData
            if (getallactionsData?.actions.length >= 1) {
                const getdifferencevalue = await DateDifferenceCalculator(getallactionsData.actions)
                // sethandledata(actionsData.actions);
                sethandledata(getdifferencevalue);
            }
            setLoading(false);
            // Store the response data
        } catch (error) {
            // setError(error.message);  // Store the error message
            setLoading(false);
        } finally {
            setLoading(false);  // Stop loading
        }
    }

    // forSinceData
    // Utility function to format time difference in a human-readable format
    const [timeDifferences, setTimeDifferences] = useState([]);
    const formatTimeDifference = (diffInMs) => {
        const seconds = Math.floor(diffInMs / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        if (days > 0) {
            return `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
    };
    const DateDifferenceCalculator = (getallactionsData) => {
        const diffs = [];
        // Loop through the data to calculate time differences
        for (let i = 0; i < getallactionsData.length - 1; i++) {
            const currentDate = new Date(getallactionsData[i].createdon);
            const nextDate = new Date(getallactionsData[i + 1].createdon);

            const diffInMs = currentDate - nextDate; // Difference in milliseconds
            const formattedDiff = formatTimeDifference(Math.abs(diffInMs)); // Format and make absolute

            diffs.push({
                ...getallactionsData[i],
                nextCreatedon: getallactionsData[i + 1].createdon,
                timeDifference: formattedDiff
            });
        }
        return diffs
        setTimeDifferences(diffs);
    }
    // forSinceData
    const getActionDetails = () => {
        if (smallLoading) {
            return <div class="text-center font-white">Loading...</div>;
        }
        //emaildescription , attachments, fetchactivityparties
        if (activeActionDetails) {
            // console.log(activeActionDetails);
            return (
                <>
                    <div className="description-wrapper mb-3" dangerouslySetInnerHTML={{ __html: activeActionDetails?.description }}>
                    </div>
                    <div className="files-wrapper d-flex flex-wrap gap-2 mb-3">
                        {getAttachments?.length >= 1 ?
                            <>
                                <Base26Converter sendattachmentValue={getAttachments} />
                                {/* {getAttachments?.map((itemvalue) => (
                                    
                                    // <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
                                    //     <i className="bi bi-file-earmark-pdf-fill text-light"></i>
                                    //     <span className="text-truncate">{itemvalue?.filename}</span>
                                    //     <i className="bi bi-eye-fill ms-1"></i>
                                    // </div>
                                ))} */}
                            </>
                            : ""}
                    </div></>
            )
        }
    }
    const toggleExpand = async (index, value) => {
        console.log(value);
        console.log("value");
        setActiveIndex(index)
        setIsExpanded(!isExpanded);
        let actionDetails = {}
        if (value?.activitytypecode === 'email') {
            let postData = {
                operation: 'emaildescription',
                activityid: value.activityid
            }
            setSmallLoading(true);
            const emailBody = await handleActionsDetails(postData);
            console.log(emailBody);
            if (emailBody && emailBody.length > 0) {
                actionDetails.description = emailBody[0].description
            }
        } else if (value?.description) {
            actionDetails.description = value?.description
        } else if (value?.notetext) {
            actionDetails.description = value?.notetext
        } else if (value?.tect_description2) {
            actionDetails.description = value?.tect_description2
        }
        setActiveActionDetails(actionDetails);
        let postattachData = {
            operation: 'attachments',
            activityid: value.activityid
        }
        const attachmentsData = await handleActionsDetails(postattachData);
        if (attachmentsData?.length >= 1) {
            setAttachments(attachmentsData)
        } else {
            setAttachments([])
        }
    };
    const handleActionsDetails = async (postData) => {
        // setactionRefresh('')
        setSmallLoading(true);  // Start loading
        
        try {
            const response = await callAxios(`${environment.REACT_APP_API_URL_Live_CRM}api/v1/co-ordination/fetch-actions`, postData, "post");
            const actionsData = response.data
            setSmallLoading(false);
            return actionsData.actions
            // sethandledata(actionsData.actions);

            // Store the response data
        } catch (error) {
            // setError(error.message);  // Store the error message
            setSmallLoading(false);
        } finally {
            setSmallLoading(false);  // Stop loading
        }
    }
    const formatDateValue = (getDate) => {
        const dateStr = getDate;
        const date = new Date(dateStr);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
        return formattedDate
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [url, setUrl] = useState('');
    const [getDoctype, setDoctype] = useState("");

    const openModal = (url) => {
        setUrl(url);
        // getFileExtension(url)
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const getFileExtension = (fileName) => {
        const fileExtension = fileName.split('.').pop().toLowerCase(); // Get the part after the last dot
        // return fileExtension;
        if (['pdf'].includes(fileExtension)) {
            setDoctype('pdf')
            return 'pdf';
        }
        else if (['doc', 'docx'].includes(fileExtension)) {
            setDoctype('word')
            return 'word';
        } else if (['zip', 'rar', 'tar'].includes(fileExtension)) {
            setDoctype('zip')
            return 'zip'
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            setDoctype('image')
            return 'image'
        } else if (['mp3', 'wav', 'flac'].includes(fileExtension)) {
            setDoctype('audio')
            return 'audio';
        } else {
            return 'Unknown';
        }
    };
    // Listing data

    return (
        <div className='notes-page InHouseNotes-page py-3 backgroundBlack'>
            <div className="container-fluid px-xxl-5">
                <div className="d-flex flex-wrap flex-column flex-md-row align-items-md-center justify-content-between gap-2 mb-3">
                    <div className="fs-3 fw-semibold text-light lh-sm text-nowrap">In House Notes</div>
                    <div className="date-wrapper d-flex flex-wrap align-items-md-center gap-2 gap-lg-3 position-relative">
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateFrom">
                            <Form.Label className="m-0">From</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                max={new Date().toJSON().slice(0, 10)}
                                defaultValue={startDate}
                                onChange={handleStartDateChange}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column flex-md-row align-items-md-center flex-fill gap-1 gap-md-2 gap-xl-3" controlId="dateTo">
                            <Form.Label className="m-0">To</Form.Label>
                            <Form.Control type="date" className="bg-transparent py-2"
                                max={new Date().toJSON().slice(0, 10)}
                                defaultValue={endDate}
                                onChange={handleEndDateChange}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex flex-column justify-content-end flex-fill">
                            <Button variant="secondary" className="rounded-2 py-2">
                                <img src={SearchIcon} alt="" className="img-fluid align-text-top" onClick={handleSubmit} />
                                {/* <span className="d-md-none ms-1">Search</span> */}
                            </Button>
                        </Form.Group>
                        {error && <p className="position-absolute end-0" style={{ color: 'red', top: "43px" }}>{error}</p>}
                    </div>
                </div>

                <hr className="border border-1 border-opacity-75" />

                <div className="row g-3">
                    {loading ? (
                        <Loader />
                    ) : ''}
                        
                    <div className="col-md-5 col-lg-4 col-xl-3 col-xxl-3 mb-3 mb-md-0">
                        <div className="notes-list-section d-flex flex-column gap-2">
                            <div className='searchfield-wrapper position-relative mb-xxl-1'>
                                <input type="search" onChange={(e) => setSearchTerm(e.target.value)} className='form-control fs-14 text-light border-0 shadow-none py-2' placeholder='Search by Lead ID or Name' style={{ minHeight: '42px', backgroundColor: 'var(--theme-color3)' }} />
                                <img src={SearchIcon} alt='Search Icon' className='position-absolute top-50 end-0 translate-middle' />
                            </div>

                            {/* Displaying data as a list */}
                            {getdata?.length > 0 && (
                                <div className="d-flex flex-column gap-2 overflow-auto">
                                    {getdata?.map((item, index) => (
                                        <div
                                            className={`client-card rounded pointer p-3 ${index === active ? "selected" : ""}`}
                                            key={index}  // You can also use `item.new_opportunityid` as the key if it's unique
                                            // onClick={() => setActive(index)}  // Set active item
                                            onClick={() => handleClick(index, item)}
                                        >
                                            <div className="fs-18 fw-semibold text-truncate text-white lh-sm mb-1">
                                                {item.new_fullname}
                                            </div>
                                            <div className="client-email fs-14 text-white text-opacity-75 text-truncate text-capitalize">
                                                {item.new_opportunityid}
                                            </div>
                                            <div className="client-email fs-14 text-white text-opacity-75 text-truncate text-capitalize">
                                                {item.country}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* If no data */}
                            {getdata?.length === 0 && <p>No data available</p>}

                            {/* Pagination */}
                            {/* <Pagination className="justify-content-center gap-1 text-light mt-1 mb-0">
                                <Pagination.First />
                                <Pagination.Item>{1}</Pagination.Item>
                                <Pagination.Item active>{2}</Pagination.Item>
                                <Pagination.Ellipsis />
                                <Pagination.Item>{5}</Pagination.Item>
                                <Pagination.Last />
                            </Pagination> */}
                            <Paginationpage currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                        </div>
                    </div>


                    <div className="col-md-7 col-lg-8 col-xl-9 col-xxl-9">
                    {getdata?.length > 0 && (
                        <div className="notes-details-section dark-theme-bg2 rounded-3 p-3">
                            <div className="w-100">
                                <div className="top-info-section mb-4">
                                    <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                                        <div className="d-flex flex-column flex-md-row align-items-md-center column-gap-3 gap-lg-4">
                                            <div className="info-box text-capitalize">
                                                <div className="label fs-5 fw-medium text-warning">{getleadData?.new_fullname}</div>
                                                <div className="value text-light text-break">{getleadData?.new_opportunityid}</div>
                                            </div>
                                        </div>
                                        <div className="d-flex flex-wrap justify-content-lg-end gap-3 gap-lg-4">
                                            <div className="info-box border-end border-secondary pe-3 pe-lg-4">
                                                <div className="label fw-medium text-warning">{getleadData?.BdmName}</div>
                                                <div className="value text-light">BDM</div>
                                            </div>
                                            <div className="info-box">
                                                <div className="label fw-medium text-warning">{getleadData?.status}</div>
                                                <div className="value text-light">Status Reason</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-wrapper">
                                    <Tabs id="OpportunityDetails" activeKey={tabContent} onSelect={(k) => setTabContent(k)} className="rounded-top border-0 mb-3">
                                        <Tab eventKey="InHouseNotes" title="In House Notes">
                                            <div className='tab-content-wrapper'>
                                                <div className="actions-wrapper d-flex flex-wrap align-items-center gap-2 gap-xl-3 mb-3">
                                                    <Button variant="outline-warning" size="sm" className="text-light"><i className="bi bi-plus-circle"></i> Add Note</Button>
                                                    <Button variant="outline-warning" size="sm" className="text-light"><i className="bi bi-sort-down-alt" onClick={() => sortingData(getSort)}></i></Button>
                                                    <Button variant="outline-warning" size="sm" className="order-lg-last text-light"><i className="bi bi-arrow-clockwise" onClick={() => handleInhouseNotes(getOpportunitydetail, '')}></i></Button>
                                                    <div className="text-light ms-lg-auto">{getRefreshtime !== "" ? `Refreshed Last: ${getRefreshtime}` : ""}</div>
                                                </div>
                                                <Accordion defaultActiveKey={['0', '1']} alwaysOpen className="user-details-section w-100 d-flex flex-column gap-3">
                                                    {getOpportunity?.length > 0 && (
                                                        <div className="d-flex flex-column gap-2 overflow-auto">
                                                            {getOpportunity?.map((item, index) => (
                                                                // added
                                                                <div className="info-box dark-theme-bg3 rounded-3 overflow-hidden">
                                                                    <CustomToggle eventKey={index}>
                                                                        <div className={`info-header ${item?.userType == 'BDM' ? 'bg-success' : 'bg-info'} bg-opacity-75 w-100 d-flex flex-wrap justify-content-between gap-1 column-gap-3 text-uppercase lh-sm p-3`}>
                                                                            <div className="user-label text-white fw-semibold">{item?.userType}</div>
                                                                            <div className="time_ago fw-light text-white text-opacity-75">
                                                                                {item?.timeSinceLastUpdate.includes('NaN') ?
                                                                                    ""
                                                                                    :
                                                                                    <>
                                                                                        <span>{item?.timeSinceLastUpdate} Ago</span>
                                                                                        <span className="ms-2">Since Last Update</span>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </CustomToggle>
                                                                    <Accordion.Collapse eventKey={index} className="p-3">
                                                                        <div className="d-flex flex-wrap flex-sm-nowrap gap-2 gap-xl-3">
                                                                            <div className="user-img bg-warning bg-opacity-75 border rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '45px', width: '45px', minWidth: '45px' }}>
                                                                                {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
                                                                                <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">{getInitials(item?.name)}</div>
                                                                            </div>
                                                                            <div className="user-email-info w-100">
                                                                                <div className="user-details">
                                                                                    <div className="info-header fs-6 d-flex flex-wrap column-gap-2 mb-2">
                                                                                        <div className="label text-warning">Created By:</div>
                                                                                        <div className="cc-user-wrapper text-white text-opacity-75 text-capitalize">{item?.name} | {formattedDate(item?.createdAt)}</div>
                                                                                    </div>
                                                                                    <div className="subject-wrapper mb-3">
                                                                                        <div class="label fs-6 text-warning mb-1">Subject</div>
                                                                                        <div className="text-light text-opacity-75">{item?.subject}</div>
                                                                                    </div>

                                                                                    <div className="description-wrapper mb-3">
                                                                                        <div className="label fs-6 text-warning mb-1">Description</div>
                                                                                        <div className="text-light text-opacity-75 mb-3">
                                                                                            {item?.description}
                                                                                        </div>
                                                                                    </div>
                                                                                    {item?.attachments.resume?.length > 0 ?
                                                                                        <div className="all-files-wrapper mb-3">
                                                                                            <div className="label fs-6 text-warning mb-2">Resume</div>
                                                                                            <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
                                                                                                <>
                                                                                                    {item?.attachments.resume?.map((docItem, index) => (
                                                                                                        <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2 pointer" onClick={() => viewAttachmentFile('in-house-note', docItem?.id, 'viewFile')}>
                                                                                                            <i className={`bi bi-file-earmark-${getDoctype}-fill text-light`}></i>
                                                                                                            <span className="text-truncate">{docItem?.name}</span>
                                                                                                            <i className="bi bi-eye-fill ms-1"></i>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </>
                                                                                                {/* {isModalOpen && <DocumentViewModal url={url} closeModal={closeModal} />} */}
                                                                                            </div>
                                                                                        </div>
                                                                                        : ""}
                                                                                    {item?.attachments.proposal?.length > 0 ?
                                                                                        <div className="all-files-wrapper">
                                                                                            <div className="label fs-6 text-warning mb-2">Time Estimation/Proposal</div>
                                                                                            <div className="files-wrapper d-flex flex-wrap gap-2 mb-2">
                                                                                                <>
                                                                                                    {item?.attachments.proposal?.map((docItem, index) => (
                                                                                                        <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2 pointer" onClick={() => viewAttachmentFile('in-house-note', docItem?.id, 'viewFile')}>
                                                                                                            <i className={`bi bi-file-earmark-${getDoctype}-fill text-light`}></i>
                                                                                                            <span className="text-truncate">{docItem?.name}</span>
                                                                                                            <i className="bi bi-eye-fill ms-1"></i>
                                                                                                        </div>
                                                                                                    ))}
                                                                                                </>
                                                                                                {/* {isModalOpen && <DocumentViewModal url={url} closeModal={closeModal} />} */}
                                                                                            </div>
                                                                                        </div>
                                                                                        : ""}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Accordion.Collapse>
                                                                </div>
                                                                // added
                                                            ))}
                                                        </div>
                                                    )}
                                                    {/* If no data */}
                                                    {getOpportunity?.length === 0 && <p>No data available</p>}
                                                </Accordion>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Actions" title="Actions">
                                            <div className='tab-content-wrapper'>
                                                <div className="actions-wrapper d-flex flex-wrap align-items-center gap-2 gap-xl-3 mb-3">
                                                    <Button variant="outline-warning" size="sm" className="order-lg-last text-light"><i className="bi bi-arrow-clockwise" onClick={() => handleActions(getOpportunitydetail, '')}></i></Button>
                                                    <div className="text-light ms-lg-auto">{getactionRefresh !== "" ? `Refreshed Last: ${getactionRefresh}` : ""}</div>
                                                </div>
                                                <div className="d-flex flex-column gap-4">
                                                    {
                                                        gethandledata?.length > 0 ? (
                                                            gethandledata?.map((elementInArray, index) => (
                                                                <div className="d-flex flex-wrap flex-lg-nowrap gap-2 gap-xl-3" key={elementInArray.id || index}> {/* Use unique identifier like 'id' if available */}
                                                                    <div className="user-img bg-warning bg-opacity-75 border rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '45px', width: '45px', minWidth: '45px' }}>
                                                                        {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
                                                                        <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">{getInitials(getUsersdata[elementInArray?._createdby_value] ? getUsersdata[elementInArray?._createdby_value] : "Unknown")}</div>
                                                                    </div>
                                                                    <div className="user-details flex-fill fs-14">
                                                                        <div className="info-header row g-2 mb-3">
                                                                            <div className="col-lg-8">
                                                                                <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize lh-sm mb-1">
                                                                                    {/* {index === 0 ? (
                                                                                        <div><span className="text-cstm-green"><i className={`bi ${elementInArray?.activitytypecode != "email" ? "bi-file-text-fill" : "bi-envelope-at-fill"}  `}></i> Lead - </span> {elementInArray?.activitytypecode}</div>
                                                                                    ) : (
                                                                                        <div><span className="text-cstm-green"><i className="bi bi-envelope-at-fill"></i> Lead - </span> Email</div>
                                                                                    )} */}
                                                                                    <div><span className="text-cstm-green"><i className={`bi ${elementInArray?.activitytypecode != "email" ? "bi-file-text-fill" : "bi-envelope-at-fill"}  `}></i> Lead - </span> {elementInArray?.activitytypecode ? elementInArray?.activitytypecode : elementInArray?.objecttypecode}</div>
                                                                                </div>
                                                                                <div className="fs-14 d-flex flex-wrap column-gap-2">
                                                                                    <div className="text-warning text-capitalize">Created By:</div>
                                                                                    <div className="cc-user-wrapper fs-14 text-white text-capitalize">{getUsersdata[elementInArray?._createdby_value] ? getUsersdata[elementInArray?._createdby_value] : "Unknown"} | {formattedDate(elementInArray?.createdon)}</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-4">
                                                                                <div className="text-white fs-14 d-flex flex-wrap justify-content-xl-end column-gap-2">
                                                                                    <span className="name text-capitalize">{elementInArray?.timeDifference}</span>
                                                                                    <span className="text-warning text-capitalize">Since Last Update</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="subject-wrapper mb-3">
                                                                            <div className="title-label text-warning text-capitalize mb-1">Subject</div>
                                                                            <div className="text-light text-opacity-75">{elementInArray?.subject}</div>
                                                                        </div>
                                                                        {
                                                                            isExpanded && activeIndex === index ? getActionDetails() : ''
                                                                        }
                                                                        <div className="view-more-box d-flex align-items-center justify-content-between gap-3 border-bottom border-light border-opacity-50 pointer px-3 py-1" onClick={() => toggleExpand(index, elementInArray)}>

                                                                            {/* <div className="view-more-box d-flex align-items-center justify-content-between gap-3 border-bottom border-light border-opacity-50 pointer px-3 py-1"> */}
                                                                            <div className='fw-medium text-light text-opacity-75'>{isExpanded && activeIndex === index ? 'Show Less' : 'View More'}</div>
                                                                            <div className='icon lh-1'>
                                                                                <i className={`text-white fs-5 ${isExpanded && activeIndex === index ? 'bi bi-chevron-up' : 'bi bi-chevron-down'}`}></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        ) : (
                                                            <>
                                                                No Data available!
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Tab>

                                        <Tab eventKey="Summary" title="Summary">
                                            <div className='tab-content-wrapper'>
                                                <div className="row g-3 mb-2">
                                                    <div className="col-xl-6">
                                                        <div className="dark-theme-bg3 rounded-3 p-3 px-xl-4">
                                                            <div className="row row-cols-sm-2 g-3">
                                                                <div className="info-box text-capitalize">
                                                                    <div className="label fs-14 text-warning">Opportunity Created</div>
                                                                    <div className="value text-white text-opacity-75 text-break">{formatDateValue(getleadData?.createdon)}</div>
                                                                </div>
                                                                <div className="info-box text-capitalize">
                                                                    <div className="label fs-14 text-warning">Skill Required</div>
                                                                    <div className="value text-white text-opacity-75">{getleadData?.skills}</div>
                                                                </div>
                                                                <div className="info-box text-capitalize">
                                                                    <div className="label fs-14 text-warning">Opportunity Type</div>
                                                                    <div className="value text-white text-opacity-75">{getleadData?.opporutnityType}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="dark-theme-bg3 rounded-3 p-3 px-xl-4">
                                                            <div className="row row-cols-sm-2 g-3">
                                                                <div className="info-box text-capitalize">
                                                                    <div className="label fs-14 text-warning">Client Type</div>
                                                                    <div className="value text-white text-opacity-75 text-break">{getleadData?.clientType}</div>
                                                                </div>
                                                                <div className="info-box text-capitalize">
                                                                    <div className="label fs-14 text-warning">Country</div>
                                                                    <div className="value text-white text-opacity-75">{getleadData?.country}</div>
                                                                </div>
                                                                <div className="info-box text-capitalize">
                                                                    <div className="label fs-14 text-warning">TimeZone</div>
                                                                    <div className="value text-white text-opacity-75">{getleadData?.timezone}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="info-box p-3 px-xl-4">
                                                    <div className="label fs-14 text-warning mb-2">Requirement</div>
                                                    <div className="description text-white text-opacity-75">
                                                        <p>
                                                            {getleadData?.requirement}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>

                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* {getdata?.length === 0 && <p>No data available</p>} */}
                    </div>
                </div>
            </div >

            {/* Create Notes Modal */}
            {/* <CreateNotesModal show={showCreateNotes} close={closeCreateNotesModal} /> */}
        </div >
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(InHouseNotes);

import React from "react";
import { useNavigate } from "react-router-dom";

export const EarningSection = ({walletData, balancesData,disputeCases, approvedTotal,pendingTotal,getdashboardData, startDate, endDate}) => {
    console.log(walletData)
    const totalExpectedBalance = balancesData?.totalExpectedBalance ?? 0;
    const totalAvailableBalance = balancesData?.totalAvailableBalance ?? 0;
    const resultExpected = totalExpectedBalance - totalAvailableBalance;
    
    const bdmAvailable = balancesData?.totalAvailableBalance??0;

    const totalBdmExpected = balancesData?.totalSDMExpected??0;
    const totalBdmAvailable = balancesData?.totalSDMAvailableBalance??0;
    const resultBdmExpected = totalBdmExpected - totalBdmAvailable;

     let navigate = useNavigate();

    const handleListDisputeFilter = () => {
        // console.log(filter)
        navigate("/incentive-view", { state: { userRole: walletData?.userType, userEmail: walletData?.email, displayName: walletData?.name, activeIndex: 0, filterOption: 'disputed', startDate:startDate, endDate:endDate } })
    }    

const sdmAvailable = balancesData?.totalSDMAvailableBalance??0
    return (
        <>
            <div className="d-flex flex-wrap gap-4">
                <div className="title-wrapper">
                    <div className="heading-label text-white fs-3 fw-semibold text-nowrap lh-sm mb-2">
                        <i class="bi bi-wallet-fill me-2"></i> Earnings
                    </div>
                    <div className="total-dispute text-red fs-14 fw-medium pointer" onClick={handleListDisputeFilter}><i className="bi bi-exclamation-octagon-fill me-2"></i> {disputeCases && disputeCases?.length} Dispute Cases <span><i className="bi bi-arrow-right fs-6 text-white"></i></span></div>
                </div>
                <div className="d-flex flex-wrap flex-xxl-nowrap gap-3 gap-lg-2 flex-fill">
                    <div className="user-incentive flex-fill rounded-3 position-relative pt-3 p-2" style={{ backgroundColor: '#424659' }}>
                        <div className="d-flex align-items-center gap-2 gap-xxl-3 mt-1">
                            <div className="icon text-white fs-3 d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: '#6c5be8' }}>
                                <i class="bi bi-person-video3"></i>
                            </div>
                            <div className="info-cover d-flex align-items-center gap-2">
                                <div className="info-cover">
                                    <div className="user-title fs-12 text-white text-opacity-50">Expected</div>
                                    <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{resultExpected}</div>
                                </div>
                                <div className="info-cover border-secondary border-start ps-2">
                                    <div className="user-title fs-12 text-white text-opacity-50 text-truncate">Approval Waiting</div>
                                 <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹
                                    {/* {balancesData?.totalAvailableBalance}  */}
                                        {Math.abs(bdmAvailable - approvedTotal)}


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-role-label fs-12 text-white fw-semibold rounded-1 position-absolute top-0 start-0 lh-sm ms-2" style={{ backgroundColor: '#455472', padding: '2px 10px', marginTop: '-8px' }}>BDM</div>
                        <div className="text-white text-opacity-75 position-absolute top-0 end-0 mt-1 px-2" style={{ marginTop: '-8px' }}>
                            <i class="bi bi-info-circle-fill" title="Loirem ipsum is simply dummy text"></i>
                        </div>
                    </div>
                    <div className="user-incentive flex-fill rounded-3 position-relative pt-3 p-2" style={{ backgroundColor: '#424659' }}>
                        <div className="d-flex align-items-center gap-2 gap-xxl-3 mt-1">
                            <div className="icon text-white fs-3 d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: '#9b59b6' }}>
                                <i class="bi bi-person-workspace"></i>
                            </div>
                            <div className="info-cover d-flex align-items-center gap-2">
                                <div className="info-cover">
                                    <div className="user-title fs-12 text-white text-opacity-50">Expected</div>
                                    <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{resultBdmExpected}</div>
                                </div>
                                <div className="info-cover border-secondary border-start ps-2">
                                    <div className="user-title fs-12 text-white text-opacity-50 text-truncate">Approval Waiting</div>
                                    <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹{sdmAvailable}</div>
                                </div>
                            </div>
                        </div>
                        <div className="user-role-label fs-12 text-white fw-semibold rounded-1 position-absolute top-0 start-0 lh-sm ms-2" style={{ backgroundColor: '#455472', padding: '2px 10px', marginTop: '-8px' }}>SDM</div>
                        <div className="text-white text-opacity-75 position-absolute top-0 end-0 mt-1 px-2" style={{ marginTop: '-8px' }}>
                            <i class="bi bi-info-circle-fill" title="Loirem ipsum is simply dummy text"></i>
                        </div>
                    </div>
                    <div className="user-incentive flex-fill rounded-3 position-relative pt-3 p-2" style={{ backgroundColor: '#424659' }}>
                        <div className="d-flex align-items-center gap-2 gap-xxl-3 mt-1">
                            <div className="icon text-white fs-3 d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: '#2993d6' }}>
                                <i class="bi bi-person-workspace"></i>
                            </div>
                            <div className="info-cover d-flex align-items-center gap-2">
                                <div className="info-cover">
                                    <div className="user-title fs-12 text-white text-opacity-50">Expected</div>
                                    <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹0</div>
                                </div>
                                <div className="info-cover border-secondary border-start ps-2">
                                    <div className="user-title fs-12 text-white text-opacity-50 text-truncate">Approval Waiting</div>
                                    <div className="heading-label text-white fs-18 fw-semibold lh-sm">₹0</div>
                                </div>
                            </div>
                        </div>

                        <div className="user-role-label fs-12 text-white fw-semibold rounded-1 position-absolute top-0 start-0 lh-sm ms-2" style={{ backgroundColor: '#455472', padding: '2px 10px', marginTop: '-8px' }}>Supported BDM</div>
                        <div className="text-white text-opacity-75 position-absolute top-0 end-0 mt-1 px-2" style={{ marginTop: '-8px' }}>
                            <i class="bi bi-info-circle-fill" title="Loirem ipsum is simply dummy text"></i>
                        </div>
                    </div>
                    <div className="info-box flex-fill text-end rounded-3 px-3 py-2" style={{ backgroundColor: '#4834d3', }}>
                        <div className="heading-label text-white fs-4 fw-semibold mt-1">₹{walletData?.lifetime_balance}</div>
                        <div className="description text-white text-opacity-75 fs-14 text-truncate lh-sm">Amount Transferred</div>
                    </div>
                    <div className="info-box flex-fill text-end rounded-3 px-3 py-2" style={{ backgroundColor: '#159b5c', }}>
                        <div className="heading-label text-white fs-4 fw-semibold mt-1">₹{walletData?.available_to_redeem}</div>
                        <div className="description text-white text-opacity-75 fs-14 text-truncate lh-sm">Available balance to redeem</div>
                    </div>
                </div>
            </div>
        </>
    )
}
import React, { useEffect, useState } from "react";

const BDMStory = ({ bdmdata, claimerdetails }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB'); 
    };
    return (
        <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden h-100">
            <div className="header-section d-flex flex-wrap justify-content-between row-gap-1 column-gap-3 p-3" style={{ backgroundColor: '#7d2930' }}>
                <div className="d-flex gap-2 text-truncate">
                    <div className="user-img mt-1">
                        <img src={require('../../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                    </div>
                    <div className="user-info text-truncate text-capitalize">
                        <div className="user-name text-white fw-semibold text-truncate">{bdmdata?.raiser_name}</div>
                        <div className="user-empCode text-white text-truncate">Despute Raiser</div>
                        <div className="opportunity-added fs-14 text-warning text-wrap text-capitalize mb-1">Despute Raised on : <span className="text-white ms-1">{formatDate(bdmdata?.createdAt)}</span></div>
                    </div>
                </div>
                <div className="info-box text-md-end ms-5 ps-1">
                    <div className="label text-warning">Claimed Role</div>
                    <div className="value text-white text-opacity-75 text-break">{bdmdata?.role_for_this_opportunity}</div>
                </div>
            </div>
            <div className="body-section p-3 pb-4">
                <div className="wrapper ms-md-5 ps-md-1">
                    <div className="info-box mb-4">
                        <div className="fs-6 text-warning mb-2">Discussion on Client Calls</div>
                        <div className="msg-description">
                            {bdmdata?.discussion_on_client_call}
                        </div>
                    </div>
                    <div className="info-box mb-4">
                        <div className="fs-6 text-warning mb-2">Did Coordination reach out to you regarding the next steps?</div>
                        <div className="msg-description">
                            {bdmdata?.did_coordination_reach_out_to_you}
                        </div>
                    </div>
                    <div className="info-box mb-4">
                        <div className="fs-6 text-warning mb-2">
                            Do you think {claimerdetails?.name} has mistakenly added this opportunity to his wallet, or do you believe
                            he is involved in this opportunity but as a Supported BDM rather than the main BDM?
                        </div>
                        <div className="msg-description">
                            {bdmdata?.someone_mistakenly_added}
                        </div>
                    </div>
                    <div className="checklist-wrapper mb-4">
                        <div className="fs-6 text-warning mb-2">Please clearly outline {claimerdetails?.name}’s contributions to this opportunity as a supporting BDM, according to your understanding.</div>
                        <ul className="m-0">
                            {bdmdata?.contribution?.map((items, index) => (
                                <>
                                    <li>{items.text}</li>
                                </>
                            ))}

                        </ul>
                    </div>
                    <div className="info-box mb-4">
                        <div className="fs-6 text-warning mb-2">Please provide the email of the Coordination employee you communicated with regarding this opportunity.</div>
                        <div className="msg-description">
                            {bdmdata?.coordination_email}
                        </div>
                    </div>
                    <div className="info-box">
                        <div className="fs-6 text-warning mb-2">Message</div>
                        <div className="msg-description">
                            {bdmdata?.additional_information}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BDMStory;
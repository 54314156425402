import React, { useState } from "react";
import { Button } from 'react-bootstrap'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { callAxiosFile, log__ } from "../../utils/utils";
import { environment } from "../../environment";

export const CommentSection = ({ disputeConversation,item,userRole, currentDisputer, setIsFullLoading }) => {
    const [file, setFile] = useState(null);
    const [getAttachmenturl, setAttachmenturl] = useState('');
    const [getConversation, setConversation] = useState('');
    const [fileName, setFileName] = useState('');
    const allowedTypes = ['image/jpeg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation','application/pdf'];

    const resetFileInput = () => {
        const fileInput = document.getElementById('file-upload');
        fileInput.value = '';  
    };
    log__("currentDisputer::", currentDisputer)
    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && !allowedTypes.includes(selectedFile.type)) {
            toast.error('Invalid file type. Please upload a supported file.', {
                position: "bottom-center",
            });
            return;
        }
        setFile(selectedFile);
        setFileName(selectedFile?.name)
        const getfileUrl = await uploadFileToS3(selectedFile)
        setAttachmenturl(getfileUrl)
    };

    const uploadFileToS3 = async (getFile) => {
        if (!getFile) {
            console.error('No file selected');
            return;
        }

        // Generate dynamic file path and name
        const currentDateTime = moment().format('MM/DD/YYYY/HH/mm/ss');
        const gettopicname = 'testing upload';
        const topic = gettopicname.replace(/ /g, '_');
        const getemail = 'testing@upload.com';
        const useremail = getemail.replace(/@/g, '_');
        const filenameWithExtension = getFile?.name;
        const filetype = getFile?.type;

        const bucketName = 'nm-wallet';
        const filekey = `disputeConversation/${topic}/${useremail}/${currentDateTime}_${filenameWithExtension}`;

        // Prepare FormData
        const formData = new FormData();
        formData.append('bucketName', bucketName);
        formData.append('fileKey', filekey);
        formData.append('filetype', filetype);
        formData.append('file', getFile); 

        try {
            setIsFullLoading(true)
            const response = await callAxiosFile(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/upload-file`, formData);
         
            if (response?.data?.statusCode === 200) {
                setIsFullLoading(false)
                log__('File uploaded successfully:', response?.data?.url);
                const fileLocation = response?.data?.url;
                return fileLocation; // Return the file location (URL)
            } else {
                console.error('No file URL returned from API.');
            }
        } catch (error) {
            console.error('Error during file upload:', error);
        }
    };

    const handleChange = (e) => {
        setConversation(e.target.value);
    };

    const submitConversation = () => {
        const sendData = {
            "wallet_id": item?.__wallet?.id,
            "email": item?.__wallet?.email,
            "name": item?.__wallet?.name,
            "topic": item?.new_opportunityid.trim(),
            "userType": userRole,
            "text": getConversation,
            "attachment_url": getAttachmenturl
        }
        disputeConversation(sendData)
        setConversation("")
        setFileName("")
        setAttachmenturl(null)
        resetFileInput()
    }

    return (
        <>
            <div className="comment-section dark-theme-bg2 rounded-3 overflow-hidden p-3">
                <textarea rows={4} className="form-control bg-transparent border-0 shadow-none p-0 text-white" placeholder="Write here" id="commentsDesc" autoComplete="off" style={{ resize: 'none' }} value={getConversation} onChange={handleChange} ></textarea>
                <div className="d-flex flex-wrap align-items-center justify-content-end" style={{ marginTop: '-10px' }}>
                    {/* <input type="file" onChange={handleFileChange} />
                    <span className="text-white border-secondary border-end pe-3 me-3"><i className="bi bi-file-earmark-image fs-4 lh-1"></i></span> */}
                    <input
                        type="file"
                        id="file-upload"
                        className="d-none"
                        onChange={handleFileChange}
                    />

                    {/* Custom button to trigger file input */}
                    <label htmlFor="file-upload" className="text-white border-secondary border-end pe-3 me-3">
                        <i className="bi bi-file-earmark-image fs-4 lh-1"></i> {/* Icon */}
                    </label>
                    {/* Optionally, display the file name once uploaded */}
                    {fileName && (
                        <span className="text-white">{fileName}</span>
                    )}
                    <Button onClick={submitConversation} variant="success" size="sm"><i className="bi bi-send-fill"></i></Button>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}
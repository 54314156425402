import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';

export const ReadMoreModal = ({ show, close, readMoreText,startReadTime, user }) => {

    const saveReadMoreLogs = async()=>{
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/mark-read-announcement`,{
            "announcement_id": readMoreText.id,
            "email":user?.email,
            "read_start_time": startReadTime,
            "read_end_time": new Date(),
            "name":user?.displayName
        },"post");
    }
    return (
        <Modal show={show} centered onHide={close} size='lg' scrollable backdrop="static" keyboard={false} dialogClassName="ReadMoreModal" contentClassName='rounded-3 overflow-hidden'>
            <Modal.Body className="text-body px-3 px-md-4 px-lg-5 py-4">
                <div className="custom-wrapper my-3">
                    <div className="fs-3 fw-bold lh-sm mb-3">Announcement Details</div>
                    <div className="description fs-6  mb-4" dangerouslySetInnerHTML={{ __html: readMoreText.description }}>                  
                    </div>
                    <div className='btn-wrapper text-center'>
                    <Button variant='success' className='btn-custom fs-18' onClick={()=>{close();saveReadMoreLogs()}}>OK</Button>
                    </div>
                    
                </div>
            </Modal.Body>
        </Modal>
    )
}

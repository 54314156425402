import React from "react";

export const Leftside = ({ leftData, claimerData, dateFomate }) => {  
    return (
        <>
            <div className="h-100">
                <div className="user-info rounded-3 mb-3 p-3" style={{ backgroundColor: '#c23516' }}>
                    <div className="row row-cols-md-3 g-3">
                        <div className="info-box">
                            <div className="label fs-5 fw-semibold text-warning">{leftData?.name}</div>
                            <div className="value text-white text-opacity-75 text-break">{leftData?.new_name}</div>
                        </div>
                        <div className="total-Incentive">
                            <div className="fs-5 fw-semibold text-white">Total Incentive: ₹{leftData?.incentiveDetails?.total_incentive}</div>
                            <div className="fs-6 fw-semibold text-white text-opacity-50 text-capitalize">Incentive Type: {leftData?.incentiveDetails?.incentive_type}</div>
                        </div>
                        <div className="total-despute text-md-end">
                            <div className="fs-5 fw-semibold text-white">{leftData?.no_of_disputer}</div>
                            <div className="fs-6 fw-semibold text-white text-opacity-50 text-capitalize">Total Number of Dispute Raisers</div>
                        </div>
                    </div>
                </div>

                <div className="row g-3">
                    <div className="col-md-7">
                        <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden h-100">
                            <div className="header-section text-white fs-18 fw-bold p-3" style={{ backgroundColor: '#8e7011' }}>Claimers</div>
                            <div className="body-section">
                                <div className="info-card active rounded-3 p-3">
                                    <div className="d-flex gap-2">
                                        <div className="user-img mt-1">
                                            <img src={require('../../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                                        </div>
                                        <div className="user-info d-flex flex-column text-truncate">
                                            <div className="user-name text-white fw-semibold text-truncate text-capitalize">{claimerData?.name}</div>
                                            <div className="user-empCode text-white text-truncate text-capitalize">{claimerData?.designation} | {claimerData?.veid}</div>
                                            <div className="opportunity-added fs-14 text-warning text-wrap text-capitalize mb-1">Opportunity Added to the wallet : <span className="text-white ms-1">{dateFomate(claimerData?.opportunityAddedToWallet)}</span></div>
                                            <div className="fs-12" style={{ color: '#03ff85', whiteSpace: 'normal' }}><i className="bi bi-patch-check-fill"></i> Matching with <span className="text-capitalize">BDM</span> in Opportunity</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden h-100 p-3">
                            <div className="label-heading text-warning fs-6 fw-semibold mb-2">Management's Final Decision</div>
                            {/* <div className="desc">
                                            <div className="description text-white text-opacity-75 mb-3">Management must provide their final approval on this dispute to ensure the correct BDM claims the incentive amount</div>
                                            <div className="btn-wrapper text-center">
                                                <Button variant="outline-success" size="lg" className="fw-medium px-4 py-2" style={{ color: '#03ff84', borderColor: '#03ff84' }}>Submit Approval</Button>
                                            </div>
                                        </div> */}
                            <div className="desc">
                                <div className="description text-white text-opacity-75 mb-3">The management's final response will be visible here once submitted.</div>
                                <div className="fs-5 fw-normal text-danger text-center lh-sm">Management's Decision Pending!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
import { Button } from "react-bootstrap"
import { new_ClientType, new_opportunitytype, statuscode } from "../../utils/codes"
import { convertReadable } from "../../utils/utils"

export const SearchCard = ({resetSearch, item, bonus,setPolicy, userRole}) => {

    return (
        <div className="info-card w-100 rounded-3 px-4 py-3">
            <div className="text-end mb-2 text-white pointer" onClick={resetSearch}><i className="bi bi-x-lg"></i></div>
            <div className="custom-bg rounded-3">
                <div className="row g-2">
                    <div className="col-12 col-lg-3 info-box text-capitalize">
                        <div className="label fs-14 text-warning">Opportunity ID</div>
                        <div className="value text-white text-opacity-75 text-break">{item.new_opportunityid}</div>
                    </div>
                    <div className="col-sm-6 col-lg-2 info-box text-capitalize">
                        <div className="label fs-14 text-warning">Opportunity Created</div>
                        <div className="value text-white text-opacity-75">{convertReadable(item.createdon)}</div>
                    </div>
                    {/* <div className="col-sm-6 col-lg-3 info-box text-capitalize">
                        <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
                        <div className="value text-white text-opacity-75">{convertReadable(item.createdon)}</div>
                    </div> */}
                    <div className="col-sm-6 col-lg-2 info-box text-capitalize">
                        <div className="label fs-14 text-warning">Opportunity in Other's Wallet</div>
                        {item.othersWallet >1?
                        <div className="value text-white text-opacity-75">Yes</div>
                        :
                        <div className="value text-white text-opacity-75">No</div>
                        }
                    </div>
                    <div className="col-sm-6 col-lg-2 info-box text-capitalize">
                        <div className="label fs-14 text-warning">Opportunity Status</div>
                        <div className="value fw-medium text-info">{statuscode[item.statuscode]}</div>
                    </div>
                </div>
            </div>
            <div className="row g-2 mt-3">
                <div className="col-12 col-lg-3 info-box text-capitalize">
                    <div className="label fs-14 text-warning">Opportunity Type</div>
                    <div className="value text-white text-opacity-75">{new_opportunitytype[item.new_opportunitytype]}</div>
                </div>
                <div className="col-sm-6 col-lg-2 info-box text-capitalize">
                    <div className="label fs-14 text-warning">Client Type</div>
                    <div className="value text-white text-opacity-75">{new_ClientType[item.new_clienttype]}</div>
                </div>
                <div className="col-sm-6 col-lg-3 info-box text-capitalize">
                    <div className="label fs-14 text-warning">Skill Required</div>
                    <div className="value text-white text-opacity-75">{item.skills}</div>
                </div>
                <div className="col-sm-6 col-lg-2 info-box text-capitalize">
                    <div className="label fs-14 text-warning">Country</div>
                    <div className="value text-white text-opacity-75">{item.country}</div>
                </div>
                <div className="col-sm-6 col-lg-2 info-box text-capitalize">
                    <div className="label fs-14 text-warning">TimeZone</div>
                    <div className="value text-white text-opacity-75">{item.timezone}</div>
                </div>
            </div>
            <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
            <div className="description">
                <div className="row gx-2 gy-3">
                    <div className="col-lg-5">
                        {/* <div className="total-Incentive fs-5 fw-semibold text-white lh-sm mb-1">Total Incentive: ₹10,000</div>
                        <div className="fs-12 text-white text-opacity-75">Incentive Redemption: The process will be available to you once the Opportunity status turns WON and payment is received from the client.</div> */}
                        { bonus && Object.entries(bonus).length > 0?
                        <>
                        <div className="total-insentive fs-5 fw-semibold text-white lh-sm mb-1">Total Insentive: ₹{bonus.total_bonus}</div>
                        <div className="fs-12 text-white text-opacity-75">Insentive Redemption: The process will be available to you once the Opportunity status turns WON and payment is received from the client.</div>
                        </>
                        
                        : null}
                    </div>
                    <div className="col-lg-3">
                        <select className="form-select dark-theme-bg text-white py-2" onChange={(e)=>{setPolicy(e.target.value,userRole)}}>
                            <option value={""}>Select Incentive Type</option>
                            <option value={"new_hire_full_time"}>New Hire (Full-Time)</option>
                            <option value={"new_hire_part_time"}>New Hire (Part-Time)</option>
                            <option value={"upscaling_full_time"}>Upscaling (Full-Time)</option>
                            <option value={"upscaling_part_time"}>Upscaling (Part-Time)</option>
                            <option value={"cross_selling_full_time"}>Cross-Selling (Full-Time)</option>
                            <option value={"cross_selling_part_time"}>Cross-Selling (Part-Time)</option>
                            <option value={"client_referral_full_time"}>Client Referral (Full-Time)</option>
                            <option value={"client_referral_part_time"}>Client Referral (Part-Time)</option>
                        </select>
                        
                    </div>
                    <div className="col-lg-4 text-lg-end">
                        <Button variant="success" className="rounded-1 px-3 py-2">Add To My Wallet</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useState } from "react";
import BDMStory from "./BDMStory";
import SBDMStory from "./SBDM";
import { BDMConversation } from "./BDMConversation";

const DisputeStory = ({ disputerData, claimerData }) => {
    return (
        <>
            {disputerData?.map((storyData, index) => (
                <>
                    {storyData?.role_for_this_opportunity == "SBDM"
                        ? <SBDMStory sbdmdata = {storyData} claimerdetails={claimerData} />
                        : storyData?.role_for_this_opportunity == "BDM"
                            ? <BDMStory  bdmdata = {storyData}  claimerdetails={claimerData} />
                            : null}
                </>
            ))}
        </>
    )
}

export default DisputeStory;
import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

export const DeleteAnnouncementModal = ({ show, close, confirmDelete }) => {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleConfirmDelete = (e) => {
        e.preventDefault();

        if (!isChecked) {
            alert('Please confirm by checking the "I Understand" checkbox.');
            return;
        }

        confirmDelete(); // Trigger the delete action from the parent
    };
    return (
        <Modal show={show} centered onHide={close} size='lg' scrollable backdrop="static" keyboard={false} className='p-0' dialogClassName="deleteAnnouncementModal mx-auto" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="text-body text-center px-lg-4 px-xl-5 py-4">
                <div className="custom-wrapper my-2 pb-1">
                    <div className="fs-3 fw-bold text-dark lh-sm mb-4">Delete Announcement</div>
                    <Form onSubmit={handleConfirmDelete}>
                        <div className='description fs-5 fw-light mb-4'>
                            Are you certain you want to delete this announcement? Once deleted, it cannot be reactivated.
                        </div>
                        <div class="form-check-inline">
                        <input
                                className="form-check-input border border-secondary"
                                type="checkbox"
                                id="IUnderstand"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            <label class="form-check-label ms-2" for="IUnderstand">I Understand</label>
                        </div>
                        <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 mt-4 pt-2">
                            <Button variant="secondary" onClick={close} className='btn-custom fs-18 px-5 py-2'>Cancel</Button>
                            <Button type='submit' variant="danger" className='btn-custom fs-18 px-5 py-2' disabled={!isChecked}>Confirm</Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

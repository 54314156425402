import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { callAxios, log__ } from '../utils/utils';
import { environment } from '../environment';
import { toast } from 'react-toastify';

export const RedeemAmountModal = ({ show, close, redeemAmount, walletOppInfo, userRole, whichMonthRedeem, setShowRedeemAmountModal, loadWallet,opInfo, cardType, type, invoiceId,isSharedYour1stInstallment }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [sharedAmount, setSharedAmount]= useState(0);
    const [sharedEmail, setSharedEmail] = useState('');
     const [isLoading, setIsLoading] = useState(false);
    const [questions, setQuestions] = useState([
        'Did the supported BDM create the proposal for you?',
        'Did the supported BDM prepare time estimations for you?',
        'Was the supported BDM present with you on the client call for support?',
        'Did the supported BDM handle the client call alone in your absence?',
        'Did the supported BDM screen resumes carefully for you before sending their resumes to the client?',
        'Did the supported BDM interview candidates for you before sending their resumes to the client?',
        'Did the supported BDM conduct research on the client\'s requirements for you?'
      ]);
    // log__(redeemAmount, walletOppInfo)
    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleSubmitRedeem = (e) =>{
        log__("in redeem submit")
        e.preventDefault();
        // console.log(selectedOption)
        if(type === "bdm" && isSharedYour1stInstallment && isSharedYour1stInstallment?.is_share_your_incentive){
            setSelectedOption("optionYes")
        } 
        else if(type === "bdm" && isSharedYour1stInstallment && !isSharedYour1stInstallment?.is_share_your_incentive){
            setSelectedOption("optionNo")
        } else if(type === "sbdm"){
            setSelectedOption("optionNo")
        } else{
            if(selectedOption === ""){
                 toast.error("Select option first", {
                                                        position: "top-right",
                                                        autoClose: 2000,
                                                        hideProgressBar: false,
                                                        closeOnClick: true,
                                                        pauseOnHover: true,
                                                        draggable: true,
                                                        progress: undefined,
                                                        theme: "light",
                                                      });
                                                      return false
            }
        }
        setIsLoading(true);
        // return false
        if(cardType === "individual"){
            callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/redeem-amount`,{redeemAmount:redeemAmount,id:walletOppInfo.id, userRole:userRole,whichMonthRedeem:whichMonthRedeem,selectedOption:selectedOption, checkedItems:checkedItems,sharedEmail:sharedEmail,sharedAmount:sharedAmount, opInfo_:opInfo,cardType:cardType,type:type, invoiceId:invoiceId,isSharedYour1stInstallment:isSharedYour1stInstallment},"post").then((res)=>{
                if(res.status === 200){
                    setIsLoading(false);
                    setShowRedeemAmountModal(false);
                    loadWallet(userRole,['individual','invalidpolicy','beforeOct24','disputed'],"redeem-pending","Redeemed")
                    // toast.success("Incentive redeemed successfully!", {
                    //     position: "top-right",
                    //     autoClose: 2000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",
                    //   });
                }
            })
        } else{
            callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/redeem-amount`,{redeemAmount:redeemAmount,id:walletOppInfo.id, userRole:userRole,whichMonthRedeem:whichMonthRedeem,selectedOption:selectedOption, checkedItems:checkedItems,sharedEmail:sharedEmail,sharedAmount:sharedAmount, opInfo_:opInfo,cardType:cardType,type:type, invoiceId:invoiceId},"post").then((res)=>{
                if(res.status === 200){
                    setIsLoading(false);
                    setShowRedeemAmountModal(false);
                    loadWallet(userRole,res.data.cardType,"redeem-pending","Redeemed")
                    // toast.success("Incentive redeemed successfully!", {
                    //     position: "top-right",
                    //     autoClose: 2000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "light",
                    //   });
                }
            })
        }
        
    }

    const [checkedItems, setCheckedItems] = useState([]);

    const calculateSharedAmount = (amount) =>{
        setSharedAmount(amount)
    }

    const handleCheckboxChange = (option) => {
        if (checkedItems.includes(option)) {
          // If already checked, remove it from the array
          setCheckedItems(checkedItems.filter(item => item !== option));
        } else {
          // If not checked, add it to the array
          setCheckedItems([...checkedItems, option]);
        }
      };
    //   log__(checkedItems)
    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="redeemAmountModal" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="wallet-info-section text-black px-3 px-lg-5 py-4">
            {isLoading ?
            <div className="loader-overlay" id="loader" style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                height: '100vh',
                background: 'rgba(0, 0, 0, 0.8)',
                backdropFilter: 'blur(2px)'
            }}>
                <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />

                <div className="text-center w-50">
                    <p className='text-warning'>Please wait</p>

                    
                </div>

            </div>
            :null}
                <div className="fs-3 fw-bold lh-sm mb-2 mb-xl-3">Redeem Amount</div>
                <div className="amount fs-3 mb-3">
                    <span className='fw-normal'>Amount:</span>
                    <span className='fw-bold ms-4'>₹{redeemAmount}</span>
                </div>
                <Form >
                    {
                    type === "bdm" && isSharedYour1stInstallment && isSharedYour1stInstallment?.is_share_your_incentive?
                        <></>
                    :
                    type === "bdm" && isSharedYour1stInstallment && !isSharedYour1stInstallment?.is_share_your_incentive?
                        <></>

                    :cardType === "individual"?
                    <div className="wrapper mb-3">
                        <div className="lh-sm mb-2 mb-xl-3">Would you like to share your incentive with a team member who assisted you with this conversion as a supporting BDM?</div>
                        <div className="checks-wrapper">
                            <div class="form-check d-inline-flex align-items-center gap-2 me-5">
                                <input class="form-check-input fs-18" type="radio" name="shareIncentive" value="optionNo" id="shareIncentiveNo" checked={selectedOption === 'optionNo'} onChange={handleChange} />
                                <label class="form-check-label fs-4 fw-semibold pt-1" for="shareIncentiveNo">No</label>
                            </div>
                            <div class="form-check d-inline-flex align-items-center gap-2">
                                <input class="form-check-input fs-18" type="radio" name="shareIncentive" value="optionYes" id="shareIncentiveYes" checked={selectedOption === 'optionYes'} onChange={handleChange} />
                                <label class="form-check-label fs-4 fw-semibold pt-1" for="shareIncentiveYes">Yes</label>
                            </div>
                        </div>
                    </div>
                    : null}

                    {/* If Choosen No */}
                    {selectedOption === 'optionNo' && (
                        <div className='wrapper mb-3 mb-lg-4 pb-xl-2'>
                            <div className="fs-12 fw-medium lh-sm" style={{ color: '#bf16ff' }}>
                                By selecting "No," you are clearly declaring that you did not receive any help from anyone in this conversion.
                                Once you submit, others in the organisation will be able to see that you've stated you did not
                                receive any assistance for this conversion in any way.
                            </div>
                        </div>
                    )}

                    {/* If Choosen Yes */}
                    {selectedOption === 'optionYes' && (
                        <div className='wrapper mb-3 mb-lg-4'>
                            <div className="incentive-info-wrapper mb-3 mb-lg-4">
                                <div className="row align-items-center">
                                    <div className="col-12">
                                        <Form.Label className="text-primary text-opacity-75">Amount for team member (INR)</Form.Label>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="d-flex align-items-center gap-3">
                                            <Form.Group controlId="teamMemberAmount">
                                                <Form.Control type="text" className='border rounded-3 px-3 py-2' placeholder="Enter amount" autoComplete='off' required onChange={(e)=>{calculateSharedAmount(e.target.value)}} />
                                            </Form.Group>
                                            <div className="total-incentive fw-bold text-nowrap">Of ₹{redeemAmount}</div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mt-2 mt-md-0">
                                        <div className='fw-bold'>You will receive: ₹{redeemAmount - sharedAmount}</div>
                                    </div>
                                </div>
                                <div className="fs-12 fw-medium lh-sm mt-2" style={{ color: '#bf16ff' }}>
                                    It's always motivating to share incentives with someone who helped you in any way, as it keeps them encouraged.
                                    It's completely up to you how much you choose to give to the supporting BDM. However, once you submit,
                                    the supporting BDM will be able to see clearly how much you are receiving, how much they will get from you
                                    and the support points you've mentioned for support BDM for this conversion.
                                </div>
                            </div>

                            <Form.Group className="mb-3 mb-lg-4" controlId="teamMemberEmail">
                                <Form.Label className="text-primary text-opacity-75">VE Email ID of the Team Member</Form.Label>
                                <Form.Control type="email" className='border rounded-3 px-3 py-2' placeholder="Email ID" autoComplete='off' required onChange={(e) =>{setSharedEmail(e.target.value)}} />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label className="text-primary text-opacity-75 pb-1">Please let us know how the supported BDM assisted you during this conversion</Form.Label>
                                <div className="checks-wrapper">
                                {questions.map((question,index) => (
                                    <div class="form-check mb-2">
                                        <input class="form-check-input" type="checkbox" value="" id={"BDMAssistedHelp1"+index} checked={checkedItems.includes(question)}
              onChange={() => handleCheckboxChange(question)} />
                                        <label class="form-check-label fs-14" for={"BDMAssistedHelp1"+index}>{question}</label>
                                    </div>
                                    ))}
                                   
                                </div>
                            </Form.Group>
                        </div>
                    )}

                    <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 pt-2">
                        <Button variant='secondary' className='btn-custom px-5 py-2' onClick={close}>Cancel</Button>
                        <Button variant='success' className='btn-custom px-5 py-2' onClick={handleSubmitRedeem}>Redeem</Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

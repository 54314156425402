import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { callAxios, getTotalInstallments } from '../utils/utils';
import { environment } from '../environment';
import { new_servicetype, new_servicetype_mapping, nm_paymentstatus, nm_servicemonth, nm_servicestatus } from '../utils/codes';
import { toast } from 'react-toastify';
import { MfaModal } from './MfaModal';

export const ManagementApprovalModal = ({ show, close, invoiceId, opId,randNo, incentiveObject,type,currentItem,setShowManagementApprovalModal, opportunityDetailsdata,firstInstallmentData,userRole,setCrmData,crmRealData,isPLoading,setIsPLoading, user }) => {
    //   console.log(type, invoiceId,randNo)
    const [selectedOption, setSelectedOption] = useState('');
    const [ServiceHoursConfirmation, setServiceHoursConfirmation] = useState('');
    const [ServiceHoursInputs, setServiceHoursInputs] = useState('');
    const [BDMConfirmation, setBDMConfirmation] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const [isAuthConfigured, setisAuthConfigured] = useState(null)
    const [mfaApproved, setMfaApproved] = useState(false)
    const [randamNo, setRandomNo] = useState(null)
            // Management MFA modal
        const [showManagementMFAModal, setShowManagementMFAModal] = useState(false);
        const closeManagementMFAModal = () => setShowManagementMFAModal(false);
        const openManagementMFAModal = () => setShowManagementMFAModal(true);


    
    const [formData, setFormData] = useState({
        wallet_users_installments_id: '',
        transaction_id: '',
        wallet_id: '',
        type:'',
        installment:'',
        opportunityid:'',
        is_client_type_correct: false,
        are_billing_hours_completed: false,
        no_value: "",
        is_bdm_confirmed: false,
        tick: false,
        userRole:userRole
    });

    useEffect(() => {
        if (currentItem) {
            setFormData({
                wallet_users_installments_id: currentItem.id,
                transaction_id: currentItem.transaction_id,
                wallet_id: currentItem.wallet_id,
                type:currentItem.type,
                installment:currentItem.installment,
                opportunityid:currentItem.opportunityid,
                is_client_type_correct: firstInstallmentData[0]?firstInstallmentData[0].is_client_type_correct:false,
                are_billing_hours_completed: false,
                no_value: "",
                is_bdm_confirmed: firstInstallmentData[0]?firstInstallmentData[0].is_bdm_confirmed:false,
                tick: false,
                userRole:userRole
            });
        }
    }, [currentItem,firstInstallmentData[0]]);

    const mfaOpen = ()=>{
        setShowManagementMFAModal(true)
    }

    // const [isPLoading, setIsPLoading] = useState(false)

    // const [crmRealData, setCrmData] = useState({})

    // useEffect(()=>{
    //      console.log("in approval:::", opId, invoiceId,randNo)
    //     setIsPLoading(true)
    //     callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/management-approval-popup`,{opId:opId,invoiceId:invoiceId,type:type},"post").then((crmData)=>{
    //         if(crmData.status === 200){
                
    //         setCrmData(crmData.data)
    //         setIsPLoading(false)
    //         }
            
    //     })
        
    // },[randNo])
    // console.log(crmRealData)
    const handleChange = (event) => {
       
        setSelectedOption(event.target.value);
        if(event.target.value === "YesCorrect"){
            setFormData({ ...formData, is_client_type_correct: true });
        } else{
            setFormData({ ...formData, is_client_type_correct: false });
        }
    };

    const handleServiceHours = (event) => {
        setServiceHoursConfirmation(event.target.value);
        if(event.target.value === "YesBillingCompleted"){
            setFormData({ ...formData, are_billing_hours_completed: true });
        } else{
            setFormData({ ...formData, are_billing_hours_completed: false });
        }
    };

    const serviceHoursInputs = (event) => {
        setServiceHoursInputs(event.target.value);
        if(event.target.value === "partialHoursInfo1")
            setFormData({ ...formData, are_billing_hours_completed: false,no_value: "The resource forgot to submit the work updates, but the billing hours have been fully consumed. I have confirmed this with the Billing Manager, SDM, and FrontFace Resource. I am approving this incentive." });

        if(event.target.value === "partialHoursInfo2")
            setFormData({ ...formData, are_billing_hours_completed: false,no_value: "The resource took an approved monthly leave, and only the work for that day was not submitted in the system. However, the rest of the billing hours were fully consumed. I have confirmed this with the Billing Manager, SDM, and FrontFace Resource. I am approving this incentive."});

        if(event.target.value === "partialHoursInfo3")
            setFormData({ ...formData, are_billing_hours_completed: false,no_value: "The resource has taken more than the allowed leaves, and updates for these days were not submitted in the system. However, the client was informed about the resource's leave through an official email. I have confirmed this with the Billing Manager, SDM, FrontFace Resource, and HR. I am approving this incentive." });

        // if(event.target.value === "partialHoursInfo4")
        //     setFormData({ ...formData, are_billing_hours_completed: false,no_value: "Resources worked, but time has passed. After confirming with the billing Manager, SDM, and FrontFace Resource, I approve the incentive."});

        if(event.target.value === "partialHoursInfo5")
            setFormData({ ...formData, are_billing_hours_completed: false,no_value: "Accounts to update the Service Status to Paused or Cancelled based on the situation. Once updated, this card will be removed from Management Approval." });
    };

    useEffect(()=>{
        if(crmRealData?.billingDetails[0]?.consumedHours >= crmRealData?.billingDetails[0]?.assignedHours){
            setServiceHoursConfirmation("YesBillingCompleted")
        } else{
            setServiceHoursConfirmation("PartiallyCompleted")
        }
    },[crmRealData])
    
    const handleBDMConfirmation = (event) => {
        setBDMConfirmation(event.target.value);
        if(event.target.value === "YesConfirmed"){
            setFormData({ ...formData, is_bdm_confirmed: true });
        } else{
            setFormData({ ...formData, is_bdm_confirmed: false });
        }
    };

    // Function to handle checkbox change event
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        if(event.target.checked){
            setFormData({ ...formData, tick: true });
        } else{
            setFormData({ ...formData, tick: false });
        }
    };

    useEffect(()=>{
        if(mfaApproved){
            handleApprove();
        }
    },[mfaApproved])


    // console.log(formData)
    const handleApprove = async() =>{
        setIsPLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/save-approval`,{formData},"post").then((res)=>{
            console.log(res)
            setIsPLoading(false)
            if(res.status ===201){
                
                setMfaApproved(false)
                setFormData({ ...formData,
                    wallet_users_installments_id: '',
                    is_client_type_correct: false,
                    are_billing_hours_completed: false,
                    no_value: "",
                    tick: false
                })
                setCrmData([])
                setShowManagementApprovalModal(false);
               
            // toast.success("Payment Approved!!", {
            // position: "top-right",
            // autoClose: 2000,
            // hideProgressBar: false,
            // closeOnClick: true,
            // pauseOnHover: true,
            // draggable: true,
            // progress: undefined,
            // theme: "light",
            // });
            opportunityDetailsdata("paymentApproved")
            } else{
                // toast.error("something went wrong!!", {
                //     position: "top-right",
                //     autoClose: 2000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //     theme: "light",
                //     });
                setMfaApproved(false)
                setCrmData([])
                setShowManagementApprovalModal(false);
                    opportunityDetailsdata("permissionError")
            }
        })
    }
    
        if( type === "sdm" ){
            return (
                <>
                 <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} className='p-0' dialogClassName="DisputeModal" contentClassName='rounded overflow-hidden'>
                   
                    <Modal.Body className="wallet-info-section text-black px-3 px-md-4 px-lg-5 py-4">
                        <>
                        {isPLoading?<div className="loader-overlay" id="loader" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '100vh',
                        background: 'rgba(0, 0, 0, 0.8)',
                        backdropFilter: 'blur(2px)'
                    }}>
                        <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />
        
                        <div className="text-center w-50">
                            <p>Please wait...</p>
                        </div>
        
                    </div>
                    :null}
                    
                        <div className="fs-3 fw-bold lh-sm mb-2 mb-xl-3">Management Approval for Billing Incentive</div>
                        <div className="info fw-medium lh-sm mb-4" style={{ color: '#FF191A' }}>
                            *Please review the billing details and provide your input to confirm or correct the information. Your approval is required to process the
                            incentive associated with this billing. Use the fields below to verify the details and submit your confirmation.
                        </div>
                        <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                        {crmRealData && crmRealData?.billingDetails?
                        <div className="billing-info">
                            <div className="heading-title fs-6 fw-semibold mb-3">Client/Billing Information</div>
                            <div className="row row-cols-2 g-3">
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Billing ID</div>
                                    <div className="label-value">
                                       
                                         {crmRealData?.billingDetails[0]?.billingId && crmRealData?.billingDetails[0]?.billingId.length > 0 ? crmRealData?.billingDetails[0]?.billingId.map((cl) => (
                                                                                                <p className="mb-0">{cl}</p>
                                                                                            )) : crmRealData?.billingDetails[0]?.nm_name}
                                        </div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Billing Manager</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.manager}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Service Month</div>
                                    <div className="label-value">{nm_servicemonth[crmRealData?.billingDetails[0]?.nm_servicemonth]}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Service Type</div>
                                    <div className="label-value">{new_servicetype[crmRealData?.service?.new_servicetype]}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Zoho Client ID</div>
                                    <div className="label-value text-uppercase">{crmRealData?.billingDetails[0]?.nm_zohoclientid}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Service in CRM</div>
                                    <div className="label-value text-uppercase">{crmRealData?.service?.new_name}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Hours Based on Payment Received</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.totalHours}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Working Hours in October 2024</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.crmHours}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Payment Status</div>
                                    <div className="label-value">{nm_paymentstatus[crmRealData?.billingDetails[0]?.nm_paymentstatus]}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Zoho Invoice ID</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.nm_zohoinvoiceid}</div>
                                </div>
                            </div>
                        </div>
                        :null}
                        
        
                        
                       
                            <>
                             
        
        
                               
                                    <>
                                        <div className="bdm-confirmation">
                                            <div className="info-box mb-4">
                                                <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                                                <div className="heading-title fs-6 fw-semibold mb-3">SDM Confirmation</div>
                                                <div className="info-box">
                                                    <div className="label-name text-primary fs-14">SDM in Opportunity</div>
                                                    <div className="label-value text-capitalize">{crmRealData?.BDMName}</div>
                                                </div>
                                            </div>
                                           
                                        </div>
        
                                       
        
                                        
                                    </>
                                
                            </>
                        
        
                        <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 pt-2">
                            <div className="">
                                                <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                                                <div class="form-check checks-wrapper mb-3">
                                                    <input class="form-check-input" type="checkbox" id="checkCondition" checked={isChecked} onChange={handleCheckboxChange} />
                                                    <label class="form-check-label fs-14" for="checkCondition">
                                                        I have verified all details throughly, including CRM inputs, emails, and Teams Conversations.
                                                        All inputs provided above are correct.
                                                    </label>
                                                </div>
                                            </div>
                            <Button variant='secondary' className='btn-custom fs-18 px-5 py-2' onClick={close}>Cancel</Button>
                           {isChecked?
                            <Button variant='success' className='btn-custom fs-18 px-5 py-2' disabled={!isChecked} onClick={mfaOpen}>Approve</Button>
                            : null}
                            
                        </div>
                        </>
                        
                    </Modal.Body>
    
                </Modal>
                {/* Management MFA Modal */}
                <MfaModal show={showManagementMFAModal} close={closeManagementMFAModal}  setShowManagementMFAModal={setShowManagementMFAModal} email={user?.email} user={user} isAuthConfigured={isAuthConfigured} setisAuthConfigured={setisAuthConfigured} setMfaApproved={setMfaApproved} setRandomNo={setRandomNo} mfaApproved={mfaApproved}/>
                </>
               
            )
        } else{
           
                 return (
                    <>
                    <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} className='p-0' dialogClassName="DisputeModal" contentClassName='rounded overflow-hidden'>
                    {Object.entries(crmRealData).length >0 && firstInstallmentData && firstInstallmentData.length > 0?
                    <Modal.Body className="wallet-info-section text-black px-3 px-md-4 px-lg-5 py-4">
                        <>
                        {isPLoading?<div className="loader-overlay" id="loader" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '100vh',
                        background: 'rgba(0, 0, 0, 0.8)',
                        backdropFilter: 'blur(2px)'
                    }}>
                        <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />
        
                        <div className="text-center w-50">
                            <p>Please wait...</p>
                        </div>
        
                    </div>
                    :null}
                    
                        <div className="fs-3 fw-bold lh-sm mb-2 mb-xl-3">Management Approval for Billing Incentive</div>
                        <div className="info fw-medium lh-sm mb-4" style={{ color: '#FF191A' }}>
                            *Please review the billing details and provide your input to confirm or correct the information. Your approval is required to process the
                            incentive associated with this billing. Use the fields below to verify the details and submit your confirmation.
                        </div>
                        <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                        {crmRealData && crmRealData?.billingDetails?
                        <div className="billing-info">
                            <div className="heading-title fs-6 fw-semibold mb-3">Client/Billing Information</div>
                            <div className="row row-cols-2 g-3">
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Billing ID</div>
                                    <div className="label-value">
                                       
                                         {crmRealData?.billingDetails[0]?.billingId && crmRealData?.billingDetails[0]?.billingId.length > 0 ? crmRealData?.billingDetails[0]?.billingId.map((cl) => (
                                                                                                <p className="mb-0">{cl}</p>
                                                                                            )) : crmRealData?.billingDetails[0]?.nm_name}
                                        </div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Billing Manager</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.manager}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Service Month</div>
                                    <div className="label-value">{nm_servicemonth[crmRealData?.billingDetails[0]?.nm_servicemonth]}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Service Type</div>
                                    <div className="label-value">{new_servicetype[crmRealData?.service?.new_servicetype]}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Zoho Client ID</div>
                                    <div className="label-value text-uppercase">{crmRealData?.billingDetails[0]?.nm_zohoclientid}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Service in CRM</div>
                                    <div className="label-value text-uppercase">{crmRealData?.service?.new_name}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Hours Based on Payment Received</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.totalHours}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Working Hours in October 2024</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.crmHours}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Payment Status</div>
                                    <div className="label-value">{nm_paymentstatus[crmRealData?.billingDetails[0]?.nm_paymentstatus]}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Zoho Invoice ID</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.nm_zohoinvoiceid}</div>
                                </div>
                            </div>
                        </div>
                        :null}
                        <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
        
                        <div className="client-confirmation-info mb-3">
                            {/* <div className="heading-title fs-6 fw-semibold mb-3">Client Type Confirmation</div> */}
                            <div className="info-box text-capitalize mb-3">
                                <div className="label-name text-primary fs-14 mb-2">Client Type</div>
                                <div className="label-value">{incentiveObject?.scenario} ({incentiveObject?.client_type})</div>
                                <div className="note small mt-1">Policy: This client type qualifies for a {incentiveObject?.total_bonus} incentive, 
                                    {/* provided in {getTotalInstallments(incentiveObject)} installments after {getTotalInstallments(incentiveObject)} full client payments. */}
                                    </div>
                            </div>
                          
        
                           
                        </div>
        
                        {firstInstallmentData[0]?.is_client_type_correct && (
                            <>
                                <div className="service-hours">
                                    <div className="info-box mb-4">
                                        <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                                        <div className="heading-title fs-6 fw-semibold mb-3">Service Hours Confirmation</div>
                                        <div className="row row-cols-2 g-3">
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Service Status</div>
                                                <div className="label-value">{nm_servicestatus[crmRealData?.billingDetails[0]?.nm_servicestatus]}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Service Delivery Manager</div>
                                                <div className="label-value">{crmRealData?.billingDetails[0]?.lead}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Front Face Resource</div>
                                                <div className="label-value">{crmRealData?.billingDetails[0]?.frontFace}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Total Assigned Hours</div>
                                                <div className="label-value">{crmRealData?.billingDetails[0]?.assignedHours}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Total Consumed Hours</div>
                                                <div className="label-value text-uppercase">{crmRealData?.billingDetails[0]?.consumedHours}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-box mb-4">
                                        <div className="label-name text-primary fs-14 mb-2">Your Input</div>
                                        <div className="radio-wrapper">
                                            <div class="form-check mb-2">
                                                <input class="form-check-input" type="radio" name="billingStatus" value="YesBillingCompleted" id="YesBillingCompleted" checked={ServiceHoursConfirmation === 'YesBillingCompleted'} onChange={handleServiceHours} />
                                                <label class="form-check-label" for="YesBillingCompleted">Yes, all billing hours were completed for this service.</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="billingStatus" value="PartiallyCompleted" id="PartiallyCompleted" checked={ServiceHoursConfirmation === 'PartiallyCompleted'} onChange={handleServiceHours} />
                                                <label class="form-check-label" for="PartiallyCompleted">No, only partial hours were completed, and resources didn't fill the complete hour details.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                {ServiceHoursConfirmation === 'PartiallyCompleted' && (
                                    <>
                                        <div className="info-box mb-4">
                                            <div className="label-name text-primary fs-14 mb-2">Your Input</div>
                                            <div className="radio-wrapper">
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo1" id="partialHoursInfo1" checked={ServiceHoursInputs === 'partialHoursInfo1'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo1">
                                                        The resource forgot to submit the work updates, but the billing hours have been fully consumed.
                                                        I have confirmed this with the Billing Manager, SDM, and FrontFace Resource. I am approving this incentive.
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo2" id="partialHoursInfo2" checked={ServiceHoursInputs === 'partialHoursInfo2'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo2">
                                                        The resource took an approved monthly leave, and only the work for that day was not submitted in the system.
                                                        However, the rest of the billing hours were fully consumed. I have confirmed this with the Billing Manager,
                                                        SDM, and FrontFace Resource. I am approving this incentive.
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo3" id="partialHoursInfo3" checked={ServiceHoursInputs === 'partialHoursInfo3'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo3">
                                                        The resource has taken more than the allowed leaves, and updates for these days were not submitted in the system.
                                                        However, the client was informed about the resource's leave through an official email.
                                                        I have confirmed this with the Billing Manager, SDM, FrontFace Resource, and HR. I am approving this incentive.
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo4" id="partialHoursInfo4" checked={ServiceHoursInputs === 'partialHoursInfo4'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo4">
                                                        Resources worked, but time has passed. After confirming with the billing Manager, SDM, and FrontFace Resource, I approve the incentive.
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo5" id="partialHoursInfo5" checked={ServiceHoursInputs === 'partialHoursInfo5'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo5">
                                                        Service was paused midway; incomplete service was provided, so no incentive is applicable.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
        
                                        {ServiceHoursInputs === 'partialHoursInfo5' && (
                                            <div className="mb-4">
                                                <div className="heading-title fs-6 fw-semibold mb-3">Please connect with Accounts to update the Service Status to "Paused" or "Cancelled" based on the situation. Once updated, this card will be removed from Management Approval.</div>
                                            </div>
                                        )}
                                    </>
                                )}
        
                                {(ServiceHoursConfirmation === 'YesBillingCompleted' || ServiceHoursInputs === 'partialHoursInfo1' || ServiceHoursInputs === 'partialHoursInfo2' || ServiceHoursInputs === 'partialHoursInfo3' || ServiceHoursInputs === 'partialHoursInfo4') && (
                                    <>
                                        <div className="bdm-confirmation">
                                            <div className="info-box mb-4">
                                                <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                                                {/* <div className="heading-title fs-6 fw-semibold mb-3">BDM Confirmation</div> */}
                                                <div className="info-box">
                                                    <div className="label-name text-primary fs-14">BDM in Opportunity</div>
                                                    <div className="label-value text-capitalize">{crmRealData?.BDMName}</div>
                                                </div>
                                            </div>
                                           
                                        </div>
        
                                      
                                            <div className="">
                                                <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                                                <div class="form-check checks-wrapper mb-3">
                                                    <input class="form-check-input" type="checkbox" id="checkCondition" checked={isChecked} onChange={handleCheckboxChange} />
                                                    <label class="form-check-label fs-14" for="checkCondition">
                                                        I have verified all details throughly, including CRM inputs, emails, and Teams Conversations.
                                                        All inputs provided above are correct.
                                                    </label>
                                                </div>
                                            </div>
                                        
        
                                        
                                    </>
                                )}
                            </>
                        )}
        
                        <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 pt-2">
                            {/* {(BDMConfirmation === 'NotConfirmed' || selectedOption === 'NoIncorrect') && (
                                <Button variant='secondary' className='btn-custom fs-18 px-5 py-2' onClick={close}>Close Form</Button>
                            )} */}
                            <Button variant='secondary' className='btn-custom fs-18 px-5 py-2' onClick={close}>Cancel</Button>
                            {isChecked && (
                            <Button variant='success' className='btn-custom fs-18 px-5 py-2' disabled={!isChecked} onClick={mfaOpen}>Approve</Button>
                            )}
                        </div>
                        </>
                        
                    </Modal.Body>
                    :
                    <Modal.Body className="wallet-info-section text-black px-3 px-md-4 px-lg-5 py-4">
                        <>
                        {isPLoading?<div className="loader-overlay" id="loader" style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textAlign: 'center',
                        height: '100vh',
                        background: 'rgba(0, 0, 0, 0.8)',
                        backdropFilter: 'blur(2px)'
                    }}>
                        <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />
        
                        <div className="text-center w-50">
                            <p>Please wait...</p>
                        </div>
        
                    </div>
                    :null}
                    
                        <div className="fs-3 fw-bold lh-sm mb-2 mb-xl-3">Management Approval for Billing Incentive</div>
                        <div className="info fw-medium lh-sm mb-4" style={{ color: '#FF191A' }}>
                            *Please review the billing details and provide your input to confirm or correct the information. Your approval is required to process the
                            incentive associated with this billing. Use the fields below to verify the details and submit your confirmation.
                        </div>
                        <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                        {crmRealData && crmRealData?.billingDetails?
                        <div className="billing-info">
                            <div className="heading-title fs-6 fw-semibold mb-3">Client/Billing Information</div>
                            <div className="row row-cols-2 g-3">
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Billing ID</div>
                                    <div className="label-value">
                                       
                                         {crmRealData?.billingDetails[0]?.billingId && crmRealData?.billingDetails[0]?.billingId.length > 0 ? crmRealData?.billingDetails[0]?.billingId.map((cl) => (
                                                                                                <p className="mb-0">{cl}</p>
                                                                                            )) : crmRealData?.billingDetails[0]?.nm_name}
                                        </div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Billing Manager</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.manager}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Service Month</div>
                                    <div className="label-value">{nm_servicemonth[crmRealData?.billingDetails[0]?.nm_servicemonth]}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Service Type</div>
                                    <div className="label-value">{new_servicetype[crmRealData?.service?.new_servicetype]}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Zoho Client ID</div>
                                    <div className="label-value text-uppercase">{crmRealData?.billingDetails[0]?.nm_zohoclientid}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Service in CRM</div>
                                    <div className="label-value text-uppercase">{crmRealData?.service?.new_name}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Hours Based on Payment Received</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.totalHours}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Working Hours in October 2024</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.crmHours}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Payment Status</div>
                                    <div className="label-value">{nm_paymentstatus[crmRealData?.billingDetails[0]?.nm_paymentstatus]}</div>
                                </div>
                                <div className="info-box text-capitalize">
                                    <div className="label-name text-primary fs-14">Zoho Invoice ID</div>
                                    <div className="label-value">{crmRealData?.billingDetails[0]?.nm_zohoinvoiceid}</div>
                                </div>
                            </div>
                        </div>
                        :null}
                        { crmRealData?.billingDetails[0].nm_paymentstatus === 124950000 && crmRealData?.billingDetails[0].nm_servicestatus === 124950000 && crmRealData?.billingDetails[0].totalHours >= crmRealData?.billingDetails[0].crmHours?
                        
                        <>
                        <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
        
                        <div className="client-confirmation-info mb-3">
                            <div className="heading-title fs-6 fw-semibold mb-3">Client Type Confirmation</div>
                            <div className="info-box text-capitalize mb-3">
                                <div className="label-name text-primary fs-14 mb-2">Client Type</div>
                                <div className="label-value">{incentiveObject?.scenario} ({incentiveObject?.client_type})</div>
                                <div className="note small mt-1">Policy: This client type qualifies for a {incentiveObject?.total_bonus} incentive, 
                                    {/* provided in {getTotalInstallments(incentiveObject)} installments after {getTotalInstallments(incentiveObject)} full client payments. */}
                                    </div>
                            </div>
                            <div className="info-box mb-3">
                                <div className="label-name text-primary fs-14 mb-2">Your Input</div>
                                <div className="radio-wrapper">
                                    <div class="form-check mb-2">
                                        <input class="form-check-input" type="radio" name="yourInputStatus" value="YesCorrect" id="YesCorrect" checked={selectedOption === 'YesCorrect'} onChange={handleChange} />
                                        <label class="form-check-label lh-sm" for="YesCorrect">Yes, this client type is correct.</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="yourInputStatus" value="NoIncorrect" id="NoIncorrect" checked={selectedOption === 'NoIncorrect'} onChange={handleChange} />
                                        <label class="form-check-label lh-sm" for="NoIncorrect">No, this client type is incorrect.</label>
                                    </div>
                                </div>
                            </div>
        
                            {selectedOption === 'NoIncorrect' && (
                                <div className="mb-3">
                                    <div className="heading-title fs-6 fw-semibold mb-3 pt-2">Please connect with the Billing Department to correct the client type before proceeding.</div>
                                </div>
                            )}
                        </div>
        
                        {selectedOption === 'YesCorrect' && (
                            <>
                                <div className="service-hours">
                                    <div className="info-box mb-4">
                                        <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                                        <div className="heading-title fs-6 fw-semibold mb-3">Service Hours Confirmation</div>
                                        <div className="row row-cols-2 g-3">
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Service Status</div>
                                                <div className="label-value">{nm_servicestatus[crmRealData?.billingDetails[0]?.nm_servicestatus]}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Service Delivery Manager</div>
                                                <div className="label-value">{crmRealData?.billingDetails[0]?.lead}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Front Face Resource</div>
                                                <div className="label-value">{crmRealData?.billingDetails[0]?.frontFace}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Total Assigned Hours</div>
                                                <div className="label-value">{crmRealData?.billingDetails[0]?.assignedHours}</div>
                                            </div>
                                            <div className="info-box text-capitalize">
                                                <div className="label-name text-primary fs-14">Total Consumed Hours</div>
                                                <div className="label-value text-uppercase">{crmRealData?.billingDetails[0]?.consumedHours}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="info-box mb-4">
                                        <div className="label-name text-primary fs-14 mb-2">Your Input</div>
                                        <div className="radio-wrapper">
                                            <div class="form-check mb-2">
                                                <input class="form-check-input" type="radio" name="billingStatus" value="YesBillingCompleted" id="YesBillingCompleted" checked={ServiceHoursConfirmation === 'YesBillingCompleted'} onChange={handleServiceHours} />
                                                <label class="form-check-label" for="YesBillingCompleted">Yes, all billing hours were completed for this service.</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="billingStatus" value="PartiallyCompleted" id="PartiallyCompleted" checked={ServiceHoursConfirmation === 'PartiallyCompleted'} onChange={handleServiceHours} />
                                                <label class="form-check-label" for="PartiallyCompleted">No, only partial hours were completed, and resources didn't fill the complete hour details.</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                {ServiceHoursConfirmation === 'PartiallyCompleted' && (
                                    <>
                                        <div className="info-box mb-4">
                                            <div className="label-name text-primary fs-14 mb-2">Your Input</div>
                                            <div className="radio-wrapper">
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo1" id="partialHoursInfo1" checked={ServiceHoursInputs === 'partialHoursInfo1'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo1">
                                                        The resource forgot to submit the work updates, but the billing hours have been fully consumed.
                                                        I have confirmed this with the Billing Manager, SDM, and FrontFace Resource. I am approving this incentive.
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo2" id="partialHoursInfo2" checked={ServiceHoursInputs === 'partialHoursInfo2'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo2">
                                                        The resource took an approved monthly leave, and only the work for that day was not submitted in the system.
                                                        However, the rest of the billing hours were fully consumed. I have confirmed this with the Billing Manager,
                                                        SDM, and FrontFace Resource. I am approving this incentive.
                                                    </label>
                                                </div>
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo3" id="partialHoursInfo3" checked={ServiceHoursInputs === 'partialHoursInfo3'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo3">
                                                        The resource has taken more than the allowed leaves, and updates for these days were not submitted in the system.
                                                        However, the client was informed about the resource's leave through an official email.
                                                        I have confirmed this with the Billing Manager, SDM, FrontFace Resource, and HR. I am approving this incentive.
                                                    </label>
                                                </div>
                                                {/* <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo4" id="partialHoursInfo4" checked={ServiceHoursInputs === 'partialHoursInfo4'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo4">
                                                        Resources worked, but time has passed. After confirming with the billing Manager, SDM, and FrontFace Resource, I approve the incentive.
                                                    </label>
                                                </div> */}
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name="partialHoursInfo" value="partialHoursInfo5" id="partialHoursInfo5" checked={ServiceHoursInputs === 'partialHoursInfo5'} onChange={serviceHoursInputs} />
                                                    <label class="form-check-label" for="partialHoursInfo5">
                                                        Service was paused midway; incomplete service was provided, so no incentive is applicable.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
        
                                        {ServiceHoursInputs === 'partialHoursInfo5' && (
                                            <div className="mb-4">
                                                <div className="heading-title fs-6 fw-semibold mb-3">Please connect with Accounts to update the Service Status to "Paused" or "Cancelled" based on the situation. Once updated, this card will be removed from Management Approval.</div>
                                            </div>
                                        )}
                                    </>
                                )}
        
                                {(ServiceHoursConfirmation === 'YesBillingCompleted' || ServiceHoursInputs === 'partialHoursInfo1' || ServiceHoursInputs === 'partialHoursInfo2' || ServiceHoursInputs === 'partialHoursInfo3' || ServiceHoursInputs === 'partialHoursInfo4') && (
                                    <>
                                        <div className="bdm-confirmation">
                                            <div className="info-box mb-4">
                                                <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                                                <div className="heading-title fs-6 fw-semibold mb-3">BDM Confirmation</div>
                                                <div className="info-box">
                                                    <div className="label-name text-primary fs-14">BDM in Opportunity</div>
                                                    <div className="label-value text-capitalize">{crmRealData?.BDMName}</div>
                                                </div>
                                            </div>
                                            <div className="info-box mb-4">
                                                <div className="label-name text-primary fs-14 mb-2">Your Input</div>
                                                <div className="radio-wrapper">
                                                    <div class="form-check mb-2">
                                                        <input class="form-check-input" type="radio" name="BDMStatus" value="YesConfirmed" id="YesConfirmed" checked={BDMConfirmation === 'YesConfirmed'} onChange={handleBDMConfirmation} />
                                                        <label class="form-check-label" for="YesConfirmed">Yes, I have confirmed that <span className='text-capitalize'>{crmRealData?.BDMName}</span> is the correct BDM for this opportunity.</label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="radio" name="BDMStatus" value="NotConfirmed" id="NotConfirmed" checked={BDMConfirmation === 'NotConfirmed'} onChange={handleBDMConfirmation} />
                                                        <label class="form-check-label" for="NotConfirmed">No, <span className='text-capitalize'>{crmRealData?.BDMName}</span> is not correct.</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
        
                                        {BDMConfirmation === 'YesConfirmed' && (
                                            <div className="">
                                                <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                                                <div class="form-check checks-wrapper mb-3">
                                                    <input class="form-check-input" type="checkbox" id="checkCondition" checked={isChecked} onChange={handleCheckboxChange} />
                                                    <label class="form-check-label fs-14" for="checkCondition">
                                                        I have verified all details throughly, including CRM inputs, emails, and Teams Conversations.
                                                        All inputs provided above are correct.
                                                    </label>
                                                </div>
                                            </div>
                                        )}
        
                                        {BDMConfirmation === 'NotConfirmed' && (
                                            <div className="mb-4">
                                                <div className="heading-title fs-6 fw-semibold mb-3">Please update the opportunity details with the correct BDM before proceeding.</div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </>
                        )}
    
    <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 pt-2">
                          
                          <Button variant='secondary' className='btn-custom fs-18 px-5 py-2' onClick={close}>Cancel</Button>
                          {BDMConfirmation === 'YesConfirmed' && (
                          <Button variant='success' className='btn-custom fs-18 px-5 py-2' disabled={!isChecked} onClick={mfaOpen}>Approve</Button>
                          )}
                      </div>
                        </>
    
                        :
                        <>
                         <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                        <div className="mb-4">
                                                <div className="heading-title fs-6 fw-semibold mb-3">
                                                {crmRealData?.billingDetails[0].nm_paymentstatus !== 124950000?

                                                <div className=''><b>Latest Update:</b> The payment status for this billing has just been changed to <span className="text-danger">{nm_paymentstatus[crmRealData?.billingDetails[0].nm_paymentstatus]}</span>. As a result, it is no longer eligible for management inspection and is being reverted to its previous stage.</div>
                                                    
                                                   
                                                :crmRealData?.billingDetails[0].nm_servicestatus !== 124950000?
                                                <div className=''><b>Latest Update:</b> The service status for this billing has just been changed to <span className="text-danger">{nm_servicestatus[crmRealData?.billingDetails[0].nm_servicestatus]}</span>. As a result, it is no longer eligible for management inspection and is being reverted to its previous stage.</div>
                                               
                                                :crmRealData?.billingDetails[0].totalHours < crmRealData?.billingDetails[0].crmHours?
                                                `Service hours are shorter than expected in this billing.`
                                                :null}
                                                </div>
                                            </div>
                                            <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 pt-2">
                          
                          <Button variant='secondary' className='btn-custom fs-18 px-5 py-2' onClick={close}>Cancel</Button>
                         
                      </div>
                        </>
    
        }
                        </>
                        
                    </Modal.Body>
    }
                </Modal>
                {/* Management MFA Modal */}
                            <MfaModal show={showManagementMFAModal} close={closeManagementMFAModal}  setShowManagementMFAModal={setShowManagementMFAModal} email={user?.email} user={user}isAuthConfigured={isAuthConfigured} setisAuthConfigured={setisAuthConfigured} setMfaApproved={setMfaApproved} setRandomNo={setRandomNo} mfaApproved={mfaApproved}/>
                    </>
                
            )
            
           
        }
    
    
    
   
}

import React from "react";
import { convertReadable } from "../../utils/utils";

export const Duplicaterequest = ({ installmentData, DateFormatefunc,duplicateRedeem }) => {
    return (
        <>
            <div className="col-md-12 col-xl-4">
                <div className="details-card h-100 rounded-3 p-3" style={{ backgroundColor: 'var(--custom-bg)' }}>
                    <div className="mb-3">
                        <div class="fw-semibold text-warning lh-sm mb-1">Duplicate Incentive Redeem Requests for this opportunity</div>
                        <div class="text-white lh-sm">This section highlights cases where multiple BDM's have requested incentives for the same opportunity. It ensures transparency and helps manage duplicate claims efficiently.</div>
                    </div>
                    {duplicateRedeem && duplicateRedeem?.length>0 ?
                        <div className="card-wrapper d-flex flex-column gap-2">
                            {duplicateRedeem?.map((item, index) => (
                                <div className={`info-card rounded-3 p-3 border ${item?.is_bdm_mismatched ? 'border-danger' : 'border-success'}`}>
                                    <div className="d-flex gap-2">
                                        <div className="user-img mt-1">
                                            <img src={require('../../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                                        </div>
                                        <div className="user-info text-truncate">
                                            <div className="user-name text-white fw-semibold text-truncate text-capitalize">{item?.wallet?.name}</div>
                                            {/* <div className="user-empCode text-white text-truncate text-capitalize">Web Designer | TK10839</div> */}
                                            <div className="opportunity-added fs-14 text-warning text-normal text-capitalize">Opportunity Added to the wallet : <span className="text-white ms-1">{convertReadable(item?.createdAt)}</span></div>
                                            {/* <div className="fs-12" style={{ color: '#03ff85', whiteSpace: 'normal' }}><i class="bi bi-patch-check-fill"></i> Matching with <span className="text-capitalize">BDM</span> in Opportunity</div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* <div className="info-card active rounded-3 p-3">
                                <div className="d-flex gap-2">
                                    <div className="user-img mt-1">
                                        <img src={require('../../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                                    </div>
                                    <div className="user-info text-truncate">
                                        <div className="user-name text-white fw-semibold text-truncate text-capitalize">Seema</div>
                                        <div className="user-empCode text-white text-truncate text-capitalize">Web Designer | TK10839</div>
                                        <div className="opportunity-added fs-14 text-warning text-capitalize">Opportunity Added to the wallet : <span className="text-white ms-1">08-08-2024</span></div>
                                    </div>
                                </div>
                            </div> */}
                        </div> : "" }
                </div>
            </div>
        </>
    )
}
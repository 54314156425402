import React, { useEffect, useState } from 'react';
import { Modal, Form, Button, Spinner } from 'react-bootstrap';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { toast } from 'react-toastify';
import { QRCodeCanvas } from 'qrcode.react';

export const MfaModal = ({ show, close, email, user, isAuthConfigured, setisAuthConfigured, setMfaApproved, setShowManagementMFAModal, setRandomNo, mfaApproved }) => {
    const qrCodeSize = 200;
    // console.log(user)
    const [secret, setSecret] = useState(null);
    const [visible, setVisible] = useState(false);
    const [otp, setOtp] = useState('');
    const [statusMfa, setStatusMfa] = useState(false);
    const [randN, setRandN] = useState(null)
    const [errorMsg, setErrorMessage] = useState({});
    // const [isAuthConfigured, setisAuthConfigured] = useState(null)
    const [isloading, setisloading] = useState(false)



    const fetchSecret = async () => {
        try {
            //   const email = 'anandbharti.ve@gmail.com';
            callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/mfa/mfa-secret`, { email }, "post").then((response) => {
                setisloading(false)
                setisAuthConfigured(response.data.is_authenticator_setup_done)
                setSecret(response.data);
            })

        } catch (error) {
            console.error('Error fetching secret:', error);
        }
    };

    const verifyOtp = async () => {
        try {
            if (otp === "") {
                setVisible(true)
                setErrorMessage({
                    code: 400,
                    message: "Scan the QR Code to get the 6 digit code and Enter OTP"
                })
                return false;
            }
            setisloading(true)
            const secretCode = secret.secret
            const response = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/mfa/mfa-verify`, { otp, secret: secretCode, email: email }, "post");
            setRandN(getRandomInteger(1, 10))
            setVisible(true)
            if (response.data.valid) {


                setisloading(false)

                setErrorMessage({
                    code: 200,
                    message: "OTP validated successfully"
                })
                setStatusMfa(true);
                setMfaApproved(true)
                setShowManagementMFAModal(false)
            } else {

                setisloading(false)
                setMfaApproved(false)
                setStatusMfa(false);
                setErrorMessage({
                    code: 400,
                    message: "Entered invalid OTP"
                })
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
        }
    };

    useEffect(() => {
        fetchSecret();
    }, [user])

    // vasheem code

    // Authentication Modal with OTP validation
    const [showAuthModal, setShowAuthModal] = useState(false);
    const openAuthenticationModal = () => {
        setShowAuthModal(true)
    };


    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault()
    };

    const closeAuthenticationModal = () => {
        setShowManagementMFAModal(false)
    }

    function getRandomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    // Example usage:


    useEffect(() => {
        // Set a timeout to hide the message after 2 seconds
        const timer = setTimeout(() => {
            setVisible(false);
            setErrorMessage(null)
        }, 2000);

        // Cleanup the timer when the component unmounts or errorMsg changes
        return () => clearTimeout(timer);
    }, [errorMsg, randN]);

    // console.log(statusMfa)
    console.log(errorMsg, visible, randN)
    return (

        <Modal show={show} centered onHide={close} size='lg' scrollable backdrop="static" keyboard={false} dialogClassName="addNewMeetingModal mw-auto" contentClassName='rounded overflow-hidden' className='background-blur'>
            <Modal.Body className="text-dark px-lg-4 px-xl-5 py-4" >
                <div className="custom-wrapper text-center my-2 pb-1">
                    {!isAuthConfigured?
                    <>
                     <div className="fs-5 fw-bold lh-sm mb-3">Authentication Required</div>
                    <div className="fs-18 subtitle mb-3">As an Incentive Approval Manager, setting up Multi-Factor Authentication (MFA) is essential. This is a one-time process where you'll need to connect your physical device by installing either the <span className="fw-semibold mx-1">Microsoft Authenticator</span> or <span className="fw-semibold mx-1">Google Authenticator</span>  app. Simply scan the QR code below using the app to set up your MFA.
                    Going forward, whenever you approve an incentive, you'll need to verify your identity by entering a 6-digit code generated by the app.</div>
                    </>
                    :
                    <>
                      <div className="fs-5 fw-bold lh-sm mb-3">To Approve This Incentive, Your Verification Is Required with MFA</div>
                    <div className="fs-18 subtitle mb-3">Enter the 6-digit code from your authenticator app to complete the approval process.</div>
                    </>
                  
                    }
                   
                    {secret && !isAuthConfigured ?
                        <>
                            <div className="qrcode-wrapper mb-3" style={{ position: 'relative', display: 'inline-block' }}>

                                {/* QR Code */}
                                <QRCodeCanvas
                                    value={secret.qrCodeUrl}
                                    size={qrCodeSize}
                                    style={{ border: '1px solid #000' }}
                                />
                                {/* Logo */}
                                <img
                                    src={"https://nm-live-media.s3.us-east-1.amazonaws.com/VE+Logo.png"}
                                    alt="Virtual Employee"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        height: '50px', // Adjust size as needed
                                        width: '50px',

                                    }}
                                />


                            </div>

                            {/* <div className="fs-18 subtitle mb-3">Scan the QR Code to get the 6 digit code</div> */}
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <div className="otp-wrapper text-start mb-4">
                                    <Form.Group className="mx-auto" style={{ maxWidth: '300px' }}>
                                        {/* <Form.Control type="text" className="rounded-3 py-3" minLength={6} maxLength={6} placeholder="OTP" autoComplete="off" required onChange={(e) => {setOtp(e.target.value);setRandomNo(e.target.value)}}></Form.Control> */}
                                        <div style={{ position: "relative", display: "inline-block", width: "300px" }}>
                                            <Form.Control
                                                type="text"
                                                className="rounded-3 py-3"
                                                minLength={6}
                                                maxLength={6}
                                                placeholder="Enter Your 6 Digit Code"
                                                autoComplete="off"
                                                required
                                                value={otp}
                                                onChange={(e) => { setOtp(e.target.value); setRandomNo(e.target.value) }}
                                                style={{ paddingRight: "40px" }} // Space for loader
                                            />
                                            {isloading && (
                                                <Spinner
                                                    animation="border"
                                                    size="sm"
                                                    style={{
                                                        position: "absolute",
                                                        top: "40%",
                                                        right: "10px",
                                                        zIndex: 1,
                                                        color: 'green'
                                                    }}
                                                />
                                            )}
                                        </div>
                                        {errorMsg && errorMsg.code === 200 && visible ?
                                            <div className="ms-2 ps-1 text-success">{errorMsg.message}</div>
                                            : null
                                        }
                                        {errorMsg && errorMsg.code === 400 && visible ?
                                            <div className="ms-2 ps-1 text-danger">{errorMsg.message}</div> : null}

                                    </Form.Group>
                                </div>

                                <div className="d-flex flex-wrap justify-content-center gap-3">
                                    <Button variant="secondary" className="btn-custom fs-18 px-5 py-2" onClick={closeAuthenticationModal}>Cancel</Button>
                                    <Button type="button" variant="success" className="btn-custom fs-18 px-5 py-2" onClick={verifyOtp}>Verify OTP</Button>
                                </div>
                            </Form>
                        </>
                        : isAuthConfigured ?
                            <>
                            
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <div className="otp-wrapper text-start mb-4">
                                        <Form.Group className="mx-auto" style={{ maxWidth: '300px' }}>
                                            {/* <Form.Control type="text" className="rounded-3 py-3" minLength={6} maxLength={6} placeholder="OTP" autoComplete="off" required onChange={(e) => {setOtp(e.target.value);setRandomNo(e.target.value)}}></Form.Control> */}
                                            <div style={{ position: "relative", display: "inline-block", width: "300px" }}>
                                                <Form.Control
                                                    type="text"
                                                    className="rounded-3 py-3"
                                                    minLength={6}
                                                    maxLength={6}
                                                    placeholder="Enter Your 6 Digit Code"
                                                    autoComplete="off"
                                                    required
                                                    value={otp}
                                                    onChange={(e) => { setOtp(e.target.value); setRandomNo(e.target.value) }}
                                                    style={{ paddingRight: "40px" }} // Space for loader
                                                />
                                                {isloading && (
                                                    <Spinner
                                                        animation="border"
                                                        size="sm"
                                                        style={{
                                                            position: "absolute",
                                                            top: "40%",
                                                            right: "10px",
                                                            zIndex: 1,
                                                            color: 'green'
                                                        }}
                                                    />
                                                )}
                                            </div>
                                            {errorMsg && errorMsg.code === 200 && visible ?
                                                <div className="ms-2 ps-1 text-success">{errorMsg.message}</div>
                                                : null
                                            }
                                            {errorMsg && errorMsg.code === 400 && visible ?
                                                <div className="ms-2 ps-1 text-danger">{errorMsg.message}</div> : null}
                                        </Form.Group>
                                    </div>

                                    <div className="d-flex flex-wrap justify-content-center gap-3">
                                        <Button variant="secondary" className="btn-custom fs-18 px-5 py-2" onClick={closeAuthenticationModal}>Cancel</Button>
                                        <Button type="button`" variant="success" className="btn-custom fs-18 px-5 py-2" onClick={verifyOtp}>Verify OTP</Button>
                                    </div>
                                </Form>
                            </>
                            : null}
                </div>
            </Modal.Body>
        </Modal>
        //     <Modal show={show} centered onHide={close} scrollable backdrop="static" keyboard={false} className='p-0' contentClassName='rounded overflow-hidden'>
        //         <Modal.Body className="text-black px-3 py-4 px-lg-5 text-center" >

        //             <div className="custom-wrapper pt-xl-2 pb-xl-3">
        //                 <div className="fs-3 fw-bold lh-sm mb-3 text-center">MFA with Authenticator App</div>
        //                 <div className="mb-3">
        //                 {/* {!secret && <button onClick={fetchSecret}>Generate Secret</button>} */}
        //                 {!secret && !isAuthConfigured?
        //                 <div className='loader-wrapper text-center' style={{height:'60px'}}>
        //                      {<div className="qr-loader mx-auto"></div>}
        //                 </div>
        //                :null}
        //                 {secret && !isAuthConfigured ?
        //                     <>
        //                     <p>Scan this QR Code in Any Authenticator App:</p>
        //                     <div style={{ position: 'relative', display: 'inline-block' }}>
        //   {/* QR Code */}
        //   <QRCodeCanvas
        //     value={secret.qrCodeUrl}
        //     size={qrCodeSize}
        //     style={{ border: '1px solid #000' }}
        //   />
        //   {/* Logo */}
        //   <img
        //     src={"https://nm-live-media.s3.us-east-1.amazonaws.com/VE+Logo.png"}
        //     alt="Virtual Employee"
        //     style={{
        //       position: 'absolute',
        //       top: '50%',
        //       left: '50%',
        //       transform: 'translate(-50%, -50%)',
        //       height: '50px', // Adjust size as needed
        //       width: '50px',

        //     }}
        //   />
        // </div>
        //                     {/* <QRCodeCanvas value={secret.qrCodeUrl} /> */}

        //                     {/* <p>Alternatively, use this key: {secret.secret}</p> */}
        //                     {/* {isAuthConfigured? */}
        //                     <>
        //                     <p>
        //                     <input
        //                     className='me-2'
        //                         type="text"
        //                         placeholder="Enter OTP"
        //                         value={otp}
        //                         onChange={(e) => setOtp(e.target.value)}
        //                     />
        //                     <button onClick={verifyOtp}>Verify OTP</button>
        //                     </p>

        //                     </>

        //                     {/* :null} */}
        //                     </>
        //                     : isAuthConfigured?
        //                     <>
        //                     <input
        //                         type="text"
        //                         placeholder="Enter 6 digit Authenticator code"
        //                         value={otp}
        //                         onChange={(e) => setOtp(e.target.value)}
        //                     />
        //                     <button onClick={verifyOtp}>Verify OTP</button>
        //                     </>
        //                     :null
        //                 }

        //                 </div>


        //             </div>
        //         </Modal.Body>
        //     </Modal>
    )
}

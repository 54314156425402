import React from 'react';
import ApexCharts from 'react-apexcharts';
import { log__ } from '../utils/utils';

const MyBDMStatsChart = ({bdmChartData}) => {
    log__(bdmChartData?.earningByTypes)
    // const getCountForPolicy = (incentiveType) => {
    //     // log__(bdmChartData?.earningByTypes);
      
    //     const matchingItem = Array.isArray(bdmChartData?.earningByTypes) &&
    //       bdmChartData.earningByTypes.find(
    //         obj => obj.hasOwnProperty(incentiveType) && obj.card_type === "individual"
    //       );
      
    //     return matchingItem ? matchingItem[incentiveType] : 0;
    //   };
    // getCountForPolicy("new_hire_full_time")
   

    const chartData = {
        series: bdmChartData, // Example data values for each category
        options: {
            chart: {
                type: 'donut', // Donut chart
                height: 310,
            },
            labels: [
                'Full Time',
                'Part Time',
                'Cross Selling Part Time',
                'Cross Selling Full Time',
                'Upscale Full Time',
                'Upscale Part Time',
                'Client Referral Part Time',
                'Client Referral Full Time',
            ],
            plotOptions: {
                pie: {
                    donut: {
                        size: '65%', // Size of the hole in the center of the donut
                    },
                },
            },
            colors: ['#008ffb', '#00e396', '#ffb01a', '#ff4560', '#775dd0', '#9C27B0', '#00BCD4', '#8BC34A'], // Example color palette
            legend: {
                // position: 'bottom',
                // horizontalAlign: 'center', 
                // floating: false,
                // fontSize: '14px',
                // width: undefined,
                //height: 100,
                // offsetX: 0,
                // offsetY: 30,
                labels: {
                    colors: '#fff',
                },
                markers: {
                    strokeWidth: 1,
                    offsetX: -5,
                },
                itemMargin: {
                    vertical: 5,
                    // horizontal: 25
                },
            },
            responsive: [
                {
                    breakpoint: 576,
                    options: {
                        chart: {
                            // width: 300
                        },
                        legend: {
                            show: false
                        }
                    },
                },
                {
                    breakpoint: 768,
                    options: {
                        chart: {
                            // width: 300
                        },
                        legend: {
                            offsetY: 0,
                            horizontal: 0,
                            position: 'bottom',
                        }
                    },
                },
                {
                    breakpoint: 1200,  // Default behavior for screens <= 1400px (No change)
                    options: {
                        legend: {
                            offsetY: 30,
                        },
                    },
                },
                {
                    breakpoint: 1500,  // Default behavior for screens <= 1400px (No change)
                    options: {
                        legend: {
                            offsetY: 15,
                        },
                        itemMargin: {
                            horizontal: 15
                        },
                    },
                },
                {
                    breakpoint: 2100,  // For screens above 1400px
                    options: {
                        legend: {
                            offsetY: 30,
                        },
                        itemMargin: {
                            horizontal: 25
                        },
                    },
                },
            ]
            // fill: {
            //     opacity: 1,
            // },
        },
    };

    return (
        <ApexCharts
            options={chartData.options}
            series={chartData.series}
            type="donut"
            height={310}
        />
    );
};

export default MyBDMStatsChart;

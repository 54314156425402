import { Accordion, Button, Card, useAccordionButton } from "react-bootstrap"
import { convertReadable, log__ } from "../../utils/utils";
import { new_ClientType, new_opportunitytype, statuscode } from "../../utils/codes";
import { useNavigate } from "react-router-dom";

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        log__('totally custom!'),
    );

    return (
        <div className="custom-bg" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

export const DisputeCard = ({key, index, opInfo, user, userRole, loadWallet, setIsLoading, opRealTime, setActiveKey, activeKey,disputer,stateC}) => {

  
    let navigate = useNavigate();
    const navigateToDisputeDetails = async() =>{
        navigate("/dispute-details-admin",{state:{topic:opInfo.new_opportunityid, clientName:opInfo.new_fullname, opportunityid:opInfo.opportunityid,item:opInfo,userRole:userRole,stateC:stateC}})
    }
    return (
        <div className="info-card rounded-3 p-2 mt-3" style={{ border: '2px solid red' }}>
            <div className="highlight d-flex flex-wrap gap-2 text-white text-opacity-75 py-1 mb-2">
                                    <div className="info flex-fill d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between gap-2">
                                        <div className="left-info">
                                            <span className="text-red fs-5 fw-semibold me-2"><i className="bi bi-exclamation-octagon-fill text-white me-2"></i>Disputed</span>
                                            {disputer === "self"?
                                            <span>You have raised a dispute/concern regarding this opportunity, which is currently under management review. Click the arrow on the right to check the status.</span>
                                            :
                                            <span>A dispute has been raised regarding this opportunity, and it is currently under management review. The opportunity will remain on hold until further notice.</span>
                                            }
                                        </div>
                                        <Button variant="light" className="bg-white border-0 btn btn-light text-center float-xxl-end px-2 py-1" onClick={navigateToDisputeDetails}>
                                            <i className="bi bi-arrow-right fs-5 lh-sm"></i>
                                        </Button>
                                    </div>
                                </div>
            <Card.Header className="rounded-3" style={{ backgroundColor: '#1C5138' }}>
                <CustomToggle eventKey={index}>
                <div className="row gx-2 gy-2">
                        <div className="left-section col-md info-box text-capitalize">
                            <div className="label fs-5 fw-medium text-warning">{opInfo.new_fullname}</div>
                            <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                        </div>
                        <div className="right-section col-md info-box text-capitalize">
                            <div className="total-Incentive pe-5">
                                <div className="fs-5 fw-semibold text-white">Total Incentive: ₹{opInfo.incentivePolicyObject.total_bonus}</div>
                                <div className="fs-6 fw-semibold text-white text-opacity-50 text-capitalize">Incentive Type: {opInfo.incentivePolicyObject.scenario } ({opInfo.incentivePolicyObject.client_type})</div>
                            </div>
                        </div>
                    </div>
                </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index}>
                <Card.Body className="mt-3 px-2">
                <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                        <div className="col-12 col-md-4 col-lg-3 info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity ID</div>
                            <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Created</div>
                            <div className="value text-white text-opacity-75">{convertReadable(opInfo.createdon)}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
                            <div className="value text-white text-opacity-75">{convertReadable(opInfo.wallet_opportunities.createdAt)}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Skill Required</div>
                            <div className="value text-white text-opacity-75">{opInfo.skills}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Status</div>
                            {opInfo.statuscode === 3?
                                        <div className="value fw-medium" style={{ color: '#03ff85' }}>{statuscode[opInfo.statuscode]}</div>
                                        : opInfo.statuscode === 4?
                                        <div className="value fw-medium" style={{ color: '#d32f2f' }}>{statuscode[opInfo.statuscode]}</div>
                                        :
                                        <div className="value fw-medium " style={{ color: '#26c6da' }}>{statuscode[opInfo.statuscode]}</div>
                                        }
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Type</div>
                            <div className="value text-white text-opacity-75">{new_opportunitytype[opInfo.new_opportunitytype]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Client Type</div>
                            <div className="value text-white text-opacity-75">{new_ClientType[opInfo.new_clienttype]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Country</div>
                            <div className="value text-white text-opacity-75">{opInfo.country}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">TimeZone</div>
                            <div className="value text-white text-opacity-75">{opInfo.timezone}</div>
                        </div>
                    </div>
                    {/* <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                    <div className="waiting-section fs-18 fw-semibold text-info">Waiting for service creation</div>
                    <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                    <div className="description fs-12 text-white text-opacity-75 mb-2">
                        Incentive Redemption: The process will be available to you once the Opportunity status turns WON and payment is received from the client.
                    </div> */}
                </Card.Body>
            </Accordion.Collapse>
        </div>
    )
}
import React from "react";
import { useState } from "react";
import { callAxios, callCrmApi, convertDateTimeFromIso, getIndexBySystemUserId, shortName } from "../../utils/utils";
// import { environment } from "../environment";
// import DocViewer, { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import axios from "axios";

import DocumentPreview from "../../modals/DocumentPreview";
import DocPreview from "../../modals/DocPreview";


const EmailCard = ({ item, length = 800, clientName, userData }) => {
  // console.log(item)
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);
  const [showLess, setShowLess] = useState(true);
  const [body, setBody] = useState({});
  const [attachments_, setAttachments] = useState([]);
  const [docUrl, setDocUrl] = useState("");
  const [type, setType] = useState("");
  const [base64, setBase64] = useState("");
  const [blobData, setBlobData] = useState(null);
  let url;
  //console.log(body)
  const renderHtml = (htmlText) => {
    let returnText;
    if (htmlText && htmlText.length < length) {
      returnText = { __html: htmlText };
    }
    else {
      if (showLess) {
        returnText = { __html: htmlText.slice(0, length) + ' ...' };
      } else {
        returnText = { __html: htmlText };
      }
    }
    return returnText;
  }

  const renderDescription = (htmlText) => {
    let returnText;
    if (htmlText && htmlText.length < length) {
      returnText = htmlText;
    }
    else {
      if (showLess) {
        returnText = htmlText.slice(0, length) + ' ...';
      } else {
        returnText = htmlText;
      }
    }
    return returnText;
  }


  const getEmailDescription = async (activityid) => {
    setIsLoading(true)
    const payLoad = JSON.stringify({ "operation": "getemailbyactivityid", "activityid": activityid });

    const response = await callCrmApi(payLoad);
    // const getBody = await callAxios(
    //   environment.REACT_APP_API_URL_Live + "api/v1/leads/get-body",
    //   { activityid: activityid,type:"email", apiKey: "nmlive" },
    //   "post"
    // );
    // console.log(response)

    const partyPayload = JSON.stringify({ "operation": "fetchactivityparties", "activityid": activityid })

    const parties = await callCrmApi(partyPayload);

    const parties_ = parties.data.data;

    const To = [];
    const From = [];
    const Required = [];
    const Organizer = [];
    for (let i = 0; i < parties_.length; i++) {
      const getArrIndex = getIndexBySystemUserId(userData, parties_[i]._partyid_value);
      //const user = await Users.findOne({ where:{systemuserid: parties_[i]._partyid_value}});
      if (parties_[i].participationtypemask === 1) {

        if (getArrIndex > -1) {
          parties_[i]['display_name'] = userData[getArrIndex].display_name;
          From.push(parties_[i]);

        }

      }

      if (parties_[i].participationtypemask === 2) {
        //const user = await Users.findOne({ where:{systemuserid: parties_[i]._partyid_value}});
        if (getArrIndex > -1) {
          parties_[i]['display_name'] = userData[getArrIndex].display_name;
          To.push(parties_[i]);
        }
      }

      if (parties_[i].participationtypemask === 5) {
        //const user = await Users.findOne({ where:{systemuserid: parties_[i]._partyid_value}});
        if (getArrIndex > -1) {
          parties_[i]['display_name'] = userData[getArrIndex].display_name;
          Required.push(parties_[i]);
        }
      }

      if (parties_[i].participationtypemask === 7) {
        //const user = await Users.findOne({ where:{systemuserid: parties_[i]._partyid_value}});
        if (getArrIndex > -1) {
          parties_[i]['display_name'] = userData[getArrIndex].display_name;
          Organizer.push(parties_[i]);
        }
      }


    }
    setBody({ desc: response.data.data[0].description, to: To, from: From })
    setIsLoading(false)
    setShowLess(!showLess)
  }

  let cardType;
  if (item._regardingobjectid_value === item.leadId) {
    cardType = "Lead"
  }
  if (item._regardingobjectid_value === item.opportunityId) {
    cardType = "Opportunity"
  }

  const Body = Object.keys(body).length;

  const fetchAttachment = async (activityid) => {
    //console.log(activityid)
    setIsLoadingAttachments(true);
    const payLoad = JSON.stringify({ "operation": "fetchattachments", "activityid": activityid });

    const attachmentList = await callCrmApi(payLoad);
    //  const attachmentList = await callAxios(environment.REACT_APP_CRM_ENDPOINT,
    // {"operation":"fetchattachments", "activityid": activityid}, "post");





    //console.log("Attachements::",attachmentList.data.data)
    setAttachments(attachmentList.data.data)
    setIsLoadingAttachments(false)
  }

  const readAttachment_ = (base64Raw, mimetype) => {

    const bytes = atob(base64Raw);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: mimetype });
  }

  const readAttachment = (base64Raw, mimetype) => {
    // console.log(mimetype)
    setBase64(base64Raw);
    const type = mimetype.split("/")[1];
    const blob = readAttachment_(base64Raw, mimetype);
    setBlobData(blob);

    url = URL.createObjectURL(blob);
    setDocUrl(url);
    setType(type);
    //console.log("---------------------",url,type)
    if (type === "vnd.openxmlformats-officedocument.wordprocessingml.document") {

      document.getElementById("DocumentPreviewDocxModal").classList.add("in");
      document.getElementById("DocumentPreviewDocxModal").classList.add("show");
      document.getElementById("DocumentPreviewDocxModal").style.display = "block";
    } else {
      document.getElementById("preview-document").src = url;
      document.getElementById("DocumentPreviewModal").classList.add("in");
      document.getElementById("DocumentPreviewModal").classList.add("show");
      document.getElementById("DocumentPreviewModal").style.display = "block";
    }

    //return <DocumentPreview url={docUrl} blobData={blobData} type={type} />;
    // return (
    //   <div>
    //     <FileViewer
    //       fileType="pdf"
    //       filePath={URL.createObjectURL(blob)}
    //       errorComponent={<div>Error!</div>}
    //       //onError={onError}
    //     />
    //   </div>
    // );
  }



  const attachments = attachments_?.map((item, index) => {

    return <div className='required-name bg-white bg-opacity-75 text-dark text-nowrap text-capitalize px-2 py-1 pointer' onClick={() => { readAttachment(item.body, item.mimetype) }}>{item.filename}</div>;
  })
  return (
    <>
      {type === "vnd.openxmlformats-officedocument.wordprocessingml.document" ?
        <DocPreview url={docUrl} blobData={blobData} type={type} />
        :
        <DocumentPreview url={docUrl} blobData={blobData} type={type} />

      }
       <div className="user-img bg-warning bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '40px', width: '40px', minWidth: '40px' }}>
        {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
        <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">{shortName(item.display_name ? item.display_name : "Unknown")}</div>
      </div>
     
        <div className='attn-details-wrapper flex-fill fs-14'>
        <div className='info-header row g-2 mb-3'>
          <div className="col-md-8">

            <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize lh-sm mb-1">
                <span className="text-cstm-green">
                <i className="bi bi-envelope fs-6"></i> {item.activitytypecode}
                </span>

                
                  <span className='fw-normal'> - {cardType}</span>

                  
            </div>
            <div className="fs-14 d-flex flex-wrap column-gap-2">
              <div className="text-primary text-capitalize">Created By:</div>
              <div className='cc-user-wrapper fs-14 text-white text-capitalize'>
                {item.display_name ? item.display_name : "Unknown"} | {convertDateTimeFromIso(item.createdon)}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-white fs-14 d-flex flex-wrap justify-content-md-end column-gap-2">
              {item.totalTime ?
                <span className='time-ago text-capitalize'>{item.totalTime}</span>

                : null}
              <span className="text-primary text-capitalize">Since Last Update</span>

            </div>
          </div>
        </div>
          
          {item.subject ?
            <div className="client-subject mb-3">
              <div className='title-label fw-medium text-light'>Subject</div>
              <div className='description text-light text-opacity-75'>{item.subject}</div>
            </div>
            : null}
          {Body === 0 ? null :
            <div className="calling-info d-flex flex-wrap justify-content-xl-between gap-2 overflow-auto mb-3">
              {/* For Call */}

              <>
                {body?.to.length > 0 ?
                  <div className='info-box'>
                    <div className='title-label fw-medium text-light'>To</div>

                    {body?.to.map((to) => (
                      <div className='description text-light text-opacity-75 text-capitalize'>{to.display_name}</div>
                    ))}
                    {/* <div className='description text-light text-opacity-75 text-capitalize'>{clientName}</div> */}

                  </div>
                  : null}
                {body?.from.length > 0 ?
                  <div className='info-box'>
                    <div className='title-label fw-medium text-light'>From</div>
                    {/* <div className='description text-light text-opacity-75 text-capitalize'>Rohit Arora</div> */}

                    {body?.from.map((from) => (
                      <div className='description text-light text-opacity-75 text-capitalize'>{from.display_name}</div>
                    ))}

                  </div>
                  : null}
                {item.attachmentcount > 0 ?
                  <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Attachment</div>
                    {attachments_.length > 0 ? null :
                      <button onClick={() => { fetchAttachment(item.activityid) }} >List Attachment</button>
                    }
                    {isLoadingAttachments ? (
                      <div id="meeting-pointer-Preloader">
                        <div className="meeting-pointer-spinner"></div> Please wait
                        ....
                      </div>
                    ) :
                      <div className='description required-wrapper d-flex flex-wrap gap-2 mt-1' style={{ maxWidth: '300px' }}>

                        {attachments}
                      </div>
                    }
                  </div>
                  : null}
                {item.scheduleddurationminutes ?
                  <div className='info-box'>

                    <div className='title-label fw-medium text-light'>Duration</div>
                    <div className='description text-light text-opacity-75'>{item.scheduleddurationminutes} minutes</div>

                  </div>
                  : null}
                {/* <div className='info-box'>
                    <div className='title-label fw-medium text-light'>Status Reason</div>
                    <div className='description text-light text-opacity-75 text-capitalize'>25 July 2023 7:30 PM IST</div>
                </div> */}
              </>


            </div>
          }

          {/* <div className="client-requrement">
            
            <div className='description text-light text-opacity-75' dangerouslySetInnerHTML={renderHtml(body.desc)}></div>
        </div> */}
          {isLoading ? (
            <div id="meeting-pointer-Preloader">
              <div className="meeting-pointer-spinner"></div> Please wait
              ....
            </div>
          ) :
            <div className="requrement-wrapper" >
              {body.desc ?
                <div
                  className="client-requrement text-light text-opacity-75"
                  dangerouslySetInnerHTML={renderHtml(body.desc)}
                ></div>
                : null}
            </div>
          }

       
        {/* {body.desc && body.desc.length < length || body.desc === null ? null : */}
        <div className="view-more-box d-flex align-items-center justify-content-between gap-3 border-bottom border-light border-opacity-50 pointer px-3 py-1" style={{ backgroundColor: '#4d4b4c' }} onClick={() => setShowLess(!showLess)}>
          {showLess ?
            body.desc ?
              <div className='fw-medium text-light text-opacity-75 pointer' onClick={() => { setShowLess(!showLess) }}>
                &nbsp;View More
              </div>
              :
              <div className='fw-medium text-light text-opacity-75 pointer' onClick={() => getEmailDescription(item.activityid)}>
                &nbsp;View More
              </div>
            :
            <div className='fw-medium text-light text-opacity-75 pointer' onClick={() => { setShowLess(!showLess) }} >
              &nbsp;View Less
            </div>
          }

          <div className='icon lh-1'>
            {showLess ? <i className="bi bi-chevron-down text-white fs-5 pointer"></i> : <i className="bi bi-chevron-up text-white fs-5 pointer"></i>}
          </div>
        </div>
        {/* } */}


      </div>

    </>

  )
}

export default EmailCard;
import React, { useEffect, useState } from "react";
import './wallet.scss';
import { Button } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import "../LeadsListingPage/LeadsListingPage.css";

import { Showloader } from "./Components/Loader";
import { Leftside } from "./Components/Leftside";
import { Rightside } from "./Components/Rightside";
import DisputeStory from "./Components/DisputeStory";
import { BDMConversation } from "./Components/BDMConversation";
import { CommentSection } from "./Components/CommentSection";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setUserInfo } from "../store/actions";
import { callAxios, callCrmApi, getIndexBySystemUserId, log__, timeDiffDaysHourMinSec } from "../utils/utils";
import { environment } from "../environment";

import userData from '../utils/Users.json';

import PhoneCallCard from "../Cards/PhoneCallCard";
import AppointmentCard from "../Cards/AppointmentCard";
import EmailCard from "../Cards/EmailCard";
import NoteCard from "../Cards/NoteCard";
import TaskCard from "../Cards/TaskCard";

const DisputeDetails = ({ setLoggedinUser, userRole }) => {
    const [user, loading, error] = useAuthState(auth);
    let navigate = useNavigate();
    const {state} = useLocation();
     log__(state.item)
    const [isFullLoading, setIsFullLoading] = useState(true);
    const [allCrmUser, setCrmUsers] = useState([]);
    const [tabContent, setTabContent] = useState('DesputeStory');
    const [getLoading, setLoading] = useState(false)
    const [getClaimer, setClaimer] = useState('')
    const [getDisputer, setDisputer] = useState([])
    const [currentDisputer, setCurrentDisputer] = useState({})
    const [getMisc, setMisc] = useState('')
    const [getConversationdata, setConversationdata] = useState([])

    const [noteData, setNoteData] = useState([]);

    const getAnnotation = async (opportunityId) => {
      // log__(opportunityId)
      setIsFullLoading(true)
    
        const annotationPayload = JSON.stringify({
          "operation":"fetchannotations",
          "leadid": "",
          "opportunityid":opportunityId
      })
    
     const annotationResponse = await callCrmApi(annotationPayload)
       
       const fullData = annotationResponse.data.data;
                 
        const TotalEle = fullData.length;
          for(let i=0; i<fullData.length;i++){
            // const getArrIndex = getIndexBySystemUserId(userData,fullData[i]._createdby_value);

            const nameObject = allCrmUser.find(item => item.systemuserid === fullData[i]._createdby_value);
            
            // if(getArrIndex > -1){
            //   fullData[i]['display_name'] = userData[getArrIndex].display_name;
            //   fullData[i]['image'] = userData[getArrIndex].image;
             
            // }
              fullData[i]['display_name'] = nameObject.yomifullname;
              // fullData[i]['image'] = userData[getArrIndex].image;
                  if (i + 1 === fullData.length) {
                    ////log__(TotalEle);
              // Last one.
            } else {
              // Not last one.
              ////log__(item.createdon, index, index+1,arrayObj[index+1].createdon)
              const diff = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[i+1].createdon));
              const totalTime = timeDiffDaysHourMinSec(new Date(fullData[i].createdon),new Date(fullData[TotalEle-1].createdon));
             
              ////log__("Time Diff::",timeAgo)
              fullData[i]['timeAgo'] = diff;
              fullData[i]['totalTime'] = totalTime;
              fullData[i]['opportunityId'] = opportunityId;
              
    
    
            }
           
          }
          
         setNoteData(fullData);
         setIsFullLoading(false)
        
        
      };

    const fetchSystemUsers = async()=>{
      callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/co-ordination/fetch-actions`,{operation:"fetchsystemusers"},"post").then((users)=>{
        setCrmUsers(users.data.users);
      })
    }
// log__(allCrmUser)
log__(currentDisputer)
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
          // getAnnotation(state.opportunityid)
          callData()
          fetchSystemUsers();
          
          
          
    
        }
      }, [user, loading, userRole]);

    // callAPI
    const postdata = {
        "topic": state.topic.trim()
    }
    const callData = async() => {
        setIsFullLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/get-dispute-details`, postdata, "post").then((res) => {
            // log__(res.data)
            setMisc(res?.data)
            setClaimer(res?.data.claimer)
            setDisputer(res?.data.disputer)
            if(res?.data.disputer && res?.data.disputer.length > 0){
              const result = res?.data.disputer.find(item => item.raiser_email === user?.email);
              setCurrentDisputer(result)
            }
            getDisputeConversation()
            
            setIsFullLoading(false)
        }).catch((error) => {
            // log__("====>", error)
            getDisputeConversation()
            setIsFullLoading(false)
        })
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB');
    };

    const getDisputeConversation = () => {
        const postdata = {
            "topic": state.item?.new_opportunityid.trim()
        }
        setIsFullLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/get-dispute-conversation`, postdata, "post").then((res) => {
            // log__(res?.data.list)
            setConversationdata(res?.data.list)
              setIsFullLoading(false)
        }).catch((error) => {
            // log__("====>", error)
            // setIsFullLoading(false)
        })
    }

    const addDisputeConversation = (getData) => {
        setIsFullLoading(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/add-dispute-conversation`, getData, "post").then((res) => {
          if(res?.data.statusCode == 200){
            toast.success('Conversation has been added.', {
                position: "bottom-center",
            });
             setIsFullLoading(false)
            getDisputeConversation()
        }
        else{
            toast.error(res?.data?.message, {
                position: "bottom-center",
            });
            setIsFullLoading(false)
        }
        }).catch((error) => {
            // log__("====>", error)
            setIsFullLoading(false)
        })
    }

    const navigateToWalletBack = async(e) =>{
        e.preventDefault();
        navigate("/wallet")
    }

const handleNotes = (key) =>{
  log__(`Selected Tab: ${key}`);
  if(key === "LeadConversation"){
    getAnnotation(state.opportunityid)
  }
  // log__("hit")
}

    return (
        <div className="DisputeDetails py-3">
            <div className="container-fluid px-xxl-5">
            {isFullLoading? <div className="loading" style={{zIndex:9999}}>Loading&#8230;</div>:null}
                <div className="heading d-flex align-items-center gap-2 mb-3">
                    <Button variant="light" type="" className="bg-white border-0 btn btn-light text-center px-2 p-1" style={{ width: '40px' }} onClick={navigateToWalletBack}>
                        <i className="bi bi-arrow-left fs-4 lh-sm"></i>
                    </Button>
                    <div className="fs-3 fw-semibold text-light text-nowrap lh-sm ms-1">Dispute Details</div>
                </div>

                <div className="row g-3">
                    <div className="col-xl-8">
                        <Leftside leftData={getMisc} claimerData={getClaimer} dateFomate={formatDate} />
                    </div>
                    <div className="col-xl-4">
                        <Rightside rightData={getDisputer} dateFomate={formatDate} />
                    </div>
                </div>

                <hr className="border" />

                <div className="tabs-wrapper">
                    <Tabs id="OpportunityDetails" activeKey={tabContent} onSelect={(k) => {setTabContent(k);handleNotes(k);}} className="dark-theme-bg2 rounded-top border-0 mb-3">
                        <Tab eventKey="DesputeStory" title="Dispute Story">
                            <div className='tab-content-wrapper text-white text-opacity-75 d-flex flex-column gap-3'>

                                <DisputeStory disputerData={getDisputer} claimerData={getClaimer} />

                                <BDMConversation conversationData={getConversationdata} claimerData={getClaimer} dateFomate={formatDate} />

                                {/* <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden h-100">
                                    <div className="header-section d-flex flex-wrap justify-content-between row-gap-1 column-gap-3 p-3" style={{ backgroundColor: '#273d75' }}>
                                        <div className="d-flex gap-2 text-truncate">
                                            <div className="user-img mt-1">
                                                <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                                            </div>
                                            <div className="user-info text-truncate text-capitalize">
                                                <div className="user-name text-white fw-semibold text-truncate">Sameer Kumar</div>
                                                <div className="user-empCode text-white text-truncate">Management</div>
                                                <div className="opportunity-added fs-14 text-warning text-wrap text-capitalize mb-1">Submission : <span className="text-white ms-1">08-08-2024</span></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="body-section p-3 pb-4">
                                        <div className="wrapper ms-md-5 ps-md-1">
                                            <div className="info-box">
                                                <div className="msg-description mb-3">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                                </div>
                                                <div className="msg-description">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden h-100 px-3 px-md-5 py-5" style={{ backgroundColor: '#346F63' }}>
                                    <div className="fs-3 fw-semibold text-green text-center lh-sm">
                                        <div className="text-capitalize mb-1">Final Approval Card Design</div>
                                        <div className="text-uppercase">W.I.P</div>
                                    </div>
                                </div> */}

                                <CommentSection disputeConversation={addDisputeConversation} item={state.item} userRole={state.userRole} currentDisputer={currentDisputer} setIsFullLoading={setIsFullLoading}/> 
                                
                            </div>
                        </Tab>
                        <Tab eventKey="LeadConversation" title="Lead Conversation" onClick={handleNotes}>
                            <div className="client-info-details">
                            <div className='cam-bdm-attn-details'>
                            {
                        noteData.length > 0 ?

                        noteData?.map((item, index) => (
                          <div className="cam-bdm-card d-flex gap-2 gap-lg-3 mt-4">
                            <div className="user-wrapper text-center" style={{
                                  height: "40px",
                                  width: "40px"
                                }}>
                              <img
                                src={
                                  item.image
                                    ? item.image
                                    : require("../resources/images/Avatar.png")
                                }
                                alt=""
                                className="border border-1 rounded-circle shadow overflow-hidden"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  maxWidth: "40px",
                                  maxHeight:"40px"
                                }}
                              />
                              {item.timeAgo?
                              <div className='time-ago text-white text-opacity-75 mt-1 text-capitalize' style={{fontSize:"9px"}}>{item.timeAgo}</div>
                              :null }
                              {/* |
                              {item.timeAgo?
                              <div className='time-ago text-white text-opacity-75 mt-1 text-capitalize' style={{fontSize:"9px"}}>{item.timeAgo}</div>
                              :null } */}
                            </div>
                            {item.activitytypecode === "phonecall"?
                           
                              <PhoneCallCard item={item} clientName={state.clientName} />
                              :
                              item.activitytypecode === "appointment"?
                              <AppointmentCard item={item} clientName={state.clientName}  userData={userData} />
                              :
                              item.activitytypecode === "email"?
                              <EmailCard item={item} clientName={state.clientName}  userData={userData} />
                              :
                              item.activitytypecode === "task"?
                              <TaskCard item={item} clientName={state.clientName} />
                              :
                              item.objecttypecode === "lead" || item.objecttypecode === "opportunity"?
                              <NoteCard item={item} clientName={state.clientName} />
                              :
                            null}
                            {/* <ReadMore item={item} /> */}
                         
                          </div>
                        ))
                      :"No data found"
                      }
                            </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
            {/* <ToastContainer /> */}
        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(DisputeDetails);


export const IncentivePolicy = [
    {
        BDM: {
            new_hire_full_time: {
                hours:172,
                clienttype:[124950002,232520007,232520008,124950000],
                scenario: "New Hire",
                role: "BDM",
                client_type: "Full Time",
                immediate_bonus: 5000,
                first_month: 5000,
                second_month: 0,
                third_month: 0,
                total_bonus: 10000, // Was 1000, corrected based on values
                client_type_value:"new_hire_full_time"
            },
            new_hire_part_time: {
                hours:86,
                clienttype:[124950003,232520009,232520010,124950001],
                scenario: "New Hire",
                role: "BDM",
                client_type: "Part Time",
                immediate_bonus: 1500,
                first_month: 1500,
                second_month: 0,
                third_month: 0,
                total_bonus: 3000,
                client_type_value:"new_hire_part_time"
            },
            upscaling_full_time: {
                hours:0,
                clienttype:[232520001,232520022,232520023],
                scenario: "Upscaling",
                role: "BDM",
                client_type: "Full Time",
                immediate_bonus: 5000,
                first_month: 5000,
                second_month: 5000,
                third_month: 5000,
                total_bonus: 20000,
                client_type_value:"upscaling_full_time"
            },
            upscaling_part_time: {
                hours:0,
                clienttype:[232520002,232520024,232520025,124950001],
                scenario: "Upscaling",
                role: "BDM",
                client_type: "Part Time",
                immediate_bonus: 2000,
                first_month: 2000,
                second_month: 2000,
                third_month: 2000,
                total_bonus: 8000,
                client_type_value:"upscaling_part_time"
            },
            cross_selling_full_time: {
                hours:0,
                clienttype:[232520003,232520026,232520027],
                scenario: "Cross-Selling",
                role: "BDM",
                client_type: "Full Time",
                immediate_bonus: 5000,
                first_month: 0,
                second_month: 0,
                third_month: 0,
                total_bonus: 5000,
                client_type_value:"cross_selling_full_time"
            },
            cross_selling_part_time: {
                hours:0,
                clienttype:[232520004,232520028,232520029,124950001],
                scenario: "Cross-Selling",
                role: "BDM",
                client_type: "Part Time",
                immediate_bonus: 2000,
                first_month: 0,
                second_month: 0,
                third_month: 0,
                total_bonus: 2000,
                client_type_value:"cross_selling_part_time"
            },
            client_referral_full_time: {
                hours:0,
                clienttype:[],
                scenario: "Client Referral",
                role: "BDM",
                client_type: "Full Time",
                immediate_bonus: 10000,
                first_month: 15000,
                second_month: 0,
                third_month: 0,
                total_bonus: 25000,
                client_type_value:"client_referral_full_time"
            },
            client_referral_part_time: {
                clienttype:[],
                hours:0,
                scenario: "Client Referral",
                role: "BDM",
                client_type: "Part Time",
                immediate_bonus: 5000,
                first_month: 5000,
                second_month: 0,
                third_month: 0,
                total_bonus: 10000,
                client_type_value:"client_referral_part_time"
            }
        },
        SDM: {
            new_hire_full_time: {
                hours:0,
                scenario: "New Hire",
                role: "SDM",
                client_type: "Full Time",
                immediate_bonus: 5000,
                first_month: 5000,
                second_month: 5000,
                third_month: 0,
                total_bonus: 15000 // Was 1000, corrected based on values
            },
            new_hire_part_time: {
                hours:0,
                scenario: "New Hire",
                role: "SDM",
                client_type: "Part Time",
                immediate_bonus: 2000,
                first_month: 2000,
                second_month: 2000,
                third_month: 0,
                total_bonus: 6000
            },
            upscaling_full_time: {
                hours:0,
                scenario: "Upscaling",
                role: "SDM",
                client_type: "Full Time",
                immediate_bonus: 5000,
                first_month: 5000,
                second_month: 5000,
                third_month: 5000,
                total_bonus: 20000
            },
            upscaling_part_time: {
                hours:0,
                scenario: "Upscaling",
                role: "SDM",
                client_type: "Part Time",
                immediate_bonus: 2000,
                first_month: 2000,
                second_month: 2000,
                third_month: 2000,
                total_bonus: 8000
            },
            cross_selling_full_time: {
                hours:0,
                scenario: "Cross-Selling",
                role: "SDM",
                client_type: "Full Time",
                immediate_bonus: 5000,
                first_month: 0,
                second_month: 0,
                third_month: 0,
                total_bonus: 5000
            },
            cross_selling_part_time: {
                hours:0,
                scenario: "Cross-Selling",
                role: "SDM",
                client_type: "Part Time",
                immediate_bonus: 2000,
                first_month: 0,
                second_month: 0,
                third_month: 0,
                total_bonus: 2000
            },
            client_referral_full_time: {
                hours:0,
                scenario: "Client Referral",
                role: "SDM",
                client_type: "Full Time",
                immediate_bonus: 10000,
                first_month: 15000,
                second_month: 0,
                third_month: 0,
                total_bonus: 25000
            },
            client_referral_part_time: {
                hours:0,
                scenario: "Client Referral",
                role: "SDM",
                client_type: "Part Time",
                immediate_bonus: 5000,
                first_month: 5000,
                second_month: 0,
                third_month: 0,
                total_bonus: 10000
            }
        }
    }
];

import { Accordion, Card, useAccordionButton } from "react-bootstrap"
import { convertReadable, log__ } from "../../utils/utils";
import { nm__clienttype, statuscode } from "../../utils/codes";

function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
        log__('totally custom!'),
    );

    return (
        <div className="custom-bg" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}

export const InvalidPolicy = ({key, index, opInfo, user, userRole, loadWallet, setIsLoading, opRealTime, setActiveKey, activeKey}) => {

    return (
        <div className="info-card red-card rounded-3 p-2 mt-3" style={{ backgroundColor: '#7d2930' }}>
            <Card.Header>
                <CustomToggle eventKey={index}>
                    <div className="row gx-2 gy-2">
                        <div className="left-section col-md info-box text-capitalize">
                            <div className="label fs-5 fw-medium text-warning">{opInfo.new_fullname}</div>
                            <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                        </div>
                        <div className="right-section col-md info-box text-capitalize">
                            <div className="total-Incentive pe-5">
                                <div className="fs-5 fw-semibold text-white">Total Incentive: ₹{opInfo.incentivePolicyObject.total_bonus}</div>
                                <div className="fs-6 fw-semibold text-white text-opacity-50 text-capitalize">Incentive Type: {opInfo.incentivePolicyObject.scenario } ({opInfo.incentivePolicyObject.client_type})</div>
                            </div>
                        </div>
                    </div>
                </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey={index}>
                <Card.Body className="mt-3 px-2">
                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3 mb-4">
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity ID</div>
                            <div className="value text-white text-opacity-75 text-break">{opInfo.new_opportunityid}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Created</div>
                            <div className="value text-white text-opacity-75">{convertReadable(opInfo.createdon)}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
                            <div className="value text-white text-opacity-75">{convertReadable(opInfo.wallet_opportunities.createdAt)}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Skill Required</div>
                            <div className="value text-white text-opacity-75">{opInfo.skills}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Status</div>
                            {opInfo.statuscode === 3?
                                        <div className="value fw-medium" style={{ color: '#03ff85' }}>{statuscode[opInfo.statuscode]}</div>
                                        : opInfo.statuscode === 4?
                                        <div className="value fw-medium" style={{ color: '#d32f2f' }}>{statuscode[opInfo.statuscode]}</div>
                                        :
                                        <div className="value fw-medium " style={{ color: '#26c6da' }}>{statuscode[opInfo.statuscode]}</div>
                                        }
                        </div>
                    </div>
                    <div className="description fs-6 fw-medium text-white">
                        <p className="mb-0">The client type {opInfo.wallet_opportunities.bonus.scenario } ({opInfo.wallet_opportunities.bonus.client_type}) you originally selected when adding this opportunity to your wallet has been changed by the Billing team.</p>
                        <p>It has now been updated to {nm__clienttype[opInfo.serviceInfo[0].nm_clienttype].replace(/\b[12]\/2\b/g, "")} for this won opportunity, making it ineligible for incentives, as the client has paid for {nm__clienttype[opInfo.serviceInfo[0].nm_clienttype].replace(/\b[12]\/2\b/g, "")} services.</p>
                    </div>
                </Card.Body>
            </Accordion.Collapse>
        </div>
    )
}
import React from "react";
import { useState } from "react";
import { convertDateTimeFromIso, shortName } from "../../utils/utils";
import notebookPenIcon from '../../resources/images/notebook-pen.svg';

const NoteCard = ({ item, length = 400, clientName }) => {
  //console.log("In note card")

  const [showLess, setShowLess] = useState(true);

  const renderHtml = (htmlText) => {
    let returnText;
    if (htmlText && htmlText.length < length) {
      returnText = { __html: htmlText };
    }
    else {
      if (showLess) {
        returnText = { __html: htmlText.slice(0, length) + ' ...' };
      } else {
        returnText = { __html: htmlText };
      }
    }
    return returnText;
  }

  const renderDescription = (htmlText) => {
    //console.log(htmlText)
    let returnText;
    if (htmlText && htmlText.length < length) {
      returnText = htmlText;
    }
    else {
      if (showLess) {
        returnText = htmlText.slice(0, length) + ' ...';
      } else {
        returnText = htmlText;
      }
    }
    return returnText;
  }

  return (
    <>
      <div className="user-img bg-warning bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '40px', width: '40px', minWidth: '40px' }}>
        {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
        <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">{shortName(item.display_name ? item.display_name : "Unknown")}</div>
      </div>

      <div className='attn-details-wrapper flex-fill fs-14'>
        <div className='info-header row g-2 mb-3'>
          <div className="col-md-8">

            <div className="user-name fs-6 fw-medium text-white text-truncate text-capitalize lh-sm mb-1">
                <span className="text-cstm-green">
                  <img src={notebookPenIcon} alt='Icon' style={{ width: '18px', filter: 'invert(1)' }} /> Note
                </span>

                {item.objecttypecode ?
                  <span className='fw-normal'> - {item.objecttypecode}</span>

                  : null}
            </div>
            <div className="fs-14 d-flex flex-wrap column-gap-2">
              <div className="text-primary text-capitalize">Created By:</div>
              <div className='cc-user-wrapper fs-14 text-white text-capitalize'>
                {item.display_name ? item.display_name : "Unknown"} | {convertDateTimeFromIso(item.createdon)}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="text-white fs-14 d-flex flex-wrap justify-content-md-end column-gap-2">
              {item.totalTime ?
                <span className='time-ago text-capitalize'>{item.totalTime}</span>

                : null}
              <span className="text-primary text-capitalize">Since Last Update</span>

            </div>
          </div>
        </div>

        {item.subject ?
          <div className="client-subject mb-3">
            <div className='title-label fw-medium text-light'>Subject</div>
            <div className='description text-light text-opacity-75'>{item.subject}</div>
          </div>
          : null}


        {item?.notetext && item?.notetext != null ?
          <div className="client-requrement mb-3">
            <div className='title-label fw-medium text-light'>Description</div>
            <div className='description text-light text-opacity-75' dangerouslySetInnerHTML={renderHtml(item?.notetext)}></div>
          </div>
          : null}

        {item?.notetext && item?.notetext.length < length || item?.notetext === null ? null :
          <div className="view-more-box d-flex align-items-center justify-content-between gap-3 border-bottom border-light border-opacity-50 pointer px-3 py-1" style={{ backgroundColor: '#4d4b4c' }} onClick={() => setShowLess(!showLess)}>
            <div className='fw-medium text-light text-opacity-75 pointer' onClick={() => setShowLess(!showLess)}>
              &nbsp;View {showLess ? "More" : "Less"}
            </div>
            <div className='icon lh-1'>
              {showLess ? <i className="bi bi-chevron-down text-white fs-5 pointer"></i> : <i className="bi bi-chevron-up text-white fs-5 pointer"></i>}
            </div>
          </div>

        }
      </div>

    </>

  )
}

export default NoteCard;
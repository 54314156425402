import React, { useEffect, useState } from "react";
import CryptoJS from 'crypto-js';
import { useLocation, useNavigate } from "react-router-dom";
import { log__ } from "../../utils/utils";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { connect } from "react-redux";
import { setUserInfo } from "../../store/actions";
// Custom Accordion

const FileViewAdmin = ({ setLoggedinUser, userRole }) => {
    const location = useLocation();
    const [user, loading, error] = useAuthState(auth);
    let navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const encryptedData = searchParams.get('query');
    const bytes = CryptoJS.AES.decrypt(encryptedData, 'secret_key');
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    log__(decryptedData?.attachmentUrl);
    const [url, setUrl] = useState(decryptedData?.attachmentUrl); 
    const [getDoctype, setDoctype] = useState("");
    const [getwordUrl, setwordUrl] = useState("")

    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
    
          setLoggedinUser(userInfo);
          
          
    
        }
      }, [user, loading]);

    const getFileExtension = (fileName) => {
        const fileExtension = fileName.split('.').pop().toLowerCase(); 
        log__(fileExtension)
        // return fileExtension;
        if (['pdf'].includes(fileExtension)) {
            setDoctype('pdf')
            return 'pdf';
        }
        else if (['doc', 'docx'].includes(fileExtension)) {
            setDoctype('word')
            const documentUrl = fileName
            const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(documentUrl)}&embedded=true`;
            setwordUrl(googleDocsViewerUrl)
            setUrl("")
            return 'word';
        } 
        else if (['xls', 'xlsx'].includes(fileExtension)) {
            setDoctype('excel');
            const documentUrl = fileName;
            const googleSheetsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(documentUrl)}&embedded=true`;
            setwordUrl(googleSheetsViewerUrl); 
            setUrl("");
            return 'excel';
        }else if (['zip', 'rar', 'tar'].includes(fileExtension)) {
            setDoctype('zip')
            return 'zip'
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            setDoctype('image')
            return 'image'
        } else if (['mp3', 'wav', 'flac'].includes(fileExtension)) {
            setDoctype('audio')
            return 'audio';
        } else {
            return 'Unknown';
        }
    };
    // Listing data
    // log__("File type:::",getDoctype)
    useEffect(()=>{
        getFileExtension(decryptedData?.attachmentUrl)
    },[])

    return (
        <div className='notes-page InHouseNotes-page py-3 backgroundBlack mt-3'>
            <div className="container-fluid px-xxl-5">
                {getDoctype && url != "" ? (
                    // Checking the file extension and rendering the appropriate content
                    getDoctype == "pdf" ? (
                        <iframe src={url} title="PDF Viewer" width="100%"
                        height="500px"/>
                    ) : getDoctype == "image"   ? (
                            <img src={url} alt="Image" />
                        ) : getDoctype == "zip" ? (
                            <p>ZIP files cannot be displayed directly. <a href={url} target="_blank" rel="noopener noreferrer">Download ZIP</a></p>
                        ) : (
                            <p>File type not supported for preview.</p>
                        )
                ) : (
                    ''
                )}
                <iframe
                    src={getwordUrl}
                    width="100%"
                    height="500px"
                    title="Document Viewer"
                />
            </div >
        </div >
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
  export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(FileViewAdmin);


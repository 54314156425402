import React from "react";
import { useNavigate } from "react-router-dom";
import { nm__clienttype, nm_servicemonth } from "../../utils/codes";
export const Approvedtab = ({ userInstallmentsdata, dateFormate, getdashboardData,getallwalletUser, activeIndex_  }) => {
    const navigate = useNavigate();

    const handleDetailsPage = async(topic, serviceappliedid,type)=>{
        navigate("/management-opportunity-details",{state:{topic:topic,serviceappliedid:serviceappliedid,type:type,getdashboardData:getdashboardData,getallwalletUser:getallwalletUser,activeIndex_:activeIndex_}})
    }
    return (
        <>
            <div className='tab-content-wrapper'>
                <div className="row g-3">
                    {userInstallmentsdata?.map((items, index) => ((
                        <>
                            {items?.is_installment_approved === 1 ?
                                <div className="col-lg-6 pointer">
                                    <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden h-100" onClick={()=>{handleDetailsPage(items?.topic,items?._new_serviceapplied_value,items?.type)}}>
                                        <div className="header-section p-3" style={{ backgroundColor: 'var(--wallet-color3)' }}>
                                            <div className="d-flex flex-wrap align-items-center justify-content-between gap-1">
                                                <div class="fs-4 fw-semibold text-white lh-sm">₹{items?.amount} <span className="text-white text-opacity-50 fs-6 fw-semibold ms-1">Installment {items?.installment} of {items?.total_installments} | {items?.type.toUpperCase()}</span></div>
                                                <div class="text-white text-opacity-50 fs-6">Requested on: {dateFormate(items?.createdAt)} | <span className="text-white fw-medium">Approved</span></div>
                                            </div>
                                        </div>
                                        <div className="body-section p-3">
                                            <div className="row g-3">
                                                <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                    <div className="label fs-14 text-warning">Requested by</div>
                                                    <div className="value text-white text-opacity-75">{items?.name}</div>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                    <div className="label fs-14 text-warning">Opportunity ID</div>
                                                    <div className="value text-white text-opacity-75 text-break">{items?.topic}</div>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                    <div className="label fs-14 text-warning">Client Type</div>
                                                    <div className="value text-white text-opacity-75">{nm__clienttype[items?.client_type].replace(/\s+\S+$/, '')}</div>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                    <div className="label fs-14 text-warning">Service Month</div>
                                                    <div className="value text-white text-opacity-75">{nm_servicemonth[items?.service_month]}</div>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                    <div className="label fs-14 text-warning">Service ID</div>
                                                    <div className="value text-white text-opacity-75 text-uppercase text-break">{items?.service_id}</div>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-lg-6 col-xl-4 info-box text-capitalize">
                                                    <div className="label fs-14 text-warning">Invoice ID</div>
                                                    <div className="value text-white text-opacity-75">{items?.invoice_id}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""}
                        </>
                    )))}
                </div>
            </div>
        </>
    )
}
import React, { useState, useEffect } from 'react';
import './Announcement.scss';
import { Button } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { NewAnnouncementModal } from './NewAnnouncementModal';
import { DeleteAnnouncementModal } from './DeleteAnnouncementModal';

import { setUserInfo } from "../store/actions";
import { connect } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";
import { callAxios, formatDateToMonthYear } from "../utils/utils";
import { environment } from "../environment";
import { toast } from "react-toastify";
import moment from 'moment';

const Announcement = ({ setLoggedinUser, userRole }) => {
    const [detailsTab, setDetailsTab] = useState('All');
    
    const [user, loading, error] = useAuthState(auth);
    let navigate = useNavigate();
    // New notice modal
    const [showNoticeModal, setShowNoticeModal] = useState(false);
    const [activeCount, setActiveCount] = useState(0);
    const [inactiveCount, setInactiveCount] = useState(0);
    const [announcementData, setAnnouncementData] = useState([]);
    const [loadingData, setLoading] = useState(false); 

     // New notice modal
     const [AnnouncementModal, setAnnouncementModal] = useState(false);
     const closeAnnouncementModal = () => setAnnouncementModal(false);
     const openAnnouncementModal = () => setAnnouncementModal(true);
 
     // New notice modal
     const [showDelAnnouncementModal, setShowDelAnnouncementModal] = useState(false);
     const [selectedAnnouncementId, setSelectedAnnouncementId] = useState(null);
 
    

    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
    
          const userInfo = {
            uid: user?.uid,
            email: user?.email,
            displayName: user?.displayName,
            accessToken: user?.accessToken,
            idToken: user?.idToken,
          };
             setLoggedinUser(userInfo);
             fetchAnnouncements(detailsTab);
        }
      }, [user, loading, userRole]);
     
      useEffect(()=>{
        setAnnouncementData([]);
        fetchAnnouncements(detailsTab);
      },[detailsTab])

      const openDeleteAnnouncementModal = (announcementId) => {
        setSelectedAnnouncementId(announcementId); 
        setShowDelAnnouncementModal(true); 
    };

    // Function to close the modal
    const closeDeleteAnnouncementModal = () => {
        setSelectedAnnouncementId(null); 
        setShowDelAnnouncementModal(false);
    };

        // Function to call the delete API
        const handleDeleteAnnouncement = async () => {
            if (!selectedAnnouncementId) return; 
    
            try {
                const deleteRequest = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/delete-announcement`,
                    {
                        wallet_announcement_id: selectedAnnouncementId, // Use the selected ID
                        deleted_by: "Admin",
                    },
                    "post"
                  );
                
                
                if (deleteRequest.data.statusCode === 200) {
                    fetchAnnouncements(detailsTab);
                    toast.success("Deleted Successfully!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                } else {
                    toast.error("Network Issue!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                      });
                }
            } catch (error) {
                toast.error("Network Issue!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            } finally {
                closeDeleteAnnouncementModal(); // Close the modal in all cases
            }
        };
      //Fetch Announcements
      const fetchAnnouncements = async (filterTypeVal) => {
        let formData = {
            "pageSize":500,
            "page":1,
        };
        if(filterTypeVal != "All")
            {
                formData = {
                    ...formData,
                    is_active: filterTypeVal == "Active" ? 1 : 0,
                };
            } 
      console.log(formData);
      setLoading(true);   
        const response = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/get-announcement`,
          formData,
          "post"
        );
         if (response?.data?.statusCode === 200) {
            console.log("Announcement Data");
            console.log(response?.data);
            setActiveCount(response?.data?.activeCount || 0);
            setInactiveCount(response?.data?.inActiveCount || 0);
            setAnnouncementData(response?.data?.list || []);
            setLoading(false);
        } else {
          setLoading(false); 
          toast.error("Network Issue!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      };

      const handleStatusChange = async (aid,newStatus) => {
        try {
            const updateResponse = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/change-announcement-status`,
               { wallet_announcement_id: aid,
                is_active: newStatus,
                },         
                "post"  
            );
           console.log("updateResponse");
           console.log(updateResponse); 
           if (updateResponse?.data?.statusCode === 200) {
             fetchAnnouncements(detailsTab);
           }
            } catch (error) {
          console.error('Error changing announcement status:', error);
          toast.error("Network Issue!", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      };  

      //Format Date Time
      const formattedDate = (dateString) => {
        const formattedDate = moment(dateString).format('DD MMM YYYY, hh:mm A');
        return formattedDate
    }
    const handleNewAnnouncement = async (data) => {
        console.log(data)
        const payload = {
            posted_by: "Admin", // Replace with dynamic data if needed
            is_active: 1,
            title: data.title,
            description:data.description,
            name: "Admin", // Replace with dynamic data if needed
            userType: "SSA", // Replace with dynamic data if needed
            publish_datetime: moment(data.from).format("YYYY-MM-DD HH:mm:ss"),
            expire_datetime: moment(data.till).format("YYYY-MM-DD HH:mm:ss"),
        };

        try {
            const addAnnouncement = await callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/add-announcement`,
                payload,         
                "post"  
            );
            console.log('API Response:', addAnnouncement);
            if (addAnnouncement?.data?.statusCode === 200) {
         // Show success notification or take further action
            closeAnnouncementModal(); 
            fetchAnnouncements(detailsTab);
            toast.success("Added Successfully!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
            }
            else
            {
                toast.error("Network Issue!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            }    
        } catch (error) {
            toast.error("Network Issue!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        }
    };
    return (
        <div className='Announcement-page py-3'>
            <div className="container-fluid px-xxl-5">
                <div className="d-flex flex-wrap align-items-center justify-content-between gap-2 mb-3">
                    <div className="fs-3 fw-semibold text-light">Announcement</div>
                    <Button variant='light' className="rounded px-3 py-2" onClick={openAnnouncementModal}><i className="bi bi-plus-circle text-success me-1"></i> New Announcement</Button>
                </div>

                <div className="tabs-wrapper">
                    <Tabs id="controlled-tab-example" activeKey={detailsTab} onSelect={(k) => setDetailsTab(k)} className="bg-transparent rounded-top-3 mb-3">
                        <Tab eventKey="All" title="All">
                        {loadingData ? (
                    <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3" id="lead-list-spinner">
                        <div className="lead-list-spinner"></div>
                        {" "}Please wait ....
                    </div>
                     ) : 
                     <div className="custom-width-wrapper mx-auto" style={{ maxWidth: '1140px' }}>
                         <div className="d-flex flex-column gap-3">
                             {announcementData?.length > 0 && announcementData.map((announcement, index) => (
                                 <div className="info-card dark-theme-bg2 rounded-3 p-3" key={index}>
                                     <div className="d-flex flex-wrap align-items-center flex-sm-nowrap gap-2">
                                         <div className="user-img bg-info bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '50px', width: '50px', minWidth: '50px' }}>
                                             {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
                                             <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">Adm</div>
                                         </div>
                                         <div className="user-email-info w-100">
                                             <div className="row align-items-center g-3">
                                                 <div className="col-sm-8">
                                                     <div className="user-name fs-6 fw-semibold text-white text-truncate text-capitalize">Admin</div>
                                                     <div className="user-team fs-14 text-white text-opacity-75 text-capitalize">{announcement?.userType}</div>
                                                 </div>
                                                 <div className="col-sm-4 text-sm-end">
                                                     {announcement?.is_active == 1 ?
                                                         <span className='badge text-bg-danger fw-medium border rounded-1' style={{width:'100px',padding:'7px 12px',cursor: 'pointer'}} onClick={()=>handleStatusChange(announcement?.id,0)}>Inactive</span> :
                                                         <span className='badge text-bg-success fw-medium border rounded-1' style={{width:'100px',padding:'7px 12px',cursor: 'pointer'}} onClick={()=>handleStatusChange(announcement?.id,1)}>Active</span>
                                                     }
                                                 </div>
                                             </div>
                                         </div>
                                     </div>
                                     <div className="announcement-details mt-4">
                                         <div className="description-wrapper fs-6 text-white mb-3">
                                         {announcement?.title}
                                         </div>

                                         <div className='d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between gap-3'>
                                             <div className="left-info d-flex flex-wrap row-gap-1 column-gap-4">
                                                 <div className="published_on text-success me-xl-4">Published on: {formattedDate(announcement?.publish_datetime)}</div>
                                                 <div className="expires_on text-danger">Expires on: {formattedDate(announcement?.expire_datetime)}</div>
                                             </div>
                                             <div className="btn-wrapper">
                                                 <Button variant='outline-danger' size='sm' onClick={() => openDeleteAnnouncementModal(announcement?.id)}><i className='bi bi-trash3'></i></Button>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             ))}
                         </div>
                     </div>}
                          
                        </Tab>
                        <Tab eventKey="Active" title={`Active (${activeCount})`}>
                        
                        {loadingData ? (
                    <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3" id="lead-list-spinner">
                        <div className="lead-list-spinner"></div>
                        {" "}Please wait ....
                    </div>
                     ) :  
                        <div>{announcementData?.length > 0 ?
                        <div className="custom-width-wrapper mx-auto" style={{ maxWidth: '1140px' }}>
                                <div className="d-flex flex-column gap-3">
                                     {announcementData.map((announcement, index) => (
                                        <div className="info-card dark-theme-bg2 rounded-3 p-3" key={index}>
                                            <div className="d-flex flex-wrap align-items-center flex-sm-nowrap gap-2">
                                                <div className="user-img bg-info bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '50px', width: '50px', minWidth: '50px' }}>
                                                    {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
                                                    <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">Adm</div>
                                                </div>
                                                <div className="user-email-info w-100">
                                                    <div className="row align-items-center g-3">
                                                        <div className="col-sm-8">
                                                            <div className="user-name fs-6 fw-semibold text-white text-truncate text-capitalize">Admin</div>
                                                            <div className="user-team fs-14 text-white text-opacity-75 text-capitalize">{announcement?.userType}</div>
                                                        </div>
                                                        <div className="col-sm-4 text-sm-end">
                                                        {announcement?.is_active == 1 ?
                                                         <span className='badge text-bg-danger fw-medium border rounded-1' style={{width:'100px',padding:'7px 12px',cursor: 'pointer'}} onClick={()=>handleStatusChange(announcement?.id,0)}>Inactive</span> :
                                                         <span className='badge text-bg-success fw-medium border rounded-1' style={{width:'100px',padding:'7px 12px',cursor: 'pointer'}} onClick={()=>handleStatusChange(announcement?.id,1)}>Active</span>
                                                     }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="announcement-details mt-4">
                                                <div className="description-wrapper fs-6 text-white mb-3">
                                                {announcement?.text}
                                                </div>

                                                <div className='d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between gap-3'>
                                                    <div className="left-info d-flex flex-wrap row-gap-1 column-gap-4">
                                                        <div className="published_on text-success me-xl-4">Published on: {formattedDate(announcement?.publish_datetime)}</div>
                                                        <div className="expires_on text-danger">Expires on: {formattedDate(announcement?.expire_datetime)}</div>
                                                    </div>
                                                    <div className="btn-wrapper">
                                                        <Button variant='outline-danger' size='sm'><i className='bi bi-trash3'></i></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            :
                            <div className='tab-content-wrapper'>
                                No content available here
                            </div>
                        }</div>
                    }
                        </Tab>
                        <Tab eventKey="Inactive" title={`Inactive (${inactiveCount})`}>
                        {loadingData ? (
                    <div className="d-flex flex-row-reverse align-items-center justify-content-center gap-3" id="lead-list-spinner">
                        <div className="lead-list-spinner"></div>
                        {" "}Please wait ....
                    </div>
                     ) :
                        <div>
                        {announcementData?.length > 0 ?
                        <div className="custom-width-wrapper mx-auto" style={{ maxWidth: '1140px' }}>
                                <div className="d-flex flex-column gap-3">
                                     {announcementData.map((announcement, index) => (
                                        <div className="info-card dark-theme-bg2 rounded-3 p-3" key={index}>
                                            <div className="d-flex flex-wrap align-items-center flex-sm-nowrap gap-2">
                                                <div className="user-img bg-info bg-opacity-75 rounded-circle d-flex align-items-center justify-content-center overflow-hidden" style={{ height: '50px', width: '50px', minWidth: '50px' }}>
                                                    {/* <img src={require('../resources/images/Avatar.png')} alt="User" className="img-fluid" /> */}
                                                    <div className="user-sortname text-white fs-6 fw-semibold text-uppercase">Adm</div>
                                                </div>
                                                <div className="user-email-info w-100">
                                                    <div className="row align-items-center g-3">
                                                        <div className="col-sm-8">
                                                            <div className="user-name fs-6 fw-semibold text-white text-truncate text-capitalize">Admin</div>
                                                            <div className="user-team fs-14 text-white text-opacity-75 text-capitalize">{announcement?.userType}</div>
                                                        </div>
                                                        <div className="col-sm-4 text-sm-end">
                                                        {announcement?.is_active == 1 ?
                                                         <span className='badge text-bg-danger fw-medium border rounded-1' style={{width:'100px',padding:'7px 12px',cursor: 'pointer'}} onClick={()=>handleStatusChange(announcement?.id,0)}>Inactive</span> :
                                                         <span className='badge text-bg-success fw-medium border rounded-1' style={{width:'100px',padding:'7px 12px',cursor: 'pointer'}} onClick={()=>handleStatusChange(announcement?.id,1)}>Active</span>
                                                     }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="announcement-details mt-4">
                                                <div className="description-wrapper fs-6 text-white mb-3">
                                                {announcement?.text}
                                                </div>

                                                <div className='d-flex flex-wrap flex-sm-nowrap align-items-center justify-content-between gap-3'>
                                                    <div className="left-info d-flex flex-wrap row-gap-1 column-gap-4">
                                                        <div className="published_on text-success me-xl-4">Published on: {formattedDate(announcement?.publish_datetime)}</div>
                                                        <div className="expires_on text-danger">Expires on: {formattedDate(announcement?.expire_datetime)}</div>
                                                    </div>
                                                    <div className="btn-wrapper">
                                                        <Button variant='outline-danger' size='sm'><i className='bi bi-trash3'></i></Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            :
                            <div className='tab-content-wrapper'>
                                No content available here
                            </div>
                        }
                        </div>
                    }
                        </Tab>
                    </Tabs>
                </div>
            </div>

            {/* New Notice Modal */}
            <NewAnnouncementModal
            show={AnnouncementModal}
            close={closeAnnouncementModal}
            onSubmit={handleNewAnnouncement} // Pass submission handler
            />
            {/* Delete Announcement Modal */}
            <DeleteAnnouncementModal show={showDelAnnouncementModal} 
            close={closeDeleteAnnouncementModal}
            confirmDelete={handleDeleteAnnouncement}
            />

        </div>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
    };
  };
  
  const mapStoreStateToProps = (state) => {
    return {
      ...state,
    };
  };
   
    export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
  )(Announcement);
import React, { useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { callAxios } from '../utils/utils';
import { environment } from '../environment';
import { toast } from 'react-toastify';

export const ManagementRejectModal = ({ show, close, currentItem, setShowManagementRejectModal,by,userRole,opportunityDetailsdata }) => {
    // console.log("reject modal:::", currentItem)
      // Function to handle checkbox change event
      const [isChecked, setIsChecked] = useState(false);
      const [rejectId, setRejectId] = useState(null)
      const [reason, setReason] = useState("");
       const [isPLoading, setIsPLoading] = useState(false)

      const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        setRejectId(currentItem?.id)
       
    };

      const handleReject = async() =>{
            setIsPLoading(true)
            callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/reject-approval`,{rejectId,reason,by,userRole},"post").then((res)=>{
                // console.log(res)
                setIsPLoading(false)
                if(res.status ===200){
                    
                    setShowManagementRejectModal(false);
                    opportunityDetailsdata()
                toast.success("Approval rejected", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                } else{
                    toast.error("something went wrong!!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                }
            })
        }
    return (
        <Modal show={show} centered onHide={close} scrollable backdrop="static" keyboard={false} className='p-0' contentClassName='rounded overflow-hidden'>
            <Modal.Body className="text-black px-3 py-4 px-lg-5">
            {isPLoading?<div className="loader-overlay" id="loader" style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    height: '100vh',
                    background: 'rgba(0, 0, 0, 0.8)',
                    backdropFilter: 'blur(2px)'
                }}>
                    <img src={require('../resources/images/wallet/walletpreloader.gif')} alt="Balance" className="img-fluid" style={{ maxWidth: '200px', margin: "0 auto" }} />
    
                    <div className="text-center w-50">
                        <p>Please wait...</p>
                    </div>
    
                </div>
                :null}
                <div className="custom-wrapper pt-xl-2 pb-xl-3">
                    <div className="fs-3 fw-bold lh-sm mb-3">Reject Installment</div>
                    <div className="installment-info mb-3">
                        <div className="fs-5 fw-semibold">{currentItem?.installment === 1 ? "1st "
                                    : currentItem?.installment === 2 ? "2nd "
                                     : currentItem?.installment === 3 ? "3rd "
                                      : currentItem?.installment === 4 ? "4th " :null}nd Installment of Incentive</div>
                        <div className="fs-5 fw-semibold mt-1">₹{currentItem?.amount}</div>
                    </div>

                    <Form>
                        <Form.Group className='mb-3 pb-xl-1'>
                            <Form.Control as="textarea" rows={4} className="py-2" placeholder="Write note..." autoComplete="off" onChange={(e)=>{setReason(e.target.value)}} required/>
                        </Form.Group>
                        <div class="form-check text-muted">
                            <input class="form-check-input border border-secondary" type="checkbox" value="" id="rejectionAcknowledge" style={{ borderWidth: '1.5px!important', borderRadius: '2px' }} checked={isChecked} onChange={handleCheckboxChange} />
                            <label class="form-check-label fw-light" for="rejectionAcknowledge">I acknowledge the rejection of this installment.</label>
                        </div>
                        <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 mt-4 pt-xl-1">
                            <Button variant='secondary' className='btn-custom fs-18 px-5 py-2' onClick={close}>Cancel</Button>
                            {isChecked?
                            <Button variant='danger' className='btn-custom fs-18 px-5 py-2' onClick={handleReject}>Reject</Button>
                            :null}
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

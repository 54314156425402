import React, {useState} from "react";

export const WalletUsers = ({allwalletusers, userData, setActiveIndex_,activeIndex_}) => {
    const [activeIndex, setActiveIndex] = useState(activeIndex_ ?? 0);
    const handleClick = (index, items) => {
        setActiveIndex(index);
        setActiveIndex_(index)
        userData(items)
    };
    return (
        <>
            {allwalletusers?.map((items, index) => (
                
                <div className={`user-info d-flex align-items-center gap-2 pointer rounded-3 position-relative ${activeIndex === index ? 'border-warning' : ''}`} onClick={() => handleClick(index, items)} key={index}>
                    <img src={require('../../resources/images/Avatar.png')} alt="Wallet User" className="img-fluid border border-light border-opacity-50 rounded-circle" style={{ height: '48px', width: '48px', minWidth: '48px' }} />
                    <div className="text-truncate text-capitalize">
                        <div className="user-name text-white fs-6 fw-semibold text-truncate">{items.name}</div>
                        {/* <div className="designation text-white text-opacity-75 fs-12 text-truncate">Web Designer | TK10000</div> */}
                    </div>
                    {items?.pendingInstallments > 0?
                    <div className="user-status bg-danger text-white fs-12 fw-semibold rounded-1 position-absolute top-0 end-0 me-2 z-1 ms-2 px-2" style={{marginTop:'-9px'}}>Pending</div>
                    :null}
                </div>
            ))}
        </>
    )
}
import { Button } from 'react-bootstrap'

export const ShareCard = ({openRedeemAmountModal})=>{

    return (
        <div className="info-card special-data-card border border-5 border-warning border-opacity-75 rounded-3 p-3 pb-4 mt-3">
                            <div className="row align-items-center gx-3">
                                <div className="col-lg-4">
                                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start gap-2 gap-xxl-4">
                                        <div className="gift-img">
                                            <img src={require('../../resources/images/wallet/giftbox.png')} alt="Giftbox" className="img-fluid" />
                                        </div>
                                        <div className="gift-info">
                                            <div className="gift-amount fs-2 fw-semibold text-white lh-sm">₹2,000 <span className="fs-6 fw-normal text-warning text-capitalize ms-2">Incentive Received</span></div>
                                            <div className="installment-info fs-6 fw-medium text-white text-opacity-50">Installment 1 of 2</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center gap-2 gap-xxl-3 my-4 my-lg-0">
                                        <div className="left-info text-center text-sm-end">
                                            <div className="fs-5 fw-semibold text-white lh-sm">₹2,000</div>
                                            <div className="fs-6 text-warning text-capitalize">Shared with you</div>
                                        </div>
                                        <div className="middle-info d-flex align-items-center gap-2 gap-xxl-3 text-center">
                                            <div className="left-arrow">
                                                <img src={require('../../resources/images/wallet/left-arrow.png')} alt="Left Arrow" />
                                            </div>
                                            <div className="circle-info text-white d-flex flex-column align-items-center justify-content-center border border-1 rounded-circle" style={{ height: '95px', width: '95px' }}>
                                                <div className="amount fs-18 fw-semibold">₹5,000</div>
                                                <div className="fs-6 text-white text-opacity-50">Total</div>
                                            </div>
                                            <div className="right-arrow">
                                                <img src={require('../../resources/images/wallet/right-arrow.png')} alt="Right Arrow" />
                                            </div>
                                        </div>
                                        <div className="right-info text-center text-sm-start">
                                            <div className="fs-5 fw-semibold text-white lh-sm">₹3,000</div>
                                            <div className="fs-6 text-warning text-capitalize">He/she Kept it</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-end column-gap-3">
                                        <span className="fs-6 text-warning text-capitalize">Shared By</span>
                                        <span className="d-flex align-items-center gap-2">
                                            <div className="img-wrapper">
                                                <img src={require('../../resources/images/Avatar.png')} alt="Shared User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px' }} />
                                            </div>
                                            <div className="shared-user-info text-capitalize">
                                                <div className="name text-white">Raghuveer Kumar Prasad</div>
                                                <div className="name text-white text-opacity-75">Web Designer | TK10000</div>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-3">
                                <hr class="divider border border-1 border-top-2 border-secondary border-opacity-75 mt-2 mb-3" />
                                <div className="description fs-6 fw-medium text-white mb-3">To assist him in converting the opportunity and points below:</div>
                                <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3 mb-4">
                                    <div className="info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity ID</div>
                                        <div className="value text-white text-opacity-75 text-break">Xaurl Churay_080110_009</div>
                                    </div>
                                    <div className="info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Type</div>
                                        <div className="value text-white text-opacity-75">In-House Team</div>
                                    </div>
                                    <div className="info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Client Name</div>
                                        <div className="value text-white text-opacity-75">John Doe</div>
                                    </div>
                                    <div className="info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Client Type</div>
                                        <div className="value text-white text-opacity-75">Additional New</div>
                                    </div>
                                    <div className="info-box text-capitalize">
                                        <div className="label fs-14 text-warning">Opportunity Status</div>
                                        <div class="value fw-medium" style={{ color: 'rgb(3, 255, 133)' }}>Won</div>
                                    </div>
                                </div>
                                <hr class="divider border border-1 border-top-2 border-secondary border-opacity-75 my-3" />
                                <div className="row g-3">
                                    <div className="col-lg-8">
                                        <div className="checklist-wrapper">
                                            <div className="checklist-label fs-6 fw-semibold text-warning mb-3">Points</div>
                                            <ul className="m-0">
                                                <li>You supported BDM create the proposal.</li>
                                                <li>You supported BDM prepare time estimations.</li>
                                                <li>You supported BDM present with you on the client call for support.</li>
                                                <li>You supported BDM handle the client call alone in his/her absence.</li>
                                                <li>You supported BDM screen resumes carefully for him/her before sending resumes to the client.</li>
                                                <li>You supported BDM interview candidates for him/her before sending their resumes to the client.</li>
                                                <li>You supported BDM conduct research on the client's requirements.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="btn-wrapper text-lg-end">
                                            <Button variant="success" className="text-center border-0 rounded-1 px-3 px-md-4 py-1" onClick={openRedeemAmountModal} style={{ minWidth: '240px' }}>
                                                <div className="fs-5 fw-semibold text-white lh-sm">Redeem ₹2,000</div>
                                                <div className="fs-12 text-white text-opacity-75">You can redeem now</div>
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    )
}
import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { callAxios, log__ } from '../utils/utils';
import { environment } from '../environment';
import { toast } from 'react-toastify';

export const RaiseDisputeModal = ({ show, close, item, setShowRaiseDisputeModal, resetSearch,setIsFullLoading,loadWallet }) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [selectRoleOption, setSelectRoleOption] = useState('');
    const [raiseIssue, setRaiseIssue] = useState(null)
    const [isChecked, setIsChecked] = useState(false);

    const [bdmOption, setBdmOption] = useState({});
    const [sbdmOption, setSbdmOption] = useState({})

    const [isDiscusstion, setDiscussion] = useState(null)
    const [didCoordination, setDidcoordination] = useState(null)
    const [doyouthink, setdoyouthink] = useState(null)
    const [selectedBDMList, setselectedBDMList] = useState([]);
    const [selectedSBDMList, setselectedSBDMList] = useState([]);
    const [coordinationEmail, setCoordinationEmail] = useState('')
    const [additionalINfo, setAdditional] = useState('')

    useEffect(() => {
        log__(raiseIssue)
        setRaiseIssue(raiseIssue);
        if (raiseIssue === "yes") {
            setSelectedOption('optionYes')
        } else {
            setSelectedOption('');
            setSelectRoleOption('')
        }

    }, [raiseIssue])

    useEffect(() => {
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/get-option-list`, {}, "post").then((options) => {
            setBdmOption(options.data.BDM);
            setSbdmOption(options.data.SBDM)
        }).catch((error) => {

        })
        setselectedBDMList([])
        setselectedSBDMList([])
    }, [])

    const handleChange = (event) => {
        // log__()
        setRaiseIssue(event.target.value);
    };

    const handleRoleChange = (event) => {
        setSelectRoleOption(event.target.value);
    };

    // Function to handle checkbox change event
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const formData = {
        
        "wallet_id": item?.wallet_id,
        "raiser_email": item?.raiseEmail,
        "raiser_name": item?.raiseName,
        "opportunity_name": item?.new_fullname,
        "topic": item?.new_opportunityid,
        "bdm_on_opportunity": item?.camListedBDM,
        "bdm_on_opportunity_email": item?.bdmEmail,
        "is_this_same_client": raiseIssue === "yes" ? true : false,
        "role_for_this_opportunity": selectRoleOption === "optionBDM" ? "BDM" : "SBDM",
        "discussion_on_client_call": isDiscusstion,
        "did_coordination_reach_out_to_you": didCoordination,
        "someone_mistakenly_added": doyouthink,
        "contribution": selectRoleOption === "optionBDM" ? selectedBDMList.join(",") : selectedSBDMList.join(","),
        "coordination_email": coordinationEmail,
        "additional_information": additionalINfo,
        "is_box_checked": isChecked,
        "dispute_reason": "Client claims overpayment."
    }

    // log__(sbdmOption)


    const handleOption = (index, id) => {
        log__(index, id)
        if (index === 0) {
            setDiscussion(id)
        }
        if (index === 1) {
            setDidcoordination(id)
        }
        if (index === 2) {
            setdoyouthink(id)
        }
    }

    const handleBDMCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            // Add value if checked
            setselectedBDMList((prev) => [...prev, value]);
        } else {
            // Remove value if unchecked
            setselectedBDMList((prev) => prev.filter((item) => item !== value));
        }
    };

    const handleSBDMCheckboxChange = (event) => {
        const value = event.target.value;
        if (event.target.checked) {
            // Add value if checked
            setselectedSBDMList((prev) => [...prev, value]);
        } else {
            // Remove value if unchecked
            setselectedSBDMList((prev) => prev.filter((item) => item !== value));
        }
    };

    const addToWallet = async(item) =>{
      
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/add-to-wallet`,{item:item,incentivetype:item?.incentiveObject?.client_type_value,usertype:"BDM",bonus:item?.incentiveObject,card_type:"disputed"},"post").then((res)=>{
            // log__(res)
            if(res.status === 201){
               
                  loadWallet("BDM",['individual','invalidpolicy','beforeOct24','disputed'],"all")
            } 
            
        })
      }

    const handleRaiseConcern = async(e) =>{
        e.preventDefault();
        setIsFullLoading(true)
        log__("form is submitting")
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/raise-dispute`,formData,"post").then((submittedData)=>{
            if(submittedData.status === 201){
                setIsFullLoading(false)
                setShowRaiseDisputeModal(false);
                resetSearch()
                toast.success("Dispute Raised successfully!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  addToWallet(item);
            }
            if(submittedData.status === 200 && submittedData.data.statusCode === 400){
                setIsFullLoading(false)
                setShowRaiseDisputeModal(false);
                 resetSearch()
                toast.error(submittedData.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
            }
        }).catch((error)=>{
            log__(error)
            setIsFullLoading(false)
            setShowRaiseDisputeModal(false);
            resetSearch()
            toast.error("something went wrong!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
        })
    }

    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} className='p-0' dialogClassName="DisputeModal" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="wallet-info-section text-black px-3 px-md-4 px-lg-5 py-4">
                <div className="fs-3 fw-bold lh-sm mb-2 mb-xl-3">Raise Dispute/Concern</div>
                <div className='d-flex flex-wrap align-items-center justify-content-between gap-2 mb-4'>
                    <div className="info-box fs-6 text-capitalize text-truncate">
                        <div className="name fs-5 fw-semibold text-truncate">{item.new_fullname}</div>
                        <div className="designation text-truncate">{item.new_opportunityid}</div>
                    </div>
                    <div className="info-box fs-6 text-capitalize text-truncate">
                        <div className="name fs-5 fw-semibold text-truncate">{item.camListedBDM}</div>
                        <div className="designation text-truncate">Opportunity BDM</div>
                    </div>
                </div>
                <div className="info fw-medium lh-sm mb-4" style={{ color: '#FF191A' }}>
                    *You are raising a dispute request for the above opportunity and incentive. This will undergo thorough verification by management.
                    During the review process, you may or may not be identified as the rightful owner of the opportunity.
                    The final decision will rest with management.
                </div>
                <hr className='border border-1 border-secondary border-opacity-75 mb-3' />
                <Form >
                    <div className="field-cover mb-3">
                        <div className="text-primary mb-1">Are you certain that this is the same client and opportunity ID for which you want to raise an incentive dispute? Please confirm carefully before proceeding with the form.</div>
                        <div className="radio-wrapper selectable-color ps-1">
                            <div class="form-check d-inline-flex align-items-center gap-2 me-5">
                                <input class="form-check-input fs-18" type="radio" name="incentiveDispute" value={"yes"} id="DisputeYes" checked={raiseIssue === "yes"} onChange={handleChange} />
                                <label class="form-check-label fs-4 fw-semibold pt-1" for="DisputeYes">Yes</label>
                            </div>
                            <div class="form-check d-inline-flex align-items-center gap-2">
                                <input class="form-check-input fs-18" type="radio" name="incentiveDispute" value={"no"} id="DisputeNo" checked={raiseIssue === "no"} onChange={handleChange} />
                                <label class="form-check-label fs-4 fw-semibold pt-1" for="DisputeNo">No</label>
                            </div>
                        </div>
                    </div>

                    {selectedOption === 'optionYes' && (
                        <div className="field-cover mb-4">
                            <div className="text-primary mb-1">What was your role for this opportunity?</div>
                            <div className="radio-wrapper selectable-color ps-1 mb-2">
                                <div class="form-check d-inline-flex align-items-center gap-2 me-5">
                                    <input class="form-check-input fs-18" type="radio" name="roleOpportunity" value="optionBDM" id="RoleBDM" checked={selectRoleOption === 'optionBDM'} onChange={handleRoleChange} />
                                    <label class="form-check-label fs-4 fw-semibold pt-1" for="RoleBDM">BDM</label>
                                </div>
                                <div class="form-check d-inline-flex align-items-center gap-2">
                                    <input class="form-check-input fs-18" type="radio" name="roleOpportunity" value="optionSBDM" id="RoleSBDM" checked={selectRoleOption === 'optionSBDM'} onChange={handleRoleChange} />
                                    <label class="form-check-label fs-4 fw-semibold pt-1" for="RoleSBDM">Supported BDM</label>
                                </div>
                            </div>
                            <div className="desc-info fs-12 fw-medium" style={{ color: '#BA00FF' }}>
                                A Business Development Manager (BDM) plays a pivotal role in driving business growth by identifying and pursuing new opportunities.
                                They manage key client interactions, ensure the right talent is engaged within the organization, and work closely with
                                internal teams to develop customized project estimations and negotiate contracts effectively.
                            </div>
                        </div>
                    )}

                    {selectRoleOption === 'optionBDM' && (
                        <div className="wrapper">
                            <div className="heading fs-6 fw-semibold mb-3">Please carefully review your contributions to this opportunity before proceeding.</div>
                            {Object.entries(bdmOption).map(([key, options], index) => (

                                <div className="radio-wrapper mb-4">
                                    {index === 0 ?
                                        <div className="text-primary mb-3">Discussion on Client Calls</div>
                                        : index === 1 ?
                                            <div className="text-primary mb-3">Did Coordination reach out to you regarding the next steps?</div>
                                            : index === 2 ?
                                                <div className="text-primary mb-3">Do you think {item?.camListedBDM} has mistakenly added this opportunity to his wallet, or do you believe he is involved in this opportunity but as a Supported BDM rather than the main BDM?</div>
                                                :
                                                null
                                    }
                                    {options.map((item) => {
                                        if (item.type === "radio") {
                                            return (
                                                <div class="form-check mb-3">
                                                    <input class="form-check-input" type="radio" name={"BDMRAido" + index} value={item.id} id={"bdmRadio-" + item.id} onClick={() => { handleOption(index, item.id) }} />
                                                    <label class="form-check-label lh-sm" for={"bdmRadio-" + item.id}>
                                                        {item.text}
                                                    </label>
                                                </div>
                                            )
                                        }

                                    })}

                                </div>
                            ))}


                        </div>
                    )}

                    {selectRoleOption === 'optionBDM' && (

                        <div className="checks-wrapper mb-4">
                            <div className="text-primary mb-3">Please clearly outline {item?.camListedBDM}’s contributions to this opportunity as a supporting BDM, according to your understanding.</div>
                            {Object.entries(bdmOption).map(([key, options], index) => (

                                options.map((list) => {
                                    if (index === 3) {
                                        return (
                                            <div class="form-check mb-2">
                                                <input class="form-check-input" type="checkbox" value={list.id} id={"BDMCheckbox-" + list.id} onChange={handleBDMCheckboxChange} />
                                                <label class="form-check-label" for={"BDMCheckbox-" + list.id}>{list.text}</label>
                                            </div>
                                        )
                                    }





                                })


                            ))}

                        </div>
                    )}

                    {selectRoleOption === 'optionSBDM' && (
                        <div className="checks-wrapper mb-4">
                            <div className="text-primary mb-3">Please carefully review your contributions to this opportunity before proceeding.</div>
                            {Object.entries(sbdmOption).map(([key, optionsS], index) => (

                                optionsS.map((list) => {
                                    if (index === 0) {
                                        return (
                                            <div class="form-check mb-2">
                                                <input class="form-check-input" type="checkbox" value={list.id} id={"SBDMCheckbox-" + list.id} onChange={handleSBDMCheckboxChange} />
                                                <label class="form-check-label" for={"SBDMCheckbox-" + list.id}>{list.text}</label>
                                            </div>
                                        )
                                    }





                                })


                            ))}

                        </div>
                    )}

                    {selectRoleOption === 'optionBDM' && (
                        <Form.Group className="mb-3 mb-lg-4" controlId="teamMemberEmail">
                            <Form.Label className="text-primary mb-2">Please provide the email of the Coordination employee you communicated with regarding this opportunity.</Form.Label>
                            <Form.Control type="email" className='py-2' placeholder="Enter Email Address" autoComplete='off' onChange={(e) => { setCoordinationEmail(e.target.value) }} />
                        </Form.Group>
                    )}

                    {(selectRoleOption === 'optionBDM' || selectRoleOption === 'optionSBDM') && (
                        <>
                            <Form.Group className="mb-3 mb-lg-4" controlId="teamMemberEmail">
                                <Form.Label className="text-primary mb-2">If you have any additional information to share, please provide it in the text box below.</Form.Label>
                                <Form.Control as="textarea" rows={4} className='border rounded-3 px-3 py-2' placeholder="Write here" autoComplete='off' onChange={(e) => { setAdditional(e.target.value) }} />
                            </Form.Group>

                            <div class="form-check checks-wrapper mb-3">
                                <input class="form-check-input" type="checkbox" id="checkCondition" checked={isChecked} onChange={handleCheckboxChange} />
                                <label class="form-check-label fs-14" for="checkCondition">Raising a false dispute can have consequences and management may take appropriate action. Please check the box if you are certain about raising this dispute.</label>
                            </div>
                        </>
                    )}

                    <div className="btn-wrapper d-flex flex-wrap flex-column flex-sm-row justify-content-center gap-2 gap-sm-3 pt-2">
                        <Button variant='secondary' className='btn-custom fs-18 px-5 py-2' onClick={close}>Cancel</Button>
                        {(selectRoleOption === 'optionBDM' || selectRoleOption === 'optionSBDM') && (
                            <Button variant='success' className='btn-custom fs-18 px-5 py-2' disabled={!isChecked} onClick={handleRaiseConcern}>Raise Concern</Button>
                        )}
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

import React from "react";

export const Opportunitykey = ({opportunityData, DateFormatefunc,getInstallmentCard}) => {
    return (
        <>
            <div className="col-lg-6 col-xl-4">
                <div className="details-card h-100 rounded-3 p-3" style={{ backgroundColor: 'var(--custom-bg)' }}>
                    <div className="mb-3">
                        <div class="fw-semibold text-warning lh-sm mb-1">Key Details in Opportunity</div>
                        <div class="text-white lh-sm">In this section, you can clearly see the name <span className="text-capitalize">CAM</span> has entered in <span className="text-capitalize">BDM</span> and identify and conflict if it does not match the Requester.</div>
                    </div>
                    <div className="row g-3">
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Opportunity Status<span className="float-sm-end">:</span></div>
                            <div className="value fw-semibold text-uppercase" style={{ color: '#03ff85' }}>{opportunityData?.opportunityStatus}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Opportunity Type<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{opportunityData?.opportunityType}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Opportunity Created<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{DateFormatefunc(opportunityData?.opportunityCreated)}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Skill Required<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{opportunityData?.skillRequired}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Client Type<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{opportunityData?.clientType}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">BDM in Opportunity<span className="float-sm-end">:</span></div>
                            <div className="value text-white text-opacity-75">{opportunityData?.bdmInOpportunity}</div>
                        </div>
                        <div className="info-box d-flex flex-column flex-sm-row justify-content-between column-gap-4 row-gap-1 text-capitalize">
                            <div className="label text-warning">Opportunity Conflict<span className="float-sm-end">:</span></div>
                            <div className="value fw-semibold" style={{ color: '#03ff85' }}>{opportunityData?.opportunityConflict}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
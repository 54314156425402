import React from "react";

export const Rightside = ({ rightData, dateFomate }) => {
    return (
        <>
            <div className="dispute-wrapper dark-theme-bg2 rounded-3 h-100 p-3">
                <div className="label-heading text-warning fs-6 fw-semibold mb-2">List of Dispute Submitters <i className="bi bi-info-circle-fill text-light text-opacity-75 ms-1" title='This section lists individuals who raised disputes. Review the details below to identify the appropriate BDM and click "Real BDM" to approve.'></i></div>
                <div className="card-wrapper d-flex flex-column gap-2 overflow-auto" style={{ maxHeight: '230px' }}>
                    {rightData.length >= 1 ?
                        <>
                            {rightData?.map((disputerData, index) => (
                                <div className="info-card border-danger rounded-3 p-2" style={{ border: '2px solid #ff191a', backgroundColor: '#585858' }}>
                                    <div className="d-flex gap-2">
                                        <div className="user-img mt-1">
                                            <img src={require('../../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                                        </div>
                                        <div className="user-info text-truncate text-capitalize">
                                            <div className="user-name text-white fw-semibold text-truncate">{disputerData?.raiser_name}</div>
                                            <div className="user-empCode text-white">Dispute Raiser</div>
                                            <div className="opportunity-added fs-14 text-warning text-wrap">Dispute Raised on : <span className="text-white ms-1">{dateFomate(disputerData?.createdAt)}</span></div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                        : "No data found."}
                </div>
            </div>
        </>
    )
}
import React,{useState, useEffect} from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
// Quill Editor
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const NewAnnouncementModal = ({ show, close, onSubmit }) => {
    
    const initialFormState = {
        from: '',
        till: '',
        title: '',
        description:''
    };
     const [description, setDescription] = useState("");
     const [title, setTitle] = useState("");
    const [formData, setFormData] = useState(initialFormState);

     // Define the quill toolbar modules
     const toolbarModules = {
        toolbar: {
            container: '#toolbar', // Reference the ID of the custom toolbar
            handlers: {
                // Add custom handlers if necessary
            }
        },
        toolbar: [
          [{ 'header': '1' }, { 'header': '2' }],
          ['bold', 'italic', 'underline'],
          [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
          [{ 'list': 'bullet' }, { 'list': 'ordered' }],
          ['link'],
          ['clean'] // Remove formatting button
        ],
    };

    // Define the quill formats that are allowed
    const formats = [
        'font', 'header', 'list', 'bullet', 'bold', 'italic', 'underline', 'align', 'link', 'image', 'blockquote'
    ];

    const [errors, setErrors] = useState({});
    useEffect(() => {
        if (!show) {
            setFormData(initialFormState); // Reset form when modal is closed
            setErrors({}); // Clear errors
        }
    }, [show]);
      // Handle input changes
      const handleInputChange = (e) => {
        
        const { name, value } = e.target;
        console.log(name, value)
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const newErrors = {};
        const currentDateTime = new Date();

        if (!formData.from) {
            newErrors.from = 'From date and time is required.';
        } else if (new Date(formData.from) < currentDateTime) {
            newErrors.from = 'From date and time cannot be in the past.';
        }

        if (!formData.till) {
            newErrors.till = 'Till date and time is required.';
        } else if (new Date(formData.till) < new Date(formData.from)) {
            newErrors.till = 'Till date and time cannot be earlier than From date and time.';
        }

        if (!formData.title) {
            newErrors.title = 'Announcement message is required.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(description)
        if (validateForm()) {
            console.log(formData);
            setFormData({...formData, description:description})
            let formData__ = {...formData, description:description}
            onSubmit(formData__);
            setFormData(initialFormState); 
            close();
        }
    };

    return (
        <Modal show={show} centered onHide={close} size='xl' scrollable backdrop="static" keyboard={false} dialogClassName="newAnnouncementModal mw-auto" contentClassName='rounded overflow-hidden'>
            <Modal.Body className="px-lg-4 px-xl-5 py-4">
                <div className="custom-wrapper my-lg-2 pb-1">
                    <div className="fs-3 fw-bold text-dark lh-sm mb-4">New Announcement</div>
                    <Form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-lg-6">
                                <Form.Group className="flex-fill" controlId="dateFrom">
                                    <Form.Label>From<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        type="datetime-local"
                                        name="from"
                                        value={formData.from}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.from}
                                        required
                                    />
                           <Form.Control.Feedback type="invalid">{errors.from}</Form.Control.Feedback>
    
                                </Form.Group>
                            </div>
                            <div className="col-lg-6">
                                <Form.Group className="flex-fill" controlId="dateWhen">
                                    <Form.Label>Till<span className='text-danger'>*</span></Form.Label>
                                    <Form.Control
                                        type="datetime-local"
                                        name="till"
                                        value={formData.till}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.till}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.till}</Form.Control.Feedback>
                                </Form.Group>
                            </div>

                            <Form.Group className="mb-3" controlId="addSubject">
                                    <Form.Control type="text" id="title" className="custom-input fw-medium border shadow-none py-2" placeholder="Enter title" autoComplete="off" required  
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                            //         onChange={(e) => {
                            //     setTitle(e.target.value);
                                
                                
                            // }} 
                                                
                                                />
                                                    </Form.Group>
                            <div className="col-12">
                                <Form.Group controlId="announcementMessage">
                                    <Form.Label>Announcement Message</Form.Label>
                                    {/* <Form.Control
                                        as="textarea"
                                        rows={4}
                                        name="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        placeholder="Write here"
                                        autoComplete="off"
                                        isInvalid={!!errors.message}
                                    /> */}
                                    <ReactQuill id="announcement-listing" className="custom-quill-editor border rounded overflow-hidden" theme="snow" placeholder="Provide detailed information about your query or concern. Be specific to your query so that the Helpdesk admin can understand and respond accurately."
                                                                    modules={toolbarModules}
                                                                    formats={formats}
                                                                    
                                                                     onChange={setDescription}
                                                                    // name="description"
                                                                    // value={formData.description}
                                                                    // onChange={handleInputChange}
                                                                    
                                                                />
                                     <Form.Control.Feedback type="invalid">{errors.message}</Form.Control.Feedback>
                                     <div className="fs-14 text-end mt-1">{200 - formData.title.length} character(s) left</div>
                                </Form.Group>
                            </div>
                        </div>

                        <div className="btn-wrapper d-flex flex-wrap justify-content-center gap-3 mt-4 pt-lg-2">
                            <Button variant="secondary" onClick={close} className='btn-custom fs-18 px-5 py-2'>Cancel</Button>
                            <Button type='submit' variant="success" className='btn-custom fs-18 px-5 py-2'>Send</Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    )
}

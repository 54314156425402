import { Accordion, Button, Card, InputGroup, useAccordionButton } from "react-bootstrap"
import { new_ClientType, new_opportunitytype, statuscode } from "../../utils/codes"
import { callAxios, convertReadable, log__ } from "../../utils/utils"
import { environment } from "../../environment"
import { useState } from "react"
import { toast } from "react-toastify";
import { RaiseDisputeModal } from "../RaiseDisputeModal"

function CustomToggle({ children, eventKey, onClick }) {
    const decoratedOnClick = useAccordionButton(eventKey, onClick);

    return (
        <div className="custom-bg rounded-3" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}


export const SearchCard = ({ resetSearch, item, bonus, setPolicy, userRole, incentivetype, setIncentiveType, setSearchResults, setSearchLoading, setBonus, type, loadWallet, setIsFullLoading }) => {
    // console.log(item)
    const [addingLoader, setAddingLoader] = useState(false);

    // Raise Dispute modal
    const [showRaiseDisputeModal, setShowRaiseDisputeModal] = useState(false);
    const closeRaiseDisputeModal = () => setShowRaiseDisputeModal(false);
    const openRaiseDisputeModal = () => setShowRaiseDisputeModal(true);

    const [OppType, setOppType] = useState("")


    const addToWallet = async (item) => {
        // log__(item)
        setAddingLoader(true)
        callAxios(`${environment.REACT_APP_API_URL_Live}api/v1/wallet/add-to-wallet`, { item: item, incentivetype: incentivetype, usertype: userRole, bonus: bonus,OppType:OppType }, "post").then((res) => {
            // log__(res)
            if (res.status === 201) {

                toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                setIncentiveType("")
                document.body.classList.remove('toggled-class')
                setSearchResults([])

                setSearchLoading(false)
                setBonus({})
                document.getElementById("search-op-incentive").value = "";
                loadWallet(userRole, type, "all")
            } else {

                if (res.data.statusCode === 409) {
                    document.body.classList.remove('toggled-class');
                    setSearchResults([])
                    setIncentiveType("")
                    setSearchLoading(false)
                    setBonus({})
                    setAddingLoader(false)
                    document.getElementById("search-op-incentive").value = "";
                    toast.error(res.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

            }

        }).catch((error) => {
            log__("====>", error)
            toast.error("something went wrong", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        })
    }
    if (item?.othersWallet === 0 && item?.bdmEmail !== item?.raiseEmail) {
        // can not add this card because cam listed Rahul yadav as bdm name
        log__("can not add this because cam listed ANother name on this opportunity")
        return (
            
            <div className="info-card border border-secondary rounded-3 p-2">
                <div className="card-header dark-theme-bg3 rounded-3">
                    <div className="custom-bg">
                        <div className="row align-items-center gx-2 gy-2">
                            <div className="left-section col-md info-box text-capitalize">
                                <div className="label fs-5 fw-medium text-warning">{item.new_fullname}</div>
                                <div className="value text-white text-opacity-75 text-break">{item.new_opportunityid}</div>
                            </div>
                            <div className="right-section col-md info-box text-capitalize">
                                {item?.incentiveObject ?
                                    <div className="total-Incentive pe-5">

                                        <div className="fs-5 fw-semibold text-white">Total Incentive: ₹{item?.incentiveObject?.total_bonus}</div>
                                        <div className="fs-6 fw-semibold text-white text-opacity-50 text-capitalize">Incentive Type: {item?.incentiveObject?.scenario} ({item?.incentiveObject?.client_type})</div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div className="position-absolute top-0 end-0 z-1 px-2 py-1 m-2 bg-danger rounded-2 text-white pointer" onClick={resetSearch}><i className="bi bi-x-lg"></i></div>
                    </div>
                </div>

                <div className="card-body mt-3 px-2">
                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity ID</div>
                            <div className="value text-white text-opacity-75 text-break">{item.new_opportunityid}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Created</div>
                            <div className="value text-white text-opacity-75">{convertReadable(item.createdon)}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity in Other's Wallet</div>

                            {item.othersWallet > 1 ?
                                <div className="value text-white text-opacity-75">Yes</div>
                                :
                                <div className="value text-white text-opacity-75">No</div>
                            }

                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Skill Required</div>
                            <div className="value text-white text-opacity-75">{item.skills}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Status</div>
                            <div className="value fw-medium text-info">{statuscode[item.statuscode]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Type</div>
                            <div className="value text-white text-opacity-75">{new_opportunitytype[item.new_opportunitytype]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Client Type</div>
                            <div className="value text-white text-opacity-75">{new_ClientType[item.new_clienttype]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Country</div>
                            <div className="value text-white text-opacity-75">{item.country}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">TimeZone</div>
                            <div className="value text-white text-opacity-75">{item.timezone}</div>
                        </div>
                    </div>
                    <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                    <div className="description mb-2">
                        <div className="row align-items-center g-3">
                            <div className="col-lg-8">
                                <div className="fs-12 text-red">
                                    You cannot add this to your wallet, as CAM has listed {item?.camListedBDM} as the BDM for this opportunity.
                                </div>
                            </div>
                            <div className="col-lg-4 text-lg-end">
                                <Button variant="danger" className="rounded-1 px-4 py-2" onClick={openRaiseDisputeModal}>Raise Dispute</Button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Raise Dispute Modal */}
                <RaiseDisputeModal show={showRaiseDisputeModal} close={closeRaiseDisputeModal} item={item} setShowRaiseDisputeModal={setShowRaiseDisputeModal} resetSearch={resetSearch} setIsFullLoading={setIsFullLoading} loadWallet={loadWallet} />
            </div>
        )
    } else if (item?.othersWallet > 0 && item?.isClaimerClaimed) {
        // can not add this card becaues climer claimed this
        log__("can not add this because claimer claimed  this opportunity")
        return (
           
            <div className="info-card border border-secondary rounded-3 p-2">
                <div className="card-header dark-theme-bg3 rounded-3">
                    <div className="custom-bg">
                        <div className="row align-items-center gx-2 gy-2">
                            <div className="left-section col-md info-box text-capitalize">
                                <div className="label fs-5 fw-medium text-warning">{item.new_fullname}</div>
                                <div className="value text-white text-opacity-75 text-break">{item.new_opportunityid}</div>
                            </div>
                            <div className="right-section col-md info-box text-capitalize">
                                {item?.incentiveObject ?
                                    <div className="total-Incentive pe-5">

                                        <div className="fs-5 fw-semibold text-white">Total Incentive: ₹{item?.incentiveObject?.total_bonus}</div>
                                        <div className="fs-6 fw-semibold text-white text-opacity-50 text-capitalize">Incentive Type: {item?.incentiveObject?.scenario} ({item?.incentiveObject?.client_type})</div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                        <div className="position-absolute top-0 end-0 z-1 px-2 py-1 m-2 bg-danger rounded-2 text-white pointer" onClick={resetSearch}><i className="bi bi-x-lg"></i></div>
                    </div>
                </div>

                <div className="card-body mt-3 px-2">
                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity ID</div>
                            <div className="value text-white text-opacity-75 text-break">{item.new_opportunityid}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Created</div>
                            <div className="value text-white text-opacity-75">{convertReadable(item.createdon)}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity in Other's Wallet</div>

                            {item.othersWallet > 1 ?
                                <div className="value text-white text-opacity-75">Yes</div>
                                :
                                <div className="value text-white text-opacity-75">No</div>
                            }

                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Skill Required</div>
                            <div className="value text-white text-opacity-75">{item.skills}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Status</div>
                            <div className="value fw-medium text-info">{statuscode[item.statuscode]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Type</div>
                            <div className="value text-white text-opacity-75">{new_opportunitytype[item.new_opportunitytype]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Client Type</div>
                            <div className="value text-white text-opacity-75">{new_ClientType[item.new_clienttype]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Country</div>
                            <div className="value text-white text-opacity-75">{item.country}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">TimeZone</div>
                            <div className="value text-white text-opacity-75">{item.timezone}</div>
                        </div>
                    </div>
                    <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                    <div className="description mb-2">
                        <div className="row align-items-center g-3">
                            <div className="col-lg-8">
                                <div className="fs-12 text-red">
                                    This opportunity cannot be added to your wallet because it has already been claimed by {item?.claimerName}, and CAM has also
                                    listed {item?.camListedBDM} as the BDM for this opportunity. If you wish, you can raise a dispute, which will be reviewed by management.
                                </div>
                            </div>
                            <div className="col-lg-4 text-lg-end">
                                <Button variant="danger" className="rounded-1 px-4 py-2" onClick={openRaiseDisputeModal}>Raise Dispute</Button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Raise Dispute Modal */}
                <RaiseDisputeModal show={showRaiseDisputeModal} close={closeRaiseDisputeModal} item={item} setShowRaiseDisputeModal={setShowRaiseDisputeModal} resetSearch={resetSearch} setIsFullLoading={setIsFullLoading} loadWallet={loadWallet} />
            </div>
        )
    } else {

        return (

            <div className="info-card border border-secondary rounded-3 p-2">
                <div className="card-header dark-theme-bg3 rounded-3">
                    <div className="custom-bg">
                        <div className="row align-items-center gx-2 gy-2">
                            <div className="left-section col-md info-box text-capitalize">
                                <div className="label fs-5 fw-medium text-warning">{item.new_fullname}</div>
                                <div className="value text-white text-opacity-75 text-break">{item.new_opportunityid}</div>
                            </div>
                            <div className="right-section col-md info-box text-capitalize">
                                <div className="total-Incentive pe-5">
                                    <div className="fs-5 fw-semibold text-white">Total Incentive: ₹{bonus.total_bonus}</div>
                                    {/* <div className="fs-6 fw-semibold text-white text-opacity-50 text-capitalize">Incentive Type: Client Referral (Full-Time)</div> */}
                                </div>
                            </div>
                        </div>
                        <div className="position-absolute top-0 end-0 z-1 px-2 py-1 m-2 bg-danger rounded-2 text-white pointer" onClick={resetSearch}><i className="bi bi-x-lg"></i></div>
                    </div>
                </div>

                <div className="card-body mt-3 px-2">
                    <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity ID</div>
                            <div className="value text-white text-opacity-75 text-break">{item.new_opportunityid}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Created</div>
                            <div className="value text-white text-opacity-75">{convertReadable(item.createdon)}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity in Other's Wallet</div>

                            {item.othersWallet > 1 ?
                                <div className="value text-white text-opacity-75">Yes</div>
                                :
                                <div className="value text-white text-opacity-75">No</div>
                            }

                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Skill Required</div>
                            <div className="value text-white text-opacity-75">{item.skills}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Status</div>
                            <div className="value fw-medium text-info">{statuscode[item.statuscode]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Opportunity Type</div>
                            <div className="value text-white text-opacity-75">{new_opportunitytype[item.new_opportunitytype]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Client Type</div>
                            <div className="value text-white text-opacity-75">{new_ClientType[item.new_clienttype]}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">Country</div>
                            <div className="value text-white text-opacity-75">{item.country}</div>
                        </div>
                        <div className="info-box text-capitalize">
                            <div className="label fs-14 text-warning">TimeZone</div>
                            <div className="value text-white text-opacity-75">{item.timezone}</div>
                        </div>
                    </div>
                    <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
                    <div className="description mb-2">
                        <div className="row gx-3">
                            <div className="col-xl-5"></div>
                            <div className="col-xl-7 text-lg-end">
                                <div className="d-flex align-items-center justify-content-lg-end gap-2 gap-xl-4">
                                    <div className="field-cover d-flex align-items-center gap-2">
                                        <label htmlFor="typeOfIncentive" className="text-white text-opacity-75 text-nowrap">Type of Incentive</label>
                                        <select name="" id="typeOfIncentive" className="form-select text-light dark-theme-bg fs-14  border border-secondary rounded-3 shadow-none" onChange={(e) => { setPolicy(e.target.value, userRole) }}>
                                            <option disabled selected>Select Incentive Type</option>
                                            <option value={"new_hire_full_time"}>New Hire (Full-Time)</option>
                                            <option value={"new_hire_part_time"}>New Hire (Part-Time)</option>
                                            <option value={"upscaling_full_time"}>Upscaling (Full-Time)</option>
                                            <option value={"upscaling_part_time"}>Upscaling (Part-Time)</option>
                                            <option value={"cross_selling_full_time"}>Cross-Selling (Full-Time)</option>
                                            <option value={"cross_selling_part_time"}>Cross-Selling (Part-Time)</option>
                                            <option value={"client_referral_full_time"}>Client Referral (Full-Time)</option>
                                            <option value={"client_referral_part_time"}>Client Referral (Part-Time)</option>
                                        </select>
                                    </div>
                                    <div className="field-cover d-flex align-items-center gap-2">
                                        <label htmlFor="typeOfOpp" className="text-white text-opacity-75 text-nowrap">Type of Opportunity</label>
                                        <select name="" id="typeOfOpp" className="form-select text-light dark-theme-bg fs-14  border border-secondary rounded-3 shadow-none" onChange={(e) => { setOppType(e.target.value, userRole) }}>
                                            <option disabled selected>Select Incentive Type</option>
                                            <option value={"technical"}>Technical</option>
                                            <option value={"nontechnical"}>Non - Technical</option>
                                            
                                        </select>
                                    </div>
                                    {/* <Button variant="success" className="rounded-1 px-3">Add To My Wallet</Button> */}
                                    {addingLoader ?
                                        <div className="rounded-1 px-3 py-2 search-op-loader"></div>
                                        :
                                        incentivetype.length > 0 && OppType.length > 0 ?
                                            <Button variant="success" className="rounded-1 px-3 py-2" onClick={() => { addToWallet(item) }}>Add to My Wallet</Button>
                                            :
                                            <Button variant="secondary" disabled className="rounded-1 px-3 py-2" >Add To My Wallet</Button>
                                    }
                                </div>
                                <div className="text-center fs-12">You may select the Incentive Type based on your current understanding. However, it may be updated by the billing team based on the client’s payment and service type on first billing.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    // return (
    //     <div className="search-wrapper dropdown z-1">
    //                     <InputGroup className="border border-secondary border-opacity-75 rounded-3 mb-3" type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={handleToggle}>
    //                         <Form.Control type="search" className="bg-transparent border-0 rounded-3 shadow-none py-2" placeholder="Search with Opportunity ID" />
    //                         <Button variant="success" id="button-addon2" className="rounded-1 mx-1 my-1" style={{ padding: '6px 40px' }}>Search</Button>
    //                     </InputGroup>
    //                     <div className="dropdown-menu w-100 bg-transparent p-0">
    //                         <div className="d-flex flex-column gap-3">







    //                             <div className="info-card border border-secondary rounded-3 p-2">
    //                                 <div className="card-header dark-theme-bg3 rounded-3">
    //                                     <div className="custom-bg">
    //                                         <div className="row align-items-center gx-2 gy-2">
    //                                             <div className="left-section col-md info-box text-capitalize">
    //                                                 <div className="label fs-5 fw-medium text-warning">Xaurl Churay</div>
    //                                                 <div className="value text-white text-opacity-75 text-break">Xaurl Churay_080110_009</div>
    //                                             </div>
    //                                             <div className="right-section col-md info-box text-capitalize">
    //                                                 <div className="total-Incentive pe-5">
    //                                                     <div className="fs-5 fw-semibold text-white">Total Incentive: ₹10,000</div>
    //                                                     {/* <div className="fs-6 fw-semibold text-white text-opacity-50 text-capitalize">Incentive Type: Client Referral (Full-Time)</div> */}
    //                                                 </div>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 </div>

    //                                 <div className="card-body mt-3 px-2">
    //                                     <div className="row row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 gx-2 gy-3">
    //                                         <div className="info-box text-capitalize">
    //                                             <div className="label fs-14 text-warning">Opportunity ID</div>
    //                                             <div className="value text-white text-opacity-75 text-break">Xaurl Churay_080110_009</div>
    //                                         </div>
    //                                         <div className="info-box text-capitalize">
    //                                             <div className="label fs-14 text-warning">Opportunity Created</div>
    //                                             <div className="value text-white text-opacity-75">08-08-2024</div>
    //                                         </div>
    //                                         <div className="info-box text-capitalize">
    //                                             <div className="label fs-14 text-warning">Opportunity Added to Your Wallet</div>
    //                                             <div className="value text-white text-opacity-75">08-08-2024</div>
    //                                         </div>
    //                                         <div className="info-box text-capitalize">
    //                                             <div className="label fs-14 text-warning">Skill Required</div>
    //                                             <div className="value text-white text-opacity-75">Virtual Assistant</div>
    //                                         </div>
    //                                         <div className="info-box text-capitalize">
    //                                             <div className="label fs-14 text-warning">Opportunity Status</div>
    //                                             <div className="value fw-medium text-green text-uppercase">WON</div>
    //                                         </div>
    //                                         <div className="info-box text-capitalize">
    //                                             <div className="label fs-14 text-warning">Opportunity Type</div>
    //                                             <div className="value text-white text-opacity-75">In-House Team</div>
    //                                         </div>
    //                                         <div className="info-box text-capitalize">
    //                                             <div className="label fs-14 text-warning">Client Type</div>
    //                                             <div className="value text-white text-opacity-75">Additional New</div>
    //                                         </div>
    //                                         <div className="info-box text-capitalize">
    //                                             <div className="label fs-14 text-warning">Country</div>
    //                                             <div className="value text-white text-opacity-75">Australia</div>
    //                                         </div>
    //                                         <div className="info-box text-capitalize">
    //                                             <div className="label fs-14 text-warning">TimeZone</div>
    //                                             <div className="value text-white text-opacity-75">Adelaide Time</div>
    //                                         </div>
    //                                     </div>
    //                                     <hr className="divider border border-top-2 border-secondary border-opacity-75 my-3" />
    //                                     <div className="description mb-2">
    //                                         <div className="fs-12 text-green">
    //                                             This opportunity has already been approved by management, and the payment has been initiated. No further action can't be taken at this stage.
    //                                         </div>
    //                                     </div>
    //                                 </div>
    //                             </div>


    //                         </div>
    //                     </div>
    //                 </div>
    // )

}
import React, { useEffect, useState } from "react";
import "./Header.css";
import UserImage from "../resources/images/Avatar.png";
import { app, database, auth } from "../firebaseConfig";
import {
  collection,
  query,
  where,
  onSnapshot,
  documentId,
  doc,
  getDoc,
  orderBy,
  limit,
} from "firebase/firestore";

// env import
import { environment } from "../environment";

import { useNavigate } from "react-router-dom";

import { callAxios } from "../utils/utils";
import { update_user_status } from "../utils/twilioConversation";

const Header = (props) => {
  const [userRole, setUserRole] = useState({});
  let navigate = useNavigate();

  const [currentAppVersion, setAppVersion] = useState("");
  //console.log("user current version:", localStorage.getItem("appVersion"));

  const currentVersion = async()=>{
    const myDocRef = doc(database, environment.REACT_APP_VERSION_DB, "zhwbBpi4c6AQFYJmai2Q");
    const myDoc = await getDoc(myDocRef);
    if (myDoc.exists()) {
      
        setAppVersion(myDoc.data().version);
           
    }
    
}
currentVersion();
  // get user Role data
  useEffect(() => {
    const getUserRole = async () => {
      if (props?.user) {
        const q = query(
          collection(database, environment.REACT_APP_USERS_DB),
          where("email", "==", props?.user?.email),
          orderBy("timeStamp","asc"),
          limit(1)
        );
        onSnapshot(q, (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            data["id"] = doc.id;
            setUserRole(data);
          });
        });
      }
    };
    getUserRole();
  }, [props?.user]);
//console.log(props?.user)
  // shorName method is calculating shortname
  const shortName = props?.user?.displayName.split(" ").map((shortName) => {
    return shortName[0];
  });

  const logoutHandler = async () => {
    localStorage.setItem("userRole", undefined);
    localStorage.setItem("camJoinedLead", null);
    localStorage.setItem("bdmJoinedLead", null);
    localStorage.setItem("checkAudio", null);
    const loggedInUser = props?.user?.email? props?.user?.email : localStorage.getItem("userEmail");
    await update_user_status(loggedInUser,false)

    
    await callAxios(
      environment.REACT_APP_API_URL_Live + "api/v1/attendance/login-logout",
      {
        uid: props?.user?.uid,
        action: "Check-Out",
        email: props?.user?.email,
        display_name: props?.user?.displayName,
      },
      "post"
    );

    await auth.signOut();
  };
  const image = !userRole?.profile_image
    ? require("../resources/images/Avatar.png")
    : userRole?.profile_image;
  const navigateToViewAc = (e) => {
    e.preventDefault();
    navigate("/account-details", {
      state: { user: props?.user, userRole: userRole, image: image },
    });
  };

  const navigateToViewProfile = (e) => {
    e.preventDefault();
    navigate("/profile", { state: { user: userRole, loggedInUser:props?.user } });
  };

  const handleRedirect = (e) => {
    e.preventDefault();
    //navigate('/lead-listing');
  };
//console.log(userRole)
  return (
    <nav
      className="navbar bg-dark navbar-expand top-header sticky-top py-2"
      data-bs-theme="dark"
    >
      <div className="container">
        <a
          className="navbar-brand d-flex align-items-center p-0"
          href="javascript:;"
          onClick={handleRedirect}
        >
          <img src={require("../resources/images/logo.png")} alt="NM-Calls" />
          <div className="app-version d-grid gap-1">
            <div>BETA</div>
            {/* <div>Version {currentAppVersion}</div> */}
            <div>Version 2.24.1</div> 
            </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto">
            {/* <li className="nav-item">
              <a className="nav-link text-white" aria-current="page" href="#">Issues</a>
            </li>
            <li className="nav-item">
                <a className="nav-link text-white" href="#">About</a>
            </li> */}
          </ul>
          {/* <form className="d-flex" role="search">
                    <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
                    <button className="btn btn-outline-success" type="submit">Search</button>
                </form> */}
          <li className="nav-item dropdown user-logout">
            <a
              className="nav-link text-white text-center p-0 "
              href="#"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <div className="chip-wrapper">
                <div className="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden">
                  <div className="user-shortname text-white">{shortName}</div>
                  {/* <img className='w-100 h-100' src={UserImage} alt='User Image' /> */}
                </div>
              </div>
            </a>
            <ul
              className="dropdown-menu dropdown-menu-end caret-indicator shadow-lg p-3 pb-4 mt-2"
              style={{ width: "300px" }}
            >
              <div className="d-flex justify-content-between gap-2">
                <div className="orgainization-name title-label">
                  Virtualemployee Pvt Ltd.
                </div>
                <a
                  className="signout title-label pointer text-primary"
                  onClick={logoutHandler}
                >
                  Sign out
                </a>
              </div>
              <div className="d-flex align-items-center gap-2 mt-3">
                <div className="chip-wrapper">
                  <div
                    className="chip-img d-flex align-items-center justify-content-center rounded-pill border border-primary overflow-hidden"
                    style={{ height: "70px", width: "70px" }}
                  >
                    {/* <div className='user-shortname text-dark fs-3'>VA</div> */}
                    <img className="w-100 h-100" src={image} alt="User Image" />
                  </div>
                </div>
                <div className="user-info overflow-hidden">
                  <div className="user-name text-capitalize" id="author-name" data-author-name={props?.user?.displayName} data-author-identity={props?.user?.email}>
                    {props?.user?.displayName}
                  </div>
                  <div className="user-id title-label">
                    {props?.user?.email}
                  </div>
                  <div className="user-id title-label">
                    {userRole?.userType == "SA" || userRole?.userType == "SSA" ? (
                      <a href="#" class="btn-link" onClick={navigateToViewAc}>
                        View account
                      </a>
                    ) : null}
                    <a
                      href="#"
                      class="btn-link m-3"
                      onClick={navigateToViewProfile}
                    >
                      View profile
                    </a>
                  </div>
                </div>
              </div>
            </ul>
          </li>
        </div>
      </div>
    </nav>
  );
};

export default Header;
import React from 'react';
import ApexCharts from 'react-apexcharts';

const OverAllStatsChart = ({bdm, sdm, sbdm}) => {
    // Chart data and options
    const chartData = {
        series: [
            {
                name: 'Category Values',
                data: [bdm, sdm, sbdm], // Total numbers corresponding to BDM, SDM, and Supported BDM
            },
        ],
        options: {
            chart: {
                type: 'bar', // Type of chart: bar
                height: '400', // Chart height
            },
            plotOptions: {
                bar: {
                    columnWidth: '85%',
                    distributed: true,
                    horizontal: false, // Vertical bar chart
                },
            },
            dataLabels: {
                enabled: false, // Disable data labels on top of bars
            },
            colors: ['#3742f8', '#6c5be7', '#be2edd'],
            xaxis: {
                categories: ['BDM', 'SDM', 'Supported BDM'], // Categories for the x-axis
                labels: {
                    show: true,
                    style: {
                        colors: '#e5e1e1',
                        fontSize: '12px',
                        fontWeight: '600',
                    }
                }
            },
            legend: {
                show: false,
                labels: {
                    colors: ['#fff'],
                },
            },
            yaxis: {
                min: 0, // Minimum value for Y-axis
                tickAmount: 10, // Number of ticks (0, 10, 20, ..., max)
                labels: {
                    style: {
                        colors: '#e5e1e1', // White color for Y-axis labels
                        fontSize: '12px',
                        fontWeight: '600',
                    },
                    formatter: function (val) {
                        return `${val}`; // Display the total numbers directly without percentage sign
                    },
                },
            },
            grid: {
                show: false,
                borderColor: '#f1f1f1', // Grid line color
            },
            fill: {
                opacity: 1, // Opacity of bars
            },
        },
    };

    return (
        <ApexCharts
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={400}
        />
    );
};

export default OverAllStatsChart;

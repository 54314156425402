import React, { useState } from "react";
import CryptoJS from 'crypto-js';
import { log__ } from "../../utils/utils";

export const BDMConversation = ({ conversationData, dateFomate,claimerData }) => {
    log__(claimerData)
    const [getDocname, setDocname] = useState('')
    const showAttachment = (attachmentUrl) => {
        let sendInfo = { "attachmentUrl": attachmentUrl }
        const encryptedQuery = CryptoJS.AES.encrypt(JSON.stringify(sendInfo), 'secret_key').toString();
        const url = `/viewFiles?query=${encodeURIComponent(encryptedQuery)}`;
        const popupWidth = "100%";
        const popupHeight = 600;
        const popupFeatures = `width=${popupWidth},height=${popupHeight},resizable=yes,scrollbars=yes`;
        window.open(url, '_blank');
    }
    const getFileExtension = (fileName) => {
        const fileExtension = fileName.split('.').pop().toLowerCase();
        // return fileExtension;
        if (['pdf'].includes(fileExtension)) {
            return 'pdf';
        }
        else if (['doc', 'docx'].includes(fileExtension)) {
            return 'word';
        }
        else if (['xls', 'xlsx'].includes(fileExtension)) {
            return 'excel';
        } else if (['zip', 'rar', 'tar'].includes(fileExtension)) {
            return 'zip'
        } else if (['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension)) {
            return 'image'
        } else if (['mp3', 'wav', 'flac'].includes(fileExtension)) {
            return 'audio';
        } else {
            return 'Unknown';
        }
    };

    function getFileNameWithoutExtension(url) {
        const lastSegment = url.split('/').pop(); 
        const fileNameWithoutExtension = lastSegment.split('.').slice(0, -1).join('.'); 
        return fileNameWithoutExtension;
    }
    return (
        <>
            {conversationData.map((items, index) => (
                <div className="info-card dark-theme-bg2 rounded-3 overflow-hidden h-100">
                    <div className="header-section d-flex flex-wrap justify-content-between row-gap-1 column-gap-3 p-3" style={{ backgroundColor: '#8f6f11' }}>
                        <div className="d-flex gap-2 text-truncate">
                            <div className="user-img mt-1">
                                <img src={require('../../resources/images/Avatar.png')} alt="User" className="img-fluid rounded-circle" style={{ height: '45px', width: '45px', minWidth: '45px' }} />
                            </div>
                            <div className="user-info text-truncate text-capitalize">
                                <div className="user-name text-white fw-semibold text-truncate">{items?.name}</div>
                                <div className="user-empCode text-white text-truncate">{claimerData?.email === items?.email? "Claimer":items?.userType}</div>
                                <div className="opportunity-added fs-14 text-warning text-wrap text-capitalize mb-1">Submission : <span className="text-white ms-1">{dateFomate(items?.createdAt)}</span></div>
                            </div>
                        </div>
                        <div className="info-box text-md-end ms-5 ps-1">
                            <div className="label text-warning">Claimed Role</div>
                            <div className="value text-white text-opacity-75 text-break">BDM</div>
                        </div>
                    </div>
                    <div className="body-section p-3 pb-4">
                        <div className="wrapper ms-md-5 ps-md-1">
                            <div className="info-box">
                                <div className="msg-description mb-3">
                                    {items?.text}
                                </div>
                                <div className="files-wrapper d-flex flex-wrap gap-2 mt-4 pointer">
                                    {items?.attachment_url && (
                                        <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2" onClick={() => showAttachment(items?.attachment_url)}>
                                            <i className={`bi bi-file-earmark-${getFileExtension(items?.attachment_url)}-fill text-light`}></i>
                                            <span className="text-truncate">{getFileNameWithoutExtension(items?.attachment_url)}</span>
                                            <i className="bi bi-eye-fill ms-1"></i>
                                        </div>
                                    )}
                                    {/* <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
                                        <i className="bi bi-file-earmark-word-fill text-light"></i>
                                        <span className="text-truncate">Word File name here</span>
                                        <i className="bi bi-eye-fill ms-1"></i>
                                    </div>
                                    <div className="file-btn fs-14 text-light text-opacity-75 d-flex gap-1 border border-secondary rounded-3 px-2 py-1 py-xl-2">
                                        <i className="bi bi-file-earmark-zip-fill text-light"></i>
                                        <span className="text-truncate">Zip File name here</span>
                                        <i className="bi bi-eye-fill ms-1"></i>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}
import React from "react";
import { useNavigate } from "react-router-dom";
export const Headerdetails = ({headerData, getdashboardData, getallwalletUser,activeIndex_}) => {
    const navigate = useNavigate();
    const backToDashboard = async()=>{
        navigate("/management-dashboard",{state:{getdashboardData:getdashboardData,getallwalletUser:getallwalletUser,activeIndex_:activeIndex_}})
    }
    return (
        <>
            <div className="d-flex flex-wrap align-items-center justify-content-between gap-3">
                <div className="d-flex flex-column flex-md-row align-items-md-center column-gap-3 gap-lg-4">
                    <div className="prev-icon fs-3 pointer lh-sm" onClick={backToDashboard}><i className="bi bi-arrow-left"></i></div>
                    <div className="info-box text-capitalize">
                        <div className="label fs-5 fw-medium text-warning">{headerData?.name}</div>
                        <div className="value text-white text-opacity-75 text-break">{headerData?.topic}</div>
                    </div>
                </div>
                <div className="d-flex flex-wrap justify-content-lg-end gap-3 gap-lg-4">
                    <div className="info-box border-end border-secondary pe-3 pe-lg-4">
                        <div className="label fw-medium text-warning">₹{headerData?.requestedAmount}</div>
                        <div className="value text-white text-opacity-75">Requested Amount</div>
                    </div>
                    <div className="info-box border-end border-secondary pe-3 pe-lg-4">
                        <div className="label fw-medium text-warning">{headerData?.addedBy}</div>
                        <div className="value text-white text-opacity-75">Added By</div>
                    </div>
                    <div className="info-box">
                        {/* <select className="bg-transparent text-warning fw-medium border-0 shadow-none p-0" style={{ outline: 'none' }}>
                            <option value="0">{headerData?.status}</option>
                            <option value="1">Approved</option>
                            <option value="2">Disapproved</option>
                        </select> */}
                        <div className="label fw-medium text-warning">{headerData?.status}</div>
                        <div className="value text-white text-opacity-75">Approval Status</div>
                    </div>
                </div>
            </div>
        </>
    )
}